import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

const Container = styled.div``;

const NavLink = () => {
  return (
    <Container>
      <Link to="/about-us" style={{ color: "white", marginLeft: "10px" }}>
        About Us
      </Link>
      <Link to="/contact-us" style={{ color: "white", marginLeft: "10px" }}>
        Contact Us
      </Link>
      <Link to="/register" style={{ color: "white", marginLeft: "10px" }}>
        Register your Pharmacy?
      </Link>
    </Container>
  );
};

export default NavLink;
