import styled from "styled-components";
import Navbar from "../components/Navbar";
import Features from "../components/Features";
import Team from "../components/Team";
import Footer from "../components/Footer";
import Trail from "../components/Trail";
import { useNavigate } from "react-router-dom";
import SubscriptionPlan from "../components/SubscriptionPlan";

const Container = styled.div``;

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    text-align: left;
    padding: 50px;
    height: 60vh;
  }

  @media screen and (min-width: 1440px) {
    height: 80vh;
  }
`;

const Left = styled.div`
  flex: 2;
  width: 100%;
  height: 100%;

  padding: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 768px) {
    align-items: flex-start;
    text-align: left;
    margin-left: 30px;
  }
`;

const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
  color: #2d3549;
  margin: 0;
  padding: 0;
  @media screen and (min-width: 768px) {
    font-size: 30px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 40px;
  }

  @media screen and (min-width: 1440px) {
    font-size: 60px;
  }
`;

const Desc = styled.p`
  font-weight: 500;
  color: #2d3549;
  font-size: 14px;
  margin: 0;
  padding: 0;
  @media screen and (min-width: 768px) {
    text-align: left;
    font-size: 16px;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #2d3549;
  color: white;
  border-radius: 10px;
  border: none;
  @media screen and (min-width: 768px) {
    margin-left: 20px;
  }
`;

const Right = styled.div`
  flex: 1.2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
  }
`;

const Image = styled.img`
  border-radius: 20px;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 80%;
  }
`;

const Home = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Navbar />

      <Wrapper>
        <Left>
          <Title>
            Your Pharmacy Operations with pk HealthTech Pharmacy Billing web
            Software
          </Title>
          <Desc>
            Our pharmacy software is a web application that helps you to manage
            your pharmacy. <br />
            <span style={{ color: "red", fontWeight: "600" }}>
              In just 20 Rs. / per day.
            </span>
          </Desc>
          <Button type="submit" onClick={() => navigate("/get-demo")}>
            Get A Demo
          </Button>
        </Left>
        <Right>
          <Image src="/img/pharmacy-banner.jpg" />
        </Right>
      </Wrapper>
      <Features />
      <Trail />
      <SubscriptionPlan />
      <Team />
      <Footer />
    </Container>
  );
};

export default Home;
