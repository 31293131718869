import React from "react";

const UnixTimestampConverter = ({ timestamp }) => {
  const dateObj = new Date(timestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds
  const formattedDate = dateObj.toUTCString(); // Format the date as a UTC string

  return <>{formattedDate}</>;
};

export default UnixTimestampConverter;
