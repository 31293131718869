import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding: 20px;
  max-width: 1740px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    padding: 100px;
    padding-top: 0;
    height: 80vh;
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 10px;
`;

const Right = styled.div`
  flex: 1;
  padding: 10px;
  width: 100%;
  @media screen and (min-width: 768px) {
    padding: 10px;
    width: 50vw;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #3c4b64;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  color: #3c4b64;
  @media screen and (min-width: 768px) {
    flex-wrap: wrap;
  }
`;

const Input = styled.input`
  flex: 1;
  max-width: 100%;

  padding: 10px;
  margin: 20px 10px 0 0;
`;

const Textarea = styled.textarea`
  padding: 10px;
  margin: 20px 10px 20px 0;
  max-width: 100%;
  &::placeholder {
    font-family: "Poppins", sans-serif;
  }
`;

const Button = styled.button`
  width: 99%;
  padding: 15px 30px;
  border: none;
  background-color: #3c4b64;
  color: white;
  cursor: pointer;
`;

const Trail = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setphone] = useState("");
  const [address, setAddress] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}api/v1/pharmacy/user-register`,
        {
          name: name,
          email: email,
          phone: phone,
          address: address,
        }
      );

      if (data?.success) {
        toast.success("Thankyou for your Responding, we'll Contact soon");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something Want Wrong");
    }
  };

  return (
    <Container>
      <Left>
        <Image src="/img/health2.png" />
      </Left>
      <Right>
        <Title>Get a Free Trail and Demo</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            required
            placeholder="Enter Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <Input
            required
            type="email"
            placeholder="Enter Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            required
            placeholder="Enter Your Phone Number"
            value={phone}
            onChange={(e) => setphone(e.target.value)}
          />
          <Textarea
            name=""
            id=""
            cols="95"
            rows="6"
            placeholder="Enter Your Pharmacy Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          ></Textarea>
          <Button type="submit">Submit</Button>
        </Form>
      </Right>
    </Container>
  );
};

export default Trail;
