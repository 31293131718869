import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";

const pharmacyContext = createContext();

const PharmacyProvider = ({ children }) => {
  const [pharmacy, setPharmacy] = useState({
    pharmacy: [],
    token: null,
  });

  // set Headers
  axios.defaults.headers.common["Authorization"] = pharmacy?.token;

  useEffect(() => {
    let pharmacy = localStorage.getItem("pharmacy");
    setPharmacy(JSON.parse(pharmacy));
  }, []);

  return (
    <pharmacyContext.Provider value={[pharmacy, setPharmacy]}>
      {children}
    </pharmacyContext.Provider>
  );
};

const usePharmacy = () => {
  return useContext(pharmacyContext);
};

export { usePharmacy, PharmacyProvider };
