import styled from "styled-components";
import { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";

const Container = styled.div``;

const Title = styled.h1`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #3c4b64;
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: #3c4b64;
  width: 80%;
  margin: 10px auto;
  gap: 20px;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
`;

const Button = styled.button`
  width: 20%;
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: #3c4b64;
  color: white;
  cursor: pointer;
`;

const AddGeneric = ({ handleClose }) => {
  const [name, setName] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}api/v1/medicines/add-generic-name`,
        {
          name: name,
        }
      );
      if (data?.success) {
        toast.success(data?.message);
        handleClose();
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
  return (
    <Container>
      <Title>ADD GENERIC NAME</Title>
      <Form onSubmit={handleSubmit}>
        <Input
          required
          placeholder="Enter Generic Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <Button type="submit">Save</Button>
      </Form>
    </Container>
  );
};

export default AddGeneric;
