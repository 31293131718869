import styled from "styled-components";
import DashNav from "../../components/DashNAv";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import UserSidebar from "../../components/user/UserSidebar";
import { Button } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { usePharmacy } from "../../context/pharmacy";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import toast, { LoaderIcon } from "react-hot-toast";
import Loader from "../../components/Loader";
import { formatDate, numberToWords } from "../../components/Hepler/Helper";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Left = styled.div`
  flex: 2;
`;

const Right = styled.div`
  flex: 11;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const AddNewButtonContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  .button {
    background-color: transparent;
    color: #212333;
    font-weight: 600;
    border: 2px solid #212333;
    &:hover {
      color: white;
      background-color: var(--secondary-color);
      border: 2px solid teal;
    }
  }
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 10px;
  ::placeholder {
    color: gray !important;
  }
`;

const InputField = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px 0;
  position: relative;
`;

const Lable = styled.label`
  width: 100%;
  font-size: 12px;
  font-weight: 500;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  width: 790px;
  min-height: 1109px;
  height: auto;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

  display: flex;
  flex-direction: column;
`;

const Table = styled.table`
  width: 100%;
`;

const Box = styled.div`
  padding: 20px;
`;

const BoxTitle = styled.h4`
  margin-top: 5px;
  font-size: 16px;
  text-align: center;
`;

const Row = styled.tr`
  &:nth-child(even) {
    background-color: #eaeaea;
  }
`;

const TableHeading = styled.th`
  border: 0.5px solid lightgray;
  padding-left: 5px;
  font-size: 13px;
  height: 30px;
  text-align: center;
`;

const Column = styled.td`
  border: 0.5px solid lightgray;
  padding: 0 8px;
  font-size: 13px;
  min-height: 30px;
  height: auto;
`;

const Heading = styled.div`
  box-sizing: border-box;
  padding: 20px 20px 10px 20px;
  display: flex;
  border-bottom: 0.5px solid lightgray;
`;

const Logo = styled.div`
  flex: 2;
`;

const Image = styled.img`
  width: 90%;
`;

const HeaderDetails = styled.div`
  flex: 9;
`;

const Name = styled.h2`
  font-size: 40px;
  font-weight: 900;
  font-family: "Dancing Script", cursive;
`;

const Disc = styled.div`
  flex: 1;
  font-size: 14px;
`;

const DisptachList = () => {
  const [defaultDate, setDefaultDate] = useState(getTodayDate());
  const [startDate, setStartDate] = useState(getTodayDate());
  const [isLoading, setIsLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [dispatchList, setDispatchList] = useState([]);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [pharmacy] = usePharmacy();
  const conponentPDF = useRef();

  //-----------------------------------------------------------------------
  //----------------------getTodayDate ------------------------------
  //-----------------------------------------------------------------------
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  //-----------------------------------------------------------------------
  //---------------------- handle Print Function ------------------------------
  //-----------------------------------------------------------------------

  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    documentTitle: "StockSummary",
  });

  //-----------------------------------------------------------------------
  //-------------------- Get Dispatch List -----------------------
  //-----------------------------------------------------------------------
  const getDispatchList = async (req, res) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/dispatch/user/${pharmacy?.pharmacy?._id}/${startDate}/${defaultDate}`
      );
      if (data && data.success) {
        setIsLoading(false);
        setDispatchList(data?.dispatchList);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error("Somthing Went Wrong");
    }
  };

  const printLoader = () => {
    setPrintLoading(true);
    setTimeout(() => {
      setPrintLoading(false);
    }, 2000);
  };

  //-----------------------------------------------------------------------
  //----------------------USE EFFECTS ------------------------------
  //-----------------------------------------------------------------------
  useEffect(() => {
    getDispatchList();
  }, [defaultDate, startDate]);

  useEffect(() => {
    // Calculate total discount and total amount when dispatchList changes
    let discountTotal = 0;
    let amountTotal = 0;

    dispatchList.forEach((dispatch) => {
      discountTotal += dispatch.disAmount || 0;
      amountTotal += dispatch.amount || 0;
    });

    setDiscount(discountTotal);
    setTotal(amountTotal);
  }, [dispatchList]);

  return (
    <Container>
      <Left>
        <UserSidebar />
      </Left>
      <Right>
        <DashNav />
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Home
            </Link>
          </Breadcrumbs>
        </BreadcrumbsContainer>

        <AddNewButtonContainer>
          <div style={{ display: "flex", gap: "20px" }}>
            <InputField>
              <Lable>Start Date : MM-DD-YYYY</Lable>
              <Input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </InputField>
            <InputField>
              <Lable>End Date : MM-DD-YYYY</Lable>
              <Input
                type="date"
                value={defaultDate}
                onChange={(e) => setDefaultDate(e.target.value)}
              />
            </InputField>{" "}
          </div>
          <Button
            variant="outlined"
            type="submit"
            className="button"
            onClick={() => {
              printLoader();
              generatePDF();
            }}
          >
            {printLoading ? <LoaderIcon /> : "Print"}
          </Button>
        </AddNewButtonContainer>

        <Wrapper>
          <div ref={conponentPDF}>
            <Heading>
              <Logo>
                <Image src="/img/pkhealthtech.png" />
              </Logo>
              <HeaderDetails>
                <Name>{pharmacy?.pharmacy?.pharmacyName}</Name>
                <Disc>
                  Drug Lic No. : {pharmacy?.pharmacy?.druglience} | GST No. :{" "}
                  {pharmacy?.pharmacy?.gstin}
                </Disc>
                <Disc>{pharmacy?.pharmacy?.address}</Disc>
                <Disc>
                  Phone : {pharmacy?.pharmacy?.phone} | Email :{" "}
                  {pharmacy?.pharmacy?.email} | Name :{" "}
                  {pharmacy?.pharmacy?.name}
                </Disc>
              </HeaderDetails>
            </Heading>
            <Box>
              <BoxTitle>Dispatch List</BoxTitle>
              {isLoading ? (
                <Loader />
              ) : (
                <Table>
                  <thead>
                    <Row>
                      <TableHeading>No.</TableHeading>
                      <TableHeading>Date(DD-MM-YYYY)</TableHeading>
                      <TableHeading>Patient Name</TableHeading>
                      <TableHeading>Patient Number</TableHeading>
                      <TableHeading>Dis %</TableHeading>
                      <TableHeading>Dis Amount</TableHeading>
                      <TableHeading>Amount</TableHeading>
                    </Row>
                  </thead>

                  <tbody>
                    {dispatchList &&
                      dispatchList.map((dispatch, i) => (
                        <>
                          <Row key={i}>
                            <Column>{i + 1}</Column>
                            <Column>{formatDate(dispatch?.invoiceDate)}</Column>
                            <Column>{dispatch?.patientName}</Column>
                            <Column>{dispatch?.patientNumber}</Column>
                            <Column className="textAlignCenter">
                              {dispatch?.dis}
                            </Column>
                            <Column className="textAlignRight">
                              {dispatch?.disAmount?.toFixed(2)}
                            </Column>
                            <Column className="textAlignRight">
                              {dispatch?.amount?.toFixed(2)}
                            </Column>
                          </Row>
                        </>
                      ))}
                    <Row>
                      <Column colSpan={5}></Column>
                      <Column colSpan={2} style={{ textAlign: "right" }}>
                        Total Dis Amount :
                        <b style={{ margin: "5px" }}>{discount?.toFixed(2)}</b>
                      </Column>
                    </Row>
                    <Row>
                      <Column colSpan={5} style={{ textAlign: "right" }}>
                        <CurrencyRupeeIcon sx={{ fontSize: "16px" }} />
                        {numberToWords(total)} Rupee Only
                      </Column>
                      <Column colSpan={2} style={{ textAlign: "right" }}>
                        Total Cash :
                        <span>
                          <b style={{ margin: "5px" }}>
                            <CurrencyRupeeIcon sx={{ fontSize: "16px" }} />
                            {total?.toFixed(2)}
                          </b>
                        </span>
                      </Column>
                    </Row>
                  </tbody>
                </Table>
              )}
            </Box>
          </div>
        </Wrapper>
      </Right>
    </Container>
  );
};

export default DisptachList;
