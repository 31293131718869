import styled from "styled-components";
import Sidebar from "../../components/admin/Sidebar";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import DashNav from "../../components/DashNAv";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
`;

const Left = styled.div`
  flex: 2;
`;

const Right = styled.div`
  flex: 11;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Title = styled.h1`
  margin: 20px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #3c4b64;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  color: #3c4b64;
  width: 60%;
  margin: 0 auto;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  padding: 10px;
  margin: 20px 10px 0 0;
`;

const Textarea = styled.textarea`
  padding: 10px;
  margin: 20px 10px 20px 0;

  &::placeholder {
    font-family: "Poppins", sans-serif;
  }
`;

const Button = styled.button`
  width: 99%;
  padding: 15px 30px;
  border: none;
  background-color: #3c4b64;
  color: white;
  cursor: pointer;
`;

const PharmacyProfile = () => {
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [pharmacyName, setPharmacyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [druglience, setDruglience] = useState("");
  const [gstin, setGstin] = useState("");
  const [address, setAddress] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  const getSinglePharmacy = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/pharmacy/single-pharmacy/${params.pid}`
      );
      if (data && data.success) {
        setId(data.pharmacy._id);
        setName(data.pharmacy.name);
        setPharmacyName(data.pharmacy.pharmacyName);
        setEmail(data.pharmacy.email);
        setPhone(data.pharmacy.phone);
        setUsername(data.pharmacy.username);
        setDruglience(data.pharmacy.druglience);
        setGstin(data.pharmacy.gstin);
        setAddress(data.pharmacy.address);
      }
    } catch (error) {
      console.log(error);
      toast.error("Somthing failed");
    }
  };

  useEffect(() => {
    params.pid && getSinglePharmacy();
  }, [params.pid]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_URL}api/v1/pharmacy/update-pharmacy/${id}`,
        {
          name: name,
          phone: phone,
          address: address,
          druglience: druglience,
          gstin: gstin,
        }
      );
      if (data?.success) {
        toast.success(data?.message);
        navigate("/dashboard/admin/pharmacy");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something Want Wrong");
    }
  };

  return (
    <Container>
      <Left>
        <Sidebar />
      </Left>
      <Right>
        <DashNav />
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/admin"
            >
              Home
            </Link>
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/admin/pharmacy"
            >
              Pharmacies
            </Link>
            <Link
              className="link"
              style={{ color: "#212333" }}
              to={`/dashboard/admin/pharmacy/edit-profile/`}
            >
              EDIT PROFILE
            </Link>
          </Breadcrumbs>
        </BreadcrumbsContainer>
        <Title>EDIT PHARMACY PROFILE</Title>

        <Form onSubmit={handleSubmit}>
          <Input
            required
            placeholder="Enter Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            disabled
            required
            placeholder="Enter Your Pharmacy Name"
            value={pharmacyName}
            onChange={(e) => setPharmacyName(e.target.value)}
          />
          <Input
            required
            type="email"
            placeholder="Enter Your Email"
            value={email}
            disabled
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            required
            placeholder="Enter Your Phone Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Input
            required
            placeholder="Enter Your Username"
            value={username}
            disabled
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            required
            type="password"
            placeholder="Enter Your Password"
            value={password}
            disabled
            onChange={(e) => setPassword(e.target.value)}
          />
          <Input
            required
            placeholder="Enter Your Drug License Number"
            value={druglience}
            onChange={(e) => setDruglience(e.target.value)}
          />
          <Input
            required
            placeholder="Enter Your GST License Number"
            value={gstin}
            onChange={(e) => setGstin(e.target.value)}
          />
          <Textarea
            name=""
            id=""
            cols="95"
            rows="5"
            placeholder="Enter Your Pharmacy Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          ></Textarea>
          <Button type="submit">UPDATE</Button>
        </Form>
      </Right>
    </Container>
  );
};

export default PharmacyProfile;
