import styled from "styled-components";
import { Breadcrumbs } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/admin/Sidebar";
import DashNav from "../components/DashNAv";
import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { usePharmacy } from "../context/pharmacy";
import UserSidebar from "../components/user/UserSidebar";

const Container = styled.div`
  display: flex;
`;

const Left = styled.div`
  flex: 2;
`;

const Right = styled.div`
  flex: 11;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Title = styled.h1`
  margin: 20px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #3c4b64;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: #3c4b64;
  width: 40%;
  margin: 0 auto;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  padding: 10px;
  margin: 20px 10px 0 0;
`;

const Button = styled.button`
  margin-top: 20px;
  width: 99%;
  padding: 15px 30px;
  border: none;
  background-color: #3c4b64;
  color: white;
  cursor: pointer;
`;

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [pharmacy] = usePharmacy();
  const params = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_URL}api/v1/pharmacy/change-password/${params.pid}`,
        {
          newPassword,
          oldPassword,
        }
      );
      if (data?.success) {
        toast.success(data?.message);
        navigate(
          pharmacy?.pharmacy?.role === 1
            ? `/dashboard/admin`
            : "/dashboard/pharmacy"
        );
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Container>
      <Left>
        {pharmacy?.pharmacy?.role === 1 ? <Sidebar /> : <UserSidebar />}
      </Left>
      <Right>
        <DashNav />
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="link"
              style={{ color: "inherit" }}
              to={
                pharmacy?.pharmacy?.role === 1
                  ? `/dashboard/admin`
                  : "/dashboard/pharmacy"
              }
            >
              Home
            </Link>
            <Link className="link" style={{ color: "#212333" }}>
              CHANGE-PASSWORD
            </Link>
          </Breadcrumbs>
        </BreadcrumbsContainer>

        <Title>Change Password</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            required
            type="password"
            placeholder="Enter Your Old Password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />

          <Input
            required
            type="password"
            placeholder="Enter Your New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />

          <Button type="submit">UPDATE</Button>
        </Form>
      </Right>
    </Container>
  );
};

export default ChangePassword;
