import styled from "styled-components";
import DashNav from "../../components/DashNAv";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import UserSidebar from "../../components/user/UserSidebar";

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Left = styled.div`
  flex: 2;
`;

const Right = styled.div`
  flex: 11;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const UserDashborad = () => {
  return (
    <Container>
      <Left>
        <UserSidebar />
      </Left>
      <Right>
        <DashNav />
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Home
            </Link>
          </Breadcrumbs>
        </BreadcrumbsContainer>
      </Right>
    </Container>
  );
};

export default UserDashborad;
