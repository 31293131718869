import styled from "styled-components";
import DashNav from "../../components/DashNAv";
import { Breadcrumbs } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import UserSidebar from "../../components/user/UserSidebar";
import axios from "axios";
import toast from "react-hot-toast";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Loader from "../../components/Loader";

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Left = styled.div`
  flex: 2;
`;

const Right = styled.div`
  flex: 11;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Title = styled.h1`
  margin: 20px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #3c4b64;
`;

const AddNewButtonContainer = styled.div`
  width: 90%;
  display: flex;
  margin: 0 auto;
  .button {
    background-color: transparent;
    color: #212333;
    font-weight: 600;
    border: 2px solid #212333;
    &:hover {
      color: white;
      background-color: var(--secondary-color);
      border: 2px solid teal;
    }
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  border-radius: 10px;
  width: 100%;
  margin: 20px 0;
`;

const Row = styled.tr`
  width: 100%;
`;
const Heading = styled.th`
  height: 50px;
  text-align: left;
  color: var(--primary-color);
  border-bottom: 0.5px solid lightgrey;
`;

const Column = styled.td`
  border-bottom: 0.5px solid lightgrey;
  border-collapse: collapse;
  height: 50px;
  text-align: left;
  color: var(--secondary-color);
`;

const EditButton = styled.button`
  padding: 7px;
  font-size: 10px;
  background-color: #00aeff;
  border: none;
  outline: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 5px;
`;

const DeleteButton = styled.button`
  padding: 7px;
  font-size: 10px;
  background-color: red;
  border: none;
  outline: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
`;

const AllDurgs = () => {
  const navigate = useNavigate();
  const [drugs, setDurgs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //-------------------- Get User Drugs -----------------------

  const getUserDrugs = async (req, res) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/drug`
      );
      if (data && data.success) {
        setIsLoading(false);
        setDurgs(data?.drugs);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error("Somthing Went Wrong");
    }
  };

  useEffect(() => {
    getUserDrugs();
  }, []);

  const handleDelete = async (id) => {
    try {
      const answer = window.confirm("Are you sure?");

      if (!answer) return;

      const res = await axios.delete(
        `${process.env.REACT_APP_URL}api/v1/drug/delete-user-drug/${id}`
      );
      if (res?.data.success) {
        toast.success("Deleted successfully");
        getUserDrugs();
      } else {
        toast.error(res.data.massage);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Container>
      <Left>
        <UserSidebar />
      </Left>
      <Right>
        <DashNav />
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Home
            </Link>
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Drug Master
            </Link>
            <Link
              className="link"
              style={{ color: "#3c4b64" }}
              to="/dashboard/pharmacy/add-new-drug"
            >
              ALL DRUGS
            </Link>
          </Breadcrumbs>
        </BreadcrumbsContainer>
        <Title>ALL DRUGS</Title>
        <AddNewButtonContainer>
          <Button
            variant="outlined"
            type="submit"
            style={{ flex: "1" }}
            className="button"
            onClick={() => navigate("/dashboard/pharmacy/add-new-drug")}
          >
            + Add New
          </Button>
        </AddNewButtonContainer>
        {isLoading ? (
          <div style={{ marginTop: "20px" }}>
            <Loader />
          </div>
        ) : (
          <Table>
            <thead>
              <Row>
                <Heading className="textAlignCenter">No.</Heading>
                <Heading>Active</Heading>
                <Heading>Itemcode</Heading>
                <Heading>Brand Name</Heading>
                <Heading>Generic Name</Heading>
                <Heading className="textAlignCenter">GST %</Heading>
                <Heading className="textAlignCenter"> Action</Heading>
              </Row>
            </thead>
            <tbody>
              {drugs &&
                drugs.map((d, i) => (
                  <Row key={i}>
                    <Column className="textAlignCenter">{i + 1}</Column>
                    <Column>{d.active === 1 ? "Yes" : "No"}</Column>
                    <Column>{d.itemCode}</Column>
                    <Column>{d.brandName}</Column>
                    <Column style={{ maxWidth: "300px" }}>
                      {d.genericName}
                    </Column>
                    <Column className="textAlignCenter">{d.gst}</Column>

                    <Column className="textAlignCenter">
                      {/* <EditButton
                      onClick={() =>
                        navigate(
                          `/dashboard/admin/pharmacy/edit-profile/${d._id}`
                        )
                      }
                    >
                      <ModeEditIcon />
                    </EditButton> */}
                      <DeleteButton onClick={() => handleDelete(d._id)}>
                        <DeleteIcon />
                      </DeleteButton>
                    </Column>
                  </Row>
                ))}
            </tbody>
          </Table>
        )}
      </Right>
    </Container>
  );
};

export default AllDurgs;
