import styled from "styled-components";
import DashNav from "../../components/DashNAv";
import { Breadcrumbs } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import UserSidebar from "../../components/user/UserSidebar";
import { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Button } from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import GrnDetails from "../../components/user/GrnDetails";
import Loader from "../../components/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Left = styled.div`
  flex: 2;
`;

const Right = styled.div`
  flex: 11;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Title = styled.h1`
  margin: 20px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #3c4b64;
`;

const AddNewButtonContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  .button {
    background-color: transparent;
    color: #212333;
    font-weight: 600;
    border: 2px solid #212333;
    &:hover {
      color: white;
      background-color: var(--secondary-color);
      border: 2px solid teal;
    }
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  border-radius: 10px;
  width: 100%;
  margin: 20px 0;
  padding: 20px;
`;

const Row = styled.tr`
  width: 100%;
  color: black;
`;
const Heading = styled.th`
  height: 50px;
  text-align: center;
  color: var(--primary-color);
  border-bottom: 0.5px solid lightgrey;

  background-color: var(--secondary-color);
  color: white;
`;

const Column = styled.td`
  border-bottom: 0.5px solid lightgrey;
  border-collapse: collapse;
  height: 50px;
  text-align: center;
  color: var(--secondary-color);
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 10px;
  ::placeholder {
    color: gray !important;
  }
`;

const InputField = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px 0;
  position: relative;
`;

const Lable = styled.label`
  width: 100%;
  font-size: 12px;
  font-weight: 500;
`;

const PuchaseList = () => {
  const navigate = useNavigate();
  const [grns, setGrns] = useState([]);
  const [defaultDate, setDefaultDate] = useState(getTodayDate());
  const [startDate, setStartDate] = useState(getTodayDate());
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //-----------------------------------------------------------------------
  //---------------------- Model Open Close Fuction-----------------------
  //-----------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setItem([]);
  };

  //-----------------------------------------------------------------------
  //-------------------- Get User Grn -----------------------
  //-----------------------------------------------------------------------
  const getUserGrn = async (req, res) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/grn/user-grn/${startDate}/${defaultDate}`
      );
      if (data && data.success) {
        setIsLoading(false);
        setGrns(data?.grns);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error("Somthing Went Wrong");
    }
  };

  //-----------------------------------------------------------------------
  //----------------------USE EFFECTS ------------------------------
  //-----------------------------------------------------------------------
  useEffect(() => {
    getUserGrn();
  }, [defaultDate, startDate]);

  //-----------------------------------------------------------------------
  //----------------------getTodayDate ------------------------------
  //-----------------------------------------------------------------------
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  return (
    <Container>
      <Left>
        <UserSidebar />
      </Left>
      <Right>
        <DashNav />
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Home
            </Link>
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Inventory
            </Link>
            <Link
              className="link"
              style={{ color: "#212333" }}
              to="/dashboard/pharmacy/purchase-order-list"
            >
              PURCHASE LIST
            </Link>
          </Breadcrumbs>
        </BreadcrumbsContainer>
        <Title>GRN LIST</Title>
        <AddNewButtonContainer>
          <div style={{ display: "flex", gap: "20px" }}>
            <InputField>
              <Lable>Start Date : MM-DD-YYYY</Lable>
              <Input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </InputField>
            <InputField>
              <Lable>End Date : MM-DD-YYYY</Lable>
              <Input
                type="date"
                value={defaultDate}
                onChange={(e) => setDefaultDate(e.target.value)}
              />
            </InputField>
          </div>
          <Button
            variant="outlined"
            type="submit"
            className="button"
            onClick={() => navigate("/dashboard/pharmacy/purchase-order")}
          >
            + Add New
          </Button>
        </AddNewButtonContainer>
        {isLoading ? (
          <Loader />
        ) : (
          <Table>
            <thead>
              <Row>
                <Heading>No.</Heading>
                <Heading>Invoice Date</Heading>
                <Heading>Invoice No.</Heading>
                <Heading>Supplier Name</Heading>
                <Heading>Dis %</Heading>
                <Heading>Amount</Heading>
                <Heading>Action</Heading>
              </Row>
            </thead>
            <tbody>
              {grns &&
                grns.map((g, i) => (
                  <>
                    <Row key={i}>
                      <Column>{i + 1}</Column>
                      <Column>{g.invoiceDate}</Column>
                      <Column>{g.invoiceNumber}</Column>
                      <Column>{g.suppilerName}</Column>
                      <Column>{g.dis} %</Column>
                      <Column>Rs. {g.amount}</Column>

                      <Column
                        onClick={() => {
                          handleOpen();
                          setItem(g);
                        }}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        More Details
                      </Column>
                    </Row>
                  </>
                ))}
            </tbody>
          </Table>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <GrnDetails item={item} />
          </Box>
        </Modal>
      </Right>
    </Container>
  );
};

export default PuchaseList;
