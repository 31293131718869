import styled from "styled-components";
import DashNav from "../../components/DashNAv";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import UserSidebar from "../../components/user/UserSidebar";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { createBrowserHistory } from "history";
import { usePharmacy } from "../../context/pharmacy";
import { useReactToPrint } from "react-to-print";
import Loader from "../../components/Loader";

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Left = styled.div`
  flex: 2;
`;

const Right = styled.div`
  flex: 11;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const FilterContainer = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const InputField = styled.div`
  flex: 1;
  width: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .reset {
    background-color: transparent;
    color: black;
    border: none;
    text-decoration: underline;
  }
`;

const Lable = styled.label`
  margin-left: 5px;
  font-size: 14px;
  letter-spacing: 2px;
  color: black;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
`;

const SelectHTML = styled.select`
  padding: 10px;
  border-radius: 10px;
`;

const OptionHTML = styled.option``;

const Button = styled.button`
  padding: 7px 14px;
  border-radius: 10px;
  background-color: #3a86bc;
  color: white;
  border-color: white;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  width: 790px;
  min-height: 1109px;
  height: auto;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

  display: flex;
  flex-direction: column;
`;

const Table = styled.table`
  width: 100%;
`;

const Box = styled.div`
  padding: 20px;
`;

const BoxTitle = styled.h4`
  margin-top: 5px;
  font-size: 16px;
  text-align: center;
`;

const Row = styled.tr`
  &:nth-child(even) {
    background-color: #eaeaea;
  }
`;

const TableHeading = styled.th`
  border: 0.5px solid lightgray;
  padding-left: 5px;
  font-size: 13px;
  height: 30px;
  text-align: center;
`;

const Column = styled.td`
  border: 0.5px solid lightgray;
  padding: 0 8px;
  font-size: 13px;
  min-height: 30px;
  height: auto;
`;

const Heading = styled.div`
  box-sizing: border-box;
  padding: 20px 20px 10px 20px;
  display: flex;
  border-bottom: 0.5px solid lightgray;
`;

const Logo = styled.div`
  flex: 2;
`;

const Image = styled.img`
  width: 90%;
`;

const HeaderDetails = styled.div`
  flex: 9;
`;

const Name = styled.h2`
  font-size: 40px;
  font-weight: 900;
  font-family: "Dancing Script", cursive;
`;

const Disc = styled.div`
  flex: 1;
  font-size: 14px;
`;

const StockSummary = () => {
  const [name, setName] = useState("");
  const [formulation, setFormulation] = useState("");
  const [sort, setSort] = useState("");
  const [quantityLevel, setQuantityLevel] = useState(false);
  const [expire, setExpire] = useState(false);
  const [stocks, setStocks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = createBrowserHistory();
  const [pharmacy] = usePharmacy();
  const conponentPDF = useRef();

  useEffect(() => {
    quantityLevel ? getLowLevelStock() : getuserStock();
  }, [name, formulation, sort, quantityLevel]);

  useEffect(() => {
    expire ? getExpireStock() : getuserStock();
  }, [expire]);

  const resetFilters = () => {
    setName("");
    setFormulation("");
    setSort("");
    setQuantityLevel("");
    setExpire(false);
  };

  //-----------------------------------------------------------------------
  //---------------------- handle Print Function ------------------------------
  //-----------------------------------------------------------------------

  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    documentTitle: "StockSummary",
  });

  //-----------------------------------------------------------------------
  //----------------------get User Stock ------------------------------
  //-----------------------------------------------------------------------

  const getuserStock = async () => {
    try {
      setIsLoading(true);
      const queryParams = new URLSearchParams({
        name,
        formulation,
        sort,
      });

      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/stock?${queryParams}`
      );

      if (data && data.success) {
        setIsLoading(false);
        setStocks(data?.stocks);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error("Something Went Wrong");
    }
  };

  const getLowLevelStock = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/stock/low-level/${pharmacy.pharmacy._id}`
      );

      if (data && data.success) {
        setIsLoading(false);
        setStocks(data?.stocks);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Something Went Wrong");
    }
  };

  const getExpireStock = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/stock/expire-item/${pharmacy.pharmacy._id}`
      );

      if (data && data.success) {
        setIsLoading(false);
        setStocks(data?.stocks);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error("Something Went Wrong");
    }
  };

  // Function to update query parameters in URL
  const updateQueryParams = () => {
    const queryParams = new URLSearchParams({
      name,
      formulation,
      sort,
    });

    history.push({
      pathname: "/dashboard/pharmacy/reports/stock-summary/", // adjust the pathname as needed
      search: queryParams.toString(),
    });
  };

  // Call updateQueryParams whenever name, formulation, or sort changes
  useEffect(() => {
    updateQueryParams();
  }, [name, formulation, sort, quantityLevel]);

  //-----------------------------------------------------------------------
  //----------------------Calculate Amount ------------------------------
  //-----------------------------------------------------------------------

  const calculateAmount = (b) => {
    return ((b?.quantity / b?.stripSize) * (b?.mrp * b?.stripSize)).toFixed(2);
  };

  return (
    <Container>
      <Left>
        <UserSidebar />
      </Left>
      <Right>
        <DashNav />
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Home
            </Link>
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Reports
            </Link>
            <Link className="link" style={{ color: "#212333" }}>
              STOCK SUMMARY
            </Link>
          </Breadcrumbs>
        </BreadcrumbsContainer>

        <FilterContainer>
          <InputField>
            <Lable>Search : </Lable>
            <Input
              placeholder="Seacrh..."
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </InputField>
          <InputField>
            <Lable>Category: </Lable>
            <SelectHTML
              value={formulation}
              onChange={(e) => setFormulation(e.target.value)}
            >
              <OptionHTML defaultChecked value="">
                Select a formulation
              </OptionHTML>
              <OptionHTML value="">all</OptionHTML>
              <OptionHTML>Tablet</OptionHTML>
              <OptionHTML>Capsules</OptionHTML>
              <OptionHTML>Injection</OptionHTML>
              <OptionHTML>Syrup</OptionHTML>
              <OptionHTML>Sergicals</OptionHTML>
              <OptionHTML>FMCG</OptionHTML>
              <OptionHTML>Suppositories</OptionHTML>
              <OptionHTML>Drops</OptionHTML>
              <OptionHTML>Inhalers</OptionHTML>
              <OptionHTML>Implants or patches</OptionHTML>
              <OptionHTML>Other</OptionHTML>
            </SelectHTML>
          </InputField>
          <InputField>
            <Lable>Brand Name: </Lable>
            <SelectHTML value={sort} onChange={(e) => setSort(e.target.value)}>
              <OptionHTML value="atoz">A to Z</OptionHTML>
              <OptionHTML value="ztoa">Z to A</OptionHTML>
            </SelectHTML>
          </InputField>
          <InputField>
            <Lable>Que level: </Lable>
            <SelectHTML
              value={quantityLevel}
              onChange={(e) => setQuantityLevel(e.target.value)}
            >
              <OptionHTML defaultChecked value="">
                Select A Level
              </OptionHTML>
              <OptionHTML value="true">Level 0</OptionHTML>
            </SelectHTML>
          </InputField>
          {/* <InputField>
            <Lable>Exipiry Date: </Lable>
            <SelectHTML
              value={expire}
              onChange={(e) => setExpire(e.target.value)}
            >
              <OptionHTML value={false}>All</OptionHTML>
              <OptionHTML value={true}>See Expire Medicine</OptionHTML>
            </SelectHTML>
          </InputField> */}
          <InputField>
            <button className="reset" onClick={resetFilters}>
              Rest Filters
            </button>
            <Button onClick={generatePDF}>Print</Button>
          </InputField>
        </FilterContainer>

        <Wrapper>
          <div ref={conponentPDF}>
            <Heading>
              <Logo>
                <Image src="/img/pkhealthtech.png" />
              </Logo>
              <HeaderDetails>
                <Name>{pharmacy?.pharmacy?.pharmacyName}</Name>
                <Disc>
                  Drug Lic No. : {pharmacy?.pharmacy?.druglience} | GST No. :{" "}
                  {pharmacy?.pharmacy?.gstin}
                </Disc>
                <Disc>{pharmacy?.pharmacy?.address}</Disc>
                <Disc>
                  Phone : {pharmacy?.pharmacy?.phone} | Email :{" "}
                  {pharmacy?.pharmacy?.email} | Name :{" "}
                  {pharmacy?.pharmacy?.name}
                </Disc>
              </HeaderDetails>
            </Heading>
            <Box>
              <BoxTitle>Stock Summary</BoxTitle>
              {isLoading ? (
                <Loader />
              ) : (
                <Table>
                  <thead>
                    <Row>
                      <TableHeading>No.</TableHeading>
                      <TableHeading>Category</TableHeading>
                      <TableHeading>Item Name</TableHeading>
                      <TableHeading>Batch No.</TableHeading>
                      <TableHeading>Exp. Date</TableHeading>
                      <TableHeading>Que</TableHeading>
                      <TableHeading>Unit</TableHeading>
                      <TableHeading>Mrp</TableHeading>
                      <TableHeading>GST %</TableHeading>
                      <TableHeading>Amount</TableHeading>
                    </Row>
                  </thead>

                  <tbody>
                    {stocks &&
                      stocks.map((item, index) => (
                        <Row key={index}>
                          <Column>{index + 1}</Column>
                          <Column>{item.formulation}</Column>
                          <Column>{item.brandName}</Column>
                          <Column>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {item?.batch?.map((b, i) => (
                                <span>{b?.batchNo}</span>
                              ))}
                            </div>
                          </Column>
                          <Column>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {item?.batch?.map((b, i) => (
                                <span>{b?.expiryDate}</span>
                              ))}
                            </div>
                          </Column>
                          <Column>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {item?.batch?.length === 0 && "0"}
                              {item?.batch?.map((b, i) => (
                                <span>{b?.quantity / b?.stripSize}</span>
                              ))}
                            </div>
                          </Column>
                          <Column>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {item?.batch?.map((b, i) => (
                                <span>
                                  {b?.stripSize}{" "}
                                  {b?.stripSize === 1 ? "Number" : "X strip"}
                                </span>
                              ))}
                            </div>
                          </Column>
                          <Column>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              {item?.batch?.map((b, i) => (
                                <span>
                                  {(b?.mrp * b?.stripSize).toFixed(2)}
                                </span>
                              ))}
                            </div>
                          </Column>
                          <Column>{item.gst}</Column>
                          <Column>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "right",
                              }}
                            >
                              {item?.batch?.map((b, i) => (
                                <span>{calculateAmount(b)}</span>
                              ))}
                            </div>
                          </Column>
                        </Row>
                      ))}
                  </tbody>
                </Table>
              )}
            </Box>
          </div>
        </Wrapper>
      </Right>
    </Container>
  );
};

export default StockSummary;
