import styled from "styled-components";
import DashNav from "../../components/DashNAv";
import { Breadcrumbs } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import UserSidebar from "../../components/user/UserSidebar";
import axios from "axios";
import toast, { LoaderIcon } from "react-hot-toast";
import { useEffect, useState } from "react";
import { usePharmacy } from "../../context/pharmacy";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Loader from "../../components/Loader";

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Left = styled.div`
  flex: 2;
`;

const Right = styled.div`
  flex: 11;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  width: 790px;
  height: 1109px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: 20px 20px 10px 20px;
  display: flex;
  border-bottom: 0.5px solid lightgray;
`;

const Logo = styled.div`
  flex: 2;
`;

const Image = styled.img`
  width: 90%;
`;

const HeaderDetails = styled.div`
  flex: 9;
`;

const Title = styled.h2`
  font-size: 40px;
  font-weight: 900;
  font-family: "Dancing Script", cursive;
`;

const Disc = styled.div`
  flex: 1;
  font-size: 14px;
`;

const Main = styled.div`
  box-sizing: border-box;
  flex: 3;
  padding: 10px;
`;
const Patient = styled.div``;

const PatientDetails = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid lightgray;
`;
const Box = styled.div``;

const BoxTitle = styled.h4`
  margin-top: 5px;
  font-size: 16px;
  text-align: center;
`;

const Table = styled.table`
  width: 100%;
`;

const Row = styled.tr``;

const TableHeading = styled.th`
  border: 1px solid black;
  padding-left: 5px;
  font-size: 13px;
  height: 30px;
  text-align: center;
`;

const Column = styled.td`
  border: 1px solid black;
  padding: 0 8px;
  font-size: 13px;
  height: 30px;
`;

const Footer = styled.div`
  flex: 0.5;
  box-sizing: border-box;
  padding: 10px;
`;

const PrinterController = styled.div`
  margin: 0 auto;
  width: 790px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Button = styled.button`
  padding: 7px 14px;
  border-radius: 10px;
  background-color: #3a86bc;
  color: white;
  border-color: white;
`;

const Invocie = () => {
  const params = useParams();
  const [invoiceDetails, setInvoiceDetaisl] = useState([]);
  const [Isloading, setIsLoading] = useState(false);
  const [pharmacy] = usePharmacy();
  const wrapperRef = useRef(null);
  const [printLoading, setPrintLoading] = useState(false);

  function numberToWords(number) {
    const units = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
    ];
    const teens = [
      "",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];
    const tens = [
      "",
      "ten",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const scales = ["", "thousand", "million", "billion", "trillion"];

    const numToWords = (num) => {
      if (num === 0) return "zero";

      let words = "";

      for (let i = 0; i < scales.length; i++) {
        const divisor = Math.pow(1000, i);
        const chunk = Math.floor(num / divisor) % 1000;

        if (chunk !== 0) {
          let chunkWords = "";

          const hundreds = Math.floor(chunk / 100);
          const tensAndUnits = chunk % 100;

          if (hundreds !== 0) {
            chunkWords += units[hundreds] + " hundred";
            if (tensAndUnits !== 0) chunkWords += " ";
          }

          if (tensAndUnits !== 0) {
            if (tensAndUnits < 10) {
              chunkWords += units[tensAndUnits];
            } else if (tensAndUnits < 20) {
              chunkWords += teens[tensAndUnits - 10];
            } else {
              const tensDigit = Math.floor(tensAndUnits / 10);
              const unitsDigit = tensAndUnits % 10;
              chunkWords += tens[tensDigit];
              if (unitsDigit !== 0) chunkWords += "-" + units[unitsDigit];
            }
          }

          chunkWords += " " + scales[i];

          if (words !== "") words = chunkWords + ", " + words;
          else words = chunkWords;
        }
      }

      return words.trim();
    };

    return numToWords(number);
  }

  const handlePrint = () => {
    // Get the wrapper element
    const wrapper = wrapperRef.current;

    // Generate a screenshot of the wrapper
    html2canvas(wrapper, { scale: 2, scrollY: -window.scrollY }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");

        // Set PDF size to A4
        const pdf = new jsPDF("p", "mm", "a4");
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();

        // Add the screenshot as an image to the PDF
        pdf.addImage(imgData, "PNG", 0, 0, width, height);

        // Open the print dialog
        pdf.autoPrint();
        window.open(pdf.output("bloburl"), "_blank");

        // Reset scroll position
        window.scrollTo(0, 0);
      }
    );
  };

  const printLoader = () => {
    setPrintLoading(true);
    setTimeout(() => {
      setPrintLoading(false);
    }, 2000);
  };

  //---------------------------------------------------
  //--------------- Get Invoice Details ----------------------
  //---------------------------------------------------

  const getInvoiceDetails = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/dispatch/single-dispatch/${params.id}`
      );
      if (data?.success) {
        setIsLoading(false);
        setInvoiceDetaisl(data?.dispatch);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getInvoiceDetails();
  }, []);

  return (
    <Container>
      <Left>
        <UserSidebar />
      </Left>
      <Right>
        <DashNav />
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Home
            </Link>
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy/dispatch"
            >
              Dispatch
            </Link>
            <Link className="link" style={{ color: "inherit" }}>
              Invoice
            </Link>
            <Link className="link" style={{ color: "#212333" }}>
              {invoiceDetails?.patientName?.toUpperCase()}
            </Link>
          </Breadcrumbs>
        </BreadcrumbsContainer>
        <PrinterController>
          <Button
            onClick={() => {
              printLoader();
              handlePrint();
            }}
          >
            {printLoading ? <LoaderIcon /> : "Print"}
          </Button>
        </PrinterController>
        <Wrapper ref={wrapperRef}>
          <div style={{ margin: "20px" }}>
            <Heading>
              <Logo>
                <Image src="/img/pkhealthtech.png" />
              </Logo>
              <HeaderDetails>
                <Title>{pharmacy?.pharmacy?.pharmacyName}</Title>
                <Disc>
                  Drug Lic No. : {pharmacy?.pharmacy?.druglience} | GST No. :{" "}
                  {pharmacy?.pharmacy?.gstin}
                </Disc>
                <Disc>{pharmacy?.pharmacy?.address}</Disc>
                <Disc>
                  Phone : {pharmacy?.pharmacy?.phone} | Email :{" "}
                  {pharmacy?.pharmacy?.email} | Name :{" "}
                  {pharmacy?.pharmacy?.name}
                </Disc>
              </HeaderDetails>
            </Heading>
            <Main>
              <Patient>
                <PatientDetails style={{ border: "none" }}>
                  <Disc>
                    Invoice Number : {invoiceDetails?._id?.slice(-10)}
                  </Disc>
                  <Disc style={{ textAlign: "right" }}>
                    Invoice Date : {invoiceDetails?.invoiceDate}
                  </Disc>
                </PatientDetails>
                <PatientDetails>
                  <Disc>Patient Name : {invoiceDetails?.patientName}</Disc>
                  <Disc>Phone Number : {invoiceDetails?.patientNumber}</Disc>
                  <Disc>Doctor : {invoiceDetails?.doctorName}</Disc>
                </PatientDetails>
              </Patient>
              <Box>
                <BoxTitle>Tex Invoice</BoxTitle>
                {Isloading ? (
                  <Loader />
                ) : (
                  <Table>
                    <thead>
                      <Row>
                        <TableHeading>No.</TableHeading>
                        <TableHeading>Item Name</TableHeading>
                        <TableHeading>Batch No.</TableHeading>
                        <TableHeading>Exp. Date</TableHeading>
                        <TableHeading>Quantiy</TableHeading>
                        <TableHeading>Unit</TableHeading>
                        <TableHeading>Mrp</TableHeading>
                        <TableHeading>GST %</TableHeading>
                        <TableHeading>Texable</TableHeading>
                        <TableHeading>Amount</TableHeading>
                      </Row>
                    </thead>
                    {invoiceDetails?.itemList?.length > 0 && (
                      <tbody>
                        {invoiceDetails?.itemList.map((item, i) => (
                          <Row>
                            <Column style={{ textAlign: "center" }}>
                              {i + 1}
                            </Column>
                            <Column>{item.itemName}</Column>
                            <Column>{item.batchNo}</Column>
                            <Column>{item.expiryDate}</Column>
                            <Column style={{ textAlign: "center" }}>
                              {item.quantity}
                            </Column>
                            <Column style={{ textAlign: "center" }}>
                              {item.unit}
                              {item.unit === "1" ? " Number" : " X strip"}
                            </Column>
                            <Column style={{ textAlign: "right" }}>
                              {item.mrp}
                            </Column>
                            <Column style={{ textAlign: "right" }}>
                              {item.gst}{" "}
                            </Column>
                            <Column style={{ textAlign: "right" }}>
                              {item.texable.toFixed(2)}
                            </Column>
                            <Column style={{ textAlign: "right" }}>
                              {item.itemTotal}
                            </Column>
                          </Row>
                        ))}
                        <Row>
                          <Column colSpan={8}></Column>
                          <Column colSpan={2}>
                            Dis Amo. :{" "}
                            {invoiceDetails?.disAmount === 0 ? " " : "- "}
                            {invoiceDetails?.disAmount}
                          </Column>
                        </Row>
                        <Row>
                          <Column colSpan={8}>
                            <CurrencyRupeeIcon sx={{ fontSize: "16px" }} />{" "}
                            {numberToWords(invoiceDetails?.amount)} Rupee Only
                          </Column>
                          <Column colSpan={2}>
                            Amount :
                            <b>
                              <CurrencyRupeeIcon sx={{ fontSize: "16px" }} />
                              {invoiceDetails?.amount}
                            </b>
                          </Column>
                        </Row>
                      </tbody>
                    )}
                  </Table>
                )}
              </Box>
            </Main>
            <Footer>
              <Disc style={{ fontSize: "12px", lineHeight: "1.1" }}>
                <FiberManualRecordIcon style={{ fontSize: "11px" }} /> Price are
                fluctuation based on Drugs Price Control Act.
              </Disc>
              <Disc style={{ fontSize: "12px", lineHeight: "1.1" }}>
                <FiberManualRecordIcon style={{ fontSize: "11px" }} /> દવા પરત
                આપતી વખત બિલ લાવવું જરૂરી• દવા ૩૦ દિવસ મા પરત આપવાના રહેશે.
                ભૂલચૂક લેવી દેવી.
              </Disc>
              <Disc style={{ fontSize: "12px", lineHeight: "1.1" }}>
                <FiberManualRecordIcon style={{ fontSize: "11px" }} /> Software
                By pkHealthTech.com
              </Disc>
            </Footer>
          </div>
        </Wrapper>
      </Right>
    </Container>
  );
};

export default Invocie;
