import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import Navbar from "../components/Navbar";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Heading = styled.h1`
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
`;

const TextArea = styled.textarea`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
`;

const Button = styled.button`
  padding: 12px 20px;
  font-size: 18px;
  background-color: #3c4b64;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;

  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function ContactUsPage() {
  const [name, setName] = useState("");
  const [pharmacyName, setPharmacyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setphone] = useState("");
  const [address, setAddress] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}api/v1/pharmacy/user-register`,
        {
          name: name,
          pharmacyName: pharmacyName,
          email: email,
          phone: phone,
          address: address,
        }
      );

      if (data?.success) {
        toast.success(data?.message);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something Want Wrong");
    }
  };
  return (
    <>
      <Navbar />
      <BreadcrumbsContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link className="link" style={{ color: "inherit" }} to="/">
            Home
          </Link>
          <Link className="link" style={{ color: "#212333" }} to="/contact-us">
            CONTACT US
          </Link>
        </Breadcrumbs>
      </BreadcrumbsContainer>
      <Container>
        <Heading>Contact Us</Heading>
        <Paragraph>
          Have a question or inquiry? We'd love to hear from you. Fill out the
          form below and we'll get back to you as soon as possible.
        </Paragraph>

        <Form onSubmit={(e) => handleSubmit(e)}>
          <Input
            required
            placeholder="Enter Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            required
            placeholder="Enter Your Pharmacy Name"
            value={pharmacyName}
            onChange={(e) => setPharmacyName(e.target.value)}
          />
          <Input
            required
            type="email"
            placeholder="Enter Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            required
            placeholder="Enter Your Phone Number"
            value={phone}
            onChange={(e) => setphone(e.target.value)}
          />
          <TextArea
            name=""
            id=""
            cols="95"
            rows="6"
            placeholder="Enter Your Pharmacy Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          ></TextArea>
          <Button type="submit">Send Message</Button>
        </Form>
      </Container>
    </>
  );
}

export default ContactUsPage;
