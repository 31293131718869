import styled from "styled-components";
import Sidebar from "../../components/admin/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import DashNav from "../../components/DashNAv";
import axios from "axios";
import toast from "react-hot-toast";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";

const Container = styled.div`
  display: flex;
`;

const Left = styled.div`
  flex: 2;
`;

const Right = styled.div`
  flex: 11;
`;
const Title = styled.h1`
  margin: 20px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #3c4b64;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const AddNewButtonContainer = styled.div`
  width: 90%;
  display: flex;
  margin: 0 auto;
  .button {
    background-color: transparent;
    color: #212333;
    font-weight: 600;
    border: 2px solid #212333;
    &:hover {
      color: white;
      background-color: var(--secondary-color);
      border: 2px solid teal;
    }
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  border-radius: 10px;
  width: 100%;
  margin: 20px 0;
`;

const Row = styled.tr`
  width: 100%;
`;
const Heading = styled.th`
  height: 50px;
  text-align: center;
  color: var(--primary-color);
  border-bottom: 0.5px solid lightgrey;
`;

const Column = styled.td`
  border-bottom: 0.5px solid lightgrey;
  border-collapse: collapse;
  height: 50px;
  text-align: center;
  color: var(--secondary-color);
`;

const EditButton = styled.button`
  padding: 7px;
  font-size: 10px;
  background-color: #00aeff;
  border: none;
  outline: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 5px;
`;

const DeleteButton = styled.button`
  padding: 7px;
  font-size: 10px;
  background-color: red;
  border: none;
  outline: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
`;

const Pharmacies = () => {
  const navigate = useNavigate();
  const [pharmacies, setPharmacies] = useState([]);

  //-------------------- Get all Pharmaies -----------------------

  const getPharmacies = async (req, res) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/pharmacy`
      );
      if (data && data.success) {
        setPharmacies(data?.pharmacies);
      }
    } catch (error) {
      console.log(error);
      toast.error("Somthing Went Wrong");
    }
  };

  useEffect(() => {
    getPharmacies();
  }, []);

  const handleDelete = async (id) => {
    try {
      const answer = window.confirm("Are you sure?");

      if (!answer) return;

      const res = await axios.delete(
        `${process.env.REACT_APP_URL}api/v1/pharmacy/delete/${id}`
      );
      if (res?.data.success) {
        toast.success("Deleted successfully");
        getPharmacies();
      } else {
        toast.error(res.data.massage);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Container>
      <Left>
        <Sidebar />
      </Left>
      <Right>
        <DashNav />
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/admin"
            >
              Home
            </Link>
            <Link
              className="link"
              style={{ color: "#212333", textTransform: "uppercase" }}
              to="/dashboard/admin/pharmacy"
            >
              Pharmacies
            </Link>
          </Breadcrumbs>
        </BreadcrumbsContainer>
        <Title>ALL PHARMACY</Title>
        <AddNewButtonContainer>
          <Button
            variant="outlined"
            type="submit"
            style={{ flex: "1" }}
            className="button"
            onClick={() => navigate("/dashboard/admin/add-pharmacy")}
          >
            + Add New
          </Button>
        </AddNewButtonContainer>
        <Table>
          <thead>
            <Row>
              <Heading>No.</Heading>
              <Heading>Owner Name</Heading>
              <Heading>Pharmacy Name</Heading>
              <Heading>Phone No.</Heading>
              <Heading>Email</Heading>
              <Heading>Drug Lience</Heading>
              <Heading>GST No.</Heading>
              <Heading>Action</Heading>
            </Row>
          </thead>
          <tbody>
            {pharmacies &&
              pharmacies.map((p, i) => (
                <Row key={i}>
                  <Column>{i + 1}</Column>
                  <Column>{p.name}</Column>
                  <Column>{p.pharmacyName}</Column>
                  <Column>{p.phone}</Column>
                  <Column>{p.email}</Column>
                  <Column>{p.druglience}</Column>
                  <Column>{p.gstin}</Column>
                  <Column>
                    <EditButton
                      onClick={() =>
                        navigate(
                          `/dashboard/admin/pharmacy/edit-profile/${p._id}`
                        )
                      }
                    >
                      <ModeEditIcon />
                    </EditButton>
                    <DeleteButton onClick={() => handleDelete(p._id)}>
                      <DeleteIcon />
                    </DeleteButton>
                  </Column>
                </Row>
              ))}
          </tbody>
        </Table>
      </Right>
    </Container>
  );
};

export default Pharmacies;
