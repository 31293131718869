import styled from "styled-components";
import DashNav from "../../components/DashNAv";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import UserSidebar from "../../components/user/UserSidebar";
import { useEffect, useRef, useState } from "react";
import { usePharmacy } from "../../context/pharmacy";
import axios from "axios";
import toast from "react-hot-toast";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useReactToPrint } from "react-to-print";
import Loader from "../../components/Loader";
import { numberToWords } from "../../components/Hepler/Helper";

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Left = styled.div`
  flex: 2;
`;

const Right = styled.div`
  flex: 11;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Title = styled.h1`
  margin: 20px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #3c4b64;
`;

const AddNewButtonContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  .button {
    background-color: transparent;
    color: #212333;
    font-weight: 600;
    border: 2px solid #212333;
    &:hover {
      color: white;
      background-color: var(--secondary-color);
      border: 2px solid teal;
    }
  }
`;

const Button = styled.button`
  padding: 7px 14px;
  border-radius: 10px;
  background-color: #3a86bc;
  color: white;
  border-color: white;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 10px;
  ::placeholder {
    color: gray !important;
  }
`;

const InputField = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px 0;
  position: relative;
`;

const Lable = styled.label`
  width: 100%;
  font-size: 12px;
  font-weight: 500;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  width: 790px;
  min-height: 1109px;
  height: auto;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  flex: 1;
  height: 30%;
  box-sizing: border-box;
  padding: 20px 20px 10px 20px;
  display: flex;
  border-bottom: 0.5px solid lightgray;
`;

const Logo = styled.div`
  flex: 2;
`;

const Image = styled.img`
  width: 90%;
`;

const HeaderDetails = styled.div`
  flex: 9;
`;

const Name = styled.h2`
  font-size: 40px;
  font-weight: 900;
  font-family: "Dancing Script", cursive;
`;

const Disc = styled.div`
  flex: 1;
  font-size: 14px;
`;

const Table = styled.table`
  width: 100%;
`;

const Row = styled.tr``;

const TableHeading = styled.th`
  border: 1px solid black;
  padding-left: 5px;
  font-size: 13px;
  height: 30px;
  text-align: center;
`;

const Column = styled.td`
  border: 1px solid black;
  padding: 0 8px;
  font-size: 13px;
  height: 30px;
`;

const CashCollection = () => {
  const wrapperRef = useRef(null);
  const [pharmacy] = usePharmacy();
  const [defaultDate, setDefaultDate] = useState(getTodayDate());
  const [startDate, setStartDate] = useState(getTodayDate());
  const [dispatchList, setDispatchList] = useState([]);
  const [returnList, setreturnList] = useState([]);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [returnTotal, setReturnTotal] = useState(0);
  const [returnDiscount, setReturnDiscount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  //-----------------------------------------------------------------------
  //----------------------getTodayDate ------------------------------
  //-----------------------------------------------------------------------
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  //-----------------------------------------------------------------------
  //-------------------- Get Dispatch List -----------------------
  //-----------------------------------------------------------------------
  const getDispatchList = async (req, res) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/dispatch/user/${pharmacy?.pharmacy?._id}/${startDate}/${defaultDate}`
      );
      if (data && data.success) {
        setIsLoading(false);
        setDispatchList(data?.dispatchList);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error("Somthing Went Wrong");
    }
  };
  //-----------------------------------------------------------------------
  //-------------------- Get Return List -----------------------
  //-----------------------------------------------------------------------
  const getreturnList = async (req, res) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/return/user/${pharmacy?.pharmacy?._id}/${startDate}/${defaultDate}`
      );
      if (data && data.success) {
        setIsLoading(false);
        setreturnList(data?.returnList);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error("Somthing Went Wrong");
    }
  };

  //-----------------------------------------------------------------------
  //----------------------numberToWords ------------------------------
  //-----------------------------------------------------------------------

  const generatePDF = useReactToPrint({
    content: () => wrapperRef.current,
    documentTitle: "CashCollections",
  });

  //-----------------------------------------------------------------------
  //----------------------USE EFFECTS ------------------------------
  //-----------------------------------------------------------------------
  useEffect(() => {
    getDispatchList();
    getreturnList();
  }, [defaultDate, startDate]);

  useEffect(() => {
    // Calculate total discount and total amount when dispatchList changes
    let discountTotal = 0;
    let amountTotal = 0;
    let returnDiscountTotal = 0;
    let returnAmountTotal = 0;
    dispatchList.forEach((dispatch) => {
      discountTotal += dispatch.disAmount || 0;
      amountTotal += dispatch.amount || 0;
    });
    returnList.forEach((r) => {
      returnDiscountTotal += r.disAmount || 0;
      returnAmountTotal += r.ReturnAmount || 0;
    });
    setReturnDiscount(returnDiscountTotal);
    setReturnTotal(returnAmountTotal);
    setDiscount(discountTotal);
    setTotal(amountTotal);
  }, [dispatchList, returnList]);

  return (
    <Container>
      <Left>
        <UserSidebar />
      </Left>
      <Right>
        <DashNav />
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Home
            </Link>
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Reports
            </Link>
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Home
            </Link>
          </Breadcrumbs>
        </BreadcrumbsContainer>
        <Title>PHAMACY CASH COLLECTION </Title>
        <AddNewButtonContainer>
          <div style={{ display: "flex", gap: "20px" }}>
            <InputField>
              <Lable>Start Date : MM-DD-YYYY</Lable>
              <Input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </InputField>
            <InputField>
              <Lable>End Date : MM-DD-YYYY</Lable>
              <Input
                type="date"
                value={defaultDate}
                onChange={(e) => setDefaultDate(e.target.value)}
              />
            </InputField>{" "}
          </div>
          <Button onClick={generatePDF}>Print</Button>
        </AddNewButtonContainer>
        <Wrapper>
          <div style={{ margin: "20px" }} ref={wrapperRef}>
            <Heading>
              <Logo>
                <Image src="/img/pkhealthtech.png" />
              </Logo>
              <HeaderDetails>
                <Name>{pharmacy?.pharmacy?.pharmacyName}</Name>
                <Disc>
                  Drug Lic No. : {pharmacy?.pharmacy?.druglience} | GST No. :{" "}
                  {pharmacy?.pharmacy?.gstin}
                </Disc>
                <Disc>{pharmacy?.pharmacy?.address}</Disc>
                <Disc>
                  Phone : {pharmacy?.pharmacy?.phone} | Email :{" "}
                  {pharmacy?.pharmacy?.email} | Name :{" "}
                  {pharmacy?.pharmacy?.name}
                </Disc>
              </HeaderDetails>
            </Heading>
            <Title style={{ fontSize: "16px" }}>DISPATCH INVOICES</Title>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Table>
                  <thead>
                    <Row>
                      <TableHeading>No.</TableHeading>
                      <TableHeading>Invoice No.</TableHeading>
                      <TableHeading>Patient Name</TableHeading>
                      <TableHeading>Patient Number</TableHeading>
                      <TableHeading>Dis %</TableHeading>
                      <TableHeading> Dis Amount</TableHeading>
                      <TableHeading>Amount</TableHeading>
                    </Row>
                  </thead>
                  <tbody>
                    {dispatchList &&
                      dispatchList.map((dispatch, i) => (
                        <>
                          <Row key={i}>
                            <Column>{i + 1}</Column>
                            <Column>{dispatch._id?.slice(-10)}</Column>
                            <Column>{dispatch.patientName}</Column>
                            <Column>{dispatch.patientNumber}</Column>
                            <Column style={{ textAlign: "center" }}>
                              {dispatch.dis}
                            </Column>
                            <Column style={{ textAlign: "right" }}>
                              {dispatch.disAmount?.toFixed(2)}
                            </Column>
                            <Column style={{ textAlign: "right" }}>
                              {dispatch.amount?.toFixed(2)}
                            </Column>
                          </Row>
                        </>
                      ))}
                    <Row>
                      <Column colSpan={5}></Column>
                      <Column colSpan={2} style={{ textAlign: "right" }}>
                        Total Dis Amount :
                        <b style={{ margin: "5px" }}>{discount?.toFixed(2)}</b>
                      </Column>
                    </Row>
                    <Row>
                      <Column colSpan={5} style={{ textAlign: "right" }}>
                        <CurrencyRupeeIcon sx={{ fontSize: "16px" }} />
                        {numberToWords(total)} Rupee Only
                      </Column>
                      <Column colSpan={2} style={{ textAlign: "right" }}>
                        Total Cash :
                        <span>
                          <b style={{ margin: "5px" }}>
                            <CurrencyRupeeIcon sx={{ fontSize: "16px" }} />
                            {total?.toFixed(2)}
                          </b>
                        </span>
                      </Column>
                    </Row>
                  </tbody>
                </Table>
                {returnList && returnList.length > 0 && (
                  <>
                    <Title style={{ fontSize: "16px" }}>RETURN INVOICES</Title>
                    <Table>
                      <thead>
                        <Row>
                          <TableHeading>No.</TableHeading>
                          <TableHeading>Invoice No.</TableHeading>
                          <TableHeading>Patient Name</TableHeading>
                          <TableHeading>Patient Number</TableHeading>
                          <TableHeading>Dis %</TableHeading>
                          <TableHeading> Dis Amount</TableHeading>
                          <TableHeading>Amount</TableHeading>
                        </Row>
                      </thead>
                      <tbody>
                        {returnList &&
                          returnList.map((r, i) => (
                            <>
                              <Row key={i}>
                                <Column>{i + 1}</Column>
                                <Column>{r._id?.slice(-10)}</Column>
                                <Column>{r.patientName}</Column>
                                <Column>{r.patientNumber}</Column>
                                <Column style={{ textAlign: "center" }}>
                                  {r.dis}
                                </Column>
                                <Column style={{ textAlign: "right" }}>
                                  {r.disAmount?.toFixed(2)}
                                </Column>
                                <Column style={{ textAlign: "right" }}>
                                  - {r.ReturnAmount?.toFixed(2)}
                                </Column>
                              </Row>
                            </>
                          ))}
                        <Row>
                          <Column colSpan={5}></Column>
                          <Column colSpan={2} style={{ textAlign: "right" }}>
                            Total Dis Amount :
                            <b style={{ margin: "5px" }}>
                              {returnDiscount?.toFixed(2)}
                            </b>
                          </Column>
                        </Row>
                        <Row>
                          <Column colSpan={5} style={{ textAlign: "right" }}>
                            <CurrencyRupeeIcon sx={{ fontSize: "16px" }} />
                            {numberToWords(returnTotal)} Rupee Only
                          </Column>
                          <Column colSpan={2} style={{ textAlign: "right" }}>
                            Total Return Cash :
                            <span>
                              <b style={{ margin: "5px" }}>
                                <CurrencyRupeeIcon sx={{ fontSize: "16px" }} />-{" "}
                                {returnTotal?.toFixed(2)}
                              </b>
                            </span>
                          </Column>
                        </Row>
                      </tbody>
                    </Table>
                  </>
                )}
              </>
            )}

            <Title style={{ fontSize: "16px" }}>PHARMACY CASH COLLECTION</Title>
            <Table>
              <thead>
                <Row>
                  <TableHeading>Dispatch Amount</TableHeading>
                  <TableHeading>Return Amount</TableHeading>
                  <TableHeading>CASH COLLECTION</TableHeading>
                </Row>
              </thead>
              <tbody>
                <Row>
                  <Column style={{ textAlign: "center" }}>
                    <CurrencyRupeeIcon sx={{ fontSize: "16px" }} />
                    {total?.toFixed(2)}
                  </Column>
                  <Column style={{ textAlign: "center" }}>
                    <CurrencyRupeeIcon sx={{ fontSize: "16px" }} />-{" "}
                    {returnTotal?.toFixed(2)}
                  </Column>
                  <Column style={{ textAlign: "center" }}>
                    <b>
                      <CurrencyRupeeIcon sx={{ fontSize: "16px" }} />
                      {(total - returnTotal).toFixed(2)}
                    </b>
                  </Column>
                </Row>
                <Row>
                  {total - returnTotal > 0 && (
                    <Column colSpan={3}>
                      <CurrencyRupeeIcon sx={{ fontSize: "16px" }} />
                      {numberToWords(total - returnTotal)} Rupee Only
                    </Column>
                  )}
                </Row>
              </tbody>
            </Table>
          </div>
        </Wrapper>
      </Right>
    </Container>
  );
};

export default CashCollection;
