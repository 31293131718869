import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Login from "./Login";
import { usePharmacy } from "../context/pharmacy";
import ProfileMenu from "./ProfileMenu";
import { FaBars } from "react-icons/fa";
import { useCollapse } from "react-collapsed";
import { ImCross } from "react-icons/im";
import NavLink from "./NavLink";

const Container = styled.div`
  height: 80px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #3c4b64;
  background-image: linear-gradient(
    45deg,
    var(--cui-dark-start, #212333) 0%,
    var(--cui-dark-stop, #3c4b64 100%)
  );
  color: white;

  @media screen and (min-width: 768px) {
    padding: 20px 100px;
  }
`;

const Left = styled.div``;

const Logo = styled.h1`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  margin-top: 10px;
  width: 100px;

  @media screen and (min-width: 768px) {
    width: 130px;
  }
`;

const Right = styled.div``;

const BarButton = styled.div`
  font-size: 25px;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const LinkBox = styled.div`
  width: 100%;
  text-align: right;
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
    text-align: center;
  }
`;
const LinkBox2 = styled.div`
  width: 100%;
  padding: 10px;
  text-align: right;
  background-color: #3c4b64;
  display: inline-block;
  background-image: linear-gradient(
    45deg,
    var(--cui-dark-start, #212333) 0%,
    var(--cui-dark-stop, #3c4b64 100%)
  );
  color: white;
`;

const Navbar = () => {
  const [pharmacy] = usePharmacy();
  const navigate = useNavigate();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  return (
    <>
      <Container>
        <Left>
          <Logo onClick={() => navigate("/")}>
            <Image src="/img/HEALTH_TECH__1_-removebg-preview.png" />
          </Logo>
        </Left>
        <Right>
          <BarButton {...getToggleProps()}>
            {isExpanded ? <ImCross /> : <FaBars />}
          </BarButton>

          <LinkBox>
            {pharmacy?.token ? (
              <ProfileMenu />
            ) : (
              <>
                <Login />
                <NavLink />
              </>
            )}
          </LinkBox>
        </Right>
      </Container>

      <LinkBox2 {...getCollapseProps()}>
        {pharmacy?.token ? (
          <ProfileMenu />
        ) : (
          <>
            <Login />
            <NavLink />
          </>
        )}
      </LinkBox2>
    </>
  );
};

export default Navbar;
