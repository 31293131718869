import styled from "styled-components";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { usePharmacy } from "../../context/pharmacy";

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Patient = styled.div`
  padding: 20px;
`;

const PatientDetails = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Disc = styled.div`
  flex: 1;
  font-size: 16px;
`;

const Table = styled.table`
  width: 100%;
  padding: 20px;
`;

const Row = styled.tr`
  min-height: 50px;
`;

const TableHeading = styled.th`
  border: 1px solid black;
  padding-left: 5px;
  font-size: 18px;
  height: 50px;
  text-align: center;
`;

const Column = styled.td`
  border: 1px solid black;
  padding: 0 8px;
  font-size: 16px;
  height: 50px;
`;

const Box1 = styled.div`
  margin: 20px 0;

  width: 90%;
  border: 0.5px solid lightgrey;
  border-radius: 10px;
`;

const BoxTitle = styled.h4`
  background-color: #3c4b64;
  color: white;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 10px 10px 0 0;
`;

const Input = styled.input`
  max-width: 100px;
  padding: 10px;
  border-radius: 10px;
  ::placeholder {
    color: gray !important;
  }
`;

const Button = styled.button`
  width: 15%;
  padding: 10px 14px;
  border-radius: 10px;
  border: none;
  background-color: #3c4b64;
  color: white;
  cursor: pointer;
`;

const DeleteButton = styled.button`
  padding: 7px;
  font-size: 10px;
  background-color: white;
  border: none;
  color: red;
  border-radius: 10px;
  cursor: pointer;

  &:focus {
    outline: 2px solid black;
  }
`;

const ReturnHelper = ({ item }) => {
  const [quantities, setQuantities] = useState([]);
  const [itemlist, setItemlist] = useState([]);
  const [totalReturnAmount, setTotalReturnAmount] = useState(0);
  const [defaultDate] = useState(getTodayDate());
  const [disAmount, setDisAmount] = useState("");
  const [pharmacy] = usePharmacy();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  //-----------------------------------------------------------------------
  //----------------------getTodayDate ------------------------------
  //-----------------------------------------------------------------------
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  //--------------------------------------------------------------
  //------------------ Handle Quantity Change Function---------------------------
  //--------------------------------------------------------------
  const handleQuantityChange = (index, value) => {
    const newQuantites = [...quantities];
    newQuantites[index] = value;
    setQuantities(newQuantites);
  };

  //--------------------------------------------------------------
  //-----------------------------Add Item Function----------------------------
  //--------------------------------------------------------------
  const handleItem = (index, item) => {
    if (item.quantity < quantities[index]) {
      return toast.error("Quantity should be not greater than Actual Quantity");
    }
    const isItemAlreadyAdded = itemlist.some(
      (existingItem) => existingItem.itemId === item.itemId
    );

    if (isItemAlreadyAdded) {
      return toast.error("Item already added");
    }

    const newItem = {
      ...item,
      returnQue: quantities[index],
      returnAmount: item.mrp * quantities[index],
    };

    const newItemlist = [...itemlist, newItem];

    setItemlist(newItemlist);
  };

  //-----------------------------------------------------------------------
  //-----------------------------Handle Return amount Total ----------------------------
  //-----------------------------------------------------------------------

  const handleReturnAmountChange = () => {
    const subTotal = itemlist.reduce(
      (total, item) => total + item.returnAmount,
      0
    );
    const discountAmount = subTotal * (item.dis / 100);
    setDisAmount(discountAmount);
    const totalReturnAmount = subTotal - discountAmount;

    setTotalReturnAmount(totalReturnAmount);
  };

  useEffect(() => {
    handleReturnAmountChange();
  }, [itemlist, quantities]);

  //-----------------------------------------------------------------------
  //-----------------------------Remove Item Function----------------------------
  //-----------------------------------------------------------------------
  const handleRemoveItem = (index) => {
    const updatedItemList = itemlist.filter((item, i) => i !== index);
    setItemlist(updatedItemList);
  };

  //-----------------------------------------------------------------------
  //-----------------------------Handle Return Function----------------------------
  //-----------------------------------------------------------------------

  const handleReturn = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}api/v1/return/add`,
        {
          pharmacy: pharmacy.pharmacy._id,
          invoiceDate: defaultDate,
          patientName: item.patientName,
          doctorName: item.doctorName,
          patientNumber: item.patientNumber,
          itemList: itemlist,
          dis: item.dis,
          disAmount,
          ReturnAmount: totalReturnAmount,
        }
      );
      if (data?.success) {
        setIsLoading(false);
        toast.success(data?.message);
        navigate(`/dashboard/pharmacy/return/${data?.returnItem._id}`);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Container>
      <Box1>
        <BoxTitle>Patient Details</BoxTitle>
        <Patient>
          <PatientDetails style={{ border: "none" }}>
            <Disc>Invoice Number : {item?._id?.slice(-10)}</Disc>
            <Disc style={{ textAlign: "right" }}>
              Invoice Date : {item?.invoiceDate}
            </Disc>
          </PatientDetails>
          <PatientDetails>
            <Disc>Patient Name : {item?.patientName}</Disc>
            <Disc>Phone Number : {item?.patientNumber}</Disc>
            <Disc>Doctor : {item?.doctorName}</Disc>
          </PatientDetails>
        </Patient>{" "}
      </Box1>

      <Box1>
        <BoxTitle>Item Details</BoxTitle>
        <div style={{ padding: "20px" }}>
          <Table>
            <thead>
              <Row>
                <TableHeading>No.</TableHeading>
                <TableHeading>Item Name</TableHeading>
                <TableHeading>Batch No.</TableHeading>
                <TableHeading>Exp. Date</TableHeading>
                <TableHeading>Quantiy</TableHeading>
                <TableHeading>Unit</TableHeading>
                <TableHeading>Mrp</TableHeading>
                <TableHeading>GST %</TableHeading>
                <TableHeading>Texable</TableHeading>
                <TableHeading>Amount</TableHeading>
                <TableHeading>Return Que.</TableHeading>
                <TableHeading>Action</TableHeading>
              </Row>
            </thead>
            {item?.itemList?.length > 0 && (
              <tbody>
                {item?.itemList.map((item, i) => (
                  <Row key={i}>
                    <Column style={{ textAlign: "center" }}>{i + 1}</Column>
                    <Column>{item.itemName}</Column>
                    <Column>{item.batchNo}</Column>
                    <Column>{item.expiryDate}</Column>
                    <Column style={{ textAlign: "center" }}>
                      {item.quantity}
                    </Column>
                    <Column style={{ textAlign: "center" }}>
                      {item?.unit}
                      {item?.unit === 1 ? " Number" : " X strip"}
                    </Column>

                    <Column style={{ textAlign: "right" }}>{item.mrp}</Column>
                    <Column style={{ textAlign: "right" }}>{item.gst} </Column>
                    <Column style={{ textAlign: "right" }}>
                      {item.texable.toFixed(2)}
                    </Column>
                    <Column style={{ textAlign: "right" }}>
                      {item.itemTotal}
                    </Column>
                    <Column>
                      <Input
                        value={quantities[i]}
                        onChange={(e) =>
                          handleQuantityChange(i, e.target.value)
                        }
                      />
                    </Column>
                    <Column>
                      <Button
                        style={{ width: "100%" }}
                        onClick={() => handleItem(i, item)}
                      >
                        Add
                      </Button>
                    </Column>
                  </Row>
                ))}
                <Row>
                  <Column colSpan={9}></Column>
                  <Column colSpan={3}>
                    Dis :{" "}
                    <b>
                      {item?.disAmount === 0 ? "" : "-"}
                      {item?.dis} %
                    </b>
                  </Column>
                </Row>
                <Row>
                  <Column colSpan={9}>
                    <CurrencyRupeeIcon sx={{ fontSize: "16px" }} />{" "}
                    {/* {numberToWords(item?.amount)} Rupee Only */}
                  </Column>
                  <Column colSpan={3}>
                    Bill Amount :
                    <b>
                      <CurrencyRupeeIcon sx={{ fontSize: "16px" }} />
                      {item?.amount}
                    </b>
                  </Column>
                </Row>
              </tbody>
            )}
          </Table>{" "}
        </div>
      </Box1>
      {itemlist && itemlist.length > 0 && (
        <>
          <Box1>
            <BoxTitle>Return Item List</BoxTitle>
            <div style={{ padding: "20px" }}>
              <Table>
                <thead>
                  <Row>
                    <TableHeading>No.</TableHeading>
                    <TableHeading>Item Name</TableHeading>
                    <TableHeading>Batch No.</TableHeading>
                    <TableHeading>Exp. Date</TableHeading>
                    <TableHeading>Quantiy</TableHeading>
                    <TableHeading>Unit</TableHeading>
                    <TableHeading>Mrp</TableHeading>
                    <TableHeading>GST %</TableHeading>
                    <TableHeading>Texable</TableHeading>
                    <TableHeading>Return Que.</TableHeading>
                    <TableHeading>Return Amount</TableHeading>
                    <TableHeading>Action</TableHeading>
                  </Row>
                </thead>
                {itemlist?.length > 0 && (
                  <tbody>
                    {itemlist?.map((item, i) => (
                      <Row key={i}>
                        <Column style={{ textAlign: "center" }}>{i + 1}</Column>
                        <Column>{item.itemName}</Column>
                        <Column>{item.batchNo}</Column>
                        <Column>{item.expiryDate}</Column>
                        <Column style={{ textAlign: "center" }}>
                          {item.quantity}
                        </Column>
                        <Column style={{ textAlign: "center" }}>
                          {item.unit}
                          {item.unit === 1 ? " Number" : " X strip"}
                        </Column>
                        <Column style={{ textAlign: "right" }}>
                          {item.mrp}
                        </Column>
                        <Column style={{ textAlign: "right" }}>
                          {item.gst}{" "}
                        </Column>
                        <Column style={{ textAlign: "right" }}>
                          {item.texable.toFixed(2)}
                        </Column>
                        <Column style={{ textAlign: "right" }}>
                          {item.returnQue}
                        </Column>
                        <Column style={{ textAlign: "right" }}>
                          {item.returnAmount}
                        </Column>
                        <Column>
                          <DeleteButton onClick={() => handleRemoveItem(i)}>
                            <DeleteIcon />
                          </DeleteButton>
                        </Column>
                      </Row>
                    ))}
                    <Row>
                      <Column colSpan={9}></Column>
                      <Column colSpan={3}>
                        Dis :
                        <span style={{ marginLeft: "10px" }}>
                          <b>
                            {item?.disAmount === 0 ? "" : "-"}
                            {item?.dis} %
                          </b>
                        </span>
                      </Column>
                    </Row>
                    <Row>
                      <Column colSpan={9}>
                        <CurrencyRupeeIcon sx={{ fontSize: "16px" }} />{" "}
                        {/* {numberToWords(item?.amount)} Rupee Only */}
                      </Column>
                      <Column colSpan={3}>
                        Bill Amount :
                        <b>
                          <CurrencyRupeeIcon sx={{ fontSize: "16px" }} />-{" "}
                          {totalReturnAmount}
                        </b>
                      </Column>
                    </Row>
                  </tbody>
                )}
              </Table>
            </div>
          </Box1>
          <Button onClick={handleReturn}>Return</Button>
        </>
      )}
    </Container>
  );
};

export default ReturnHelper;
