import { styled } from "styled-components";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { usePharmacy } from "../context/pharmacy.js";
import logo from "../Images/PKHEALTHTECH.png";
import { FaRegCheckSquare } from "react-icons/fa";
import LoadingButton from "@mui/lab/LoadingButton";
import { FaIndianRupeeSign } from "react-icons/fa6";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.h2``;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: rgba(0, 0, 0, 0.7);

  .button-loading {
    margin: 20px auto;
    display: block;
    text-align: center;
    text-decoration: none;
    border: 1px solid #212333;
    color: #212333;
    padding: 15px;
    border-radius: 5px;
    font-weight: bold;
    transition: 0.4s;
  }
  .active {
    background-color: #212333;
    transform: scale(1, 1.09);
    color: rgba(255, 255, 255, 1);
  }

  .active h1 {
    color: rgba(255, 255, 255, 1);
  }

  .active h1 span {
    color: rgba(255, 255, 255, 0.9);
  }

  .active p {
    color: rgba(255, 255, 255, 1);
  }

  .active ul li {
    color: rgba(255, 255, 255, 0.9);
  }

  .active ul li i {
    color: rgba(255, 255, 255, 0.4);
  }

  .active .button-loading {
    background-color: white;
    color: rgba(0, 0, 0, 0.8);
  }

  .active .button-loading:hover {
    border: 1px solid white;
  }
`;
const Card = styled.div`
  max-width: 350px;
  background-color: white;
  padding: 40px;
  margin: 30px 15px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

const PlanTitle = styled.h3`
  margin-bottom: 15px;
`;

const Price = styled.h1`
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 1);

  span {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
  }
`;

const Desc = styled.p`
  margin: 20px 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1.5;
`;

const Ul = styled.ul`
  list-style: none;
`;

const Li = styled.li`
  line-height: 3;
  color: rgba(0, 0, 0, 0.9);
`;

const SubscriptionPlan = () => {
  const [key, setKey] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [isLoading, setIsLoading] = useState([]);
  const [pharmacy] = usePharmacy();
  const [plan, setPlan] = useState("");
  const navigate = useNavigate();

  //---------------------Get Razor pay key Api Call By axios----------------------------
  const subscriptionHandler = async (planId, index) => {
    if (!pharmacy?.token) {
      return navigate("/register");
    }
    if (pharmacy?.pharmacy?.subscriptions?.status === "active") {
      return toast.error(
        "Alredy Have subscription or Frist cancle the Running subscription "
      );
    }

    try {
      setIsLoading({ ...isLoading, [index]: true });
      const {
        data: { key },
      } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/payment/razorpay-key`
      );
      setKey(key);
      buySubscription(planId, index);
    } catch (error) {
      setIsLoading({ ...isLoading, [index]: false });
      toast.error("something went wrong");
    }
  };

  //---------------------Buy Subscription Api Call By axios----------------------------
  const buySubscription = async (planId, index) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/payment/buy-subscription/${planId}`
      );
      setSubscriptionId(data?.subscriptionId);
      setIsLoading({ ...isLoading, [index]: false });
    } catch (error) {
      setIsLoading({ ...isLoading, [index]: false });
      console.log(error);
      toast.error("something went wrong");
    }
  };

  //-------------------- Use Effect----------------------------
  useEffect(() => {
    if (subscriptionId) {
      const openPopUp = () => {
        const options = {
          key,
          name: "pk HealthTech",
          description: "Access to pkHealthTech Pharmacy Billing Software",
          image: logo,
          subscription_id: subscriptionId,
          callback_url: `${process.env.REACT_APP_URL}api/v1/payment/payment-verification/${plan}/${pharmacy?.pharmacy?._id}`,
          prefill: {
            name: pharmacy?.pharmacy?.name,
            email: pharmacy?.pharmacy?.email,
            contact: pharmacy?.pharmacy?.phone,
          },
          notes: {
            address: "pk Health tech Pharmacy Billing Software",
          },
          theme: {
            color: "#212333",
          },
        };

        const razor = new window.Razorpay(options);
        razor.open();
      };
      openPopUp();
    }
  }, [
    subscriptionId,
    key,
    pharmacy?.pharmacy?.email,
    pharmacy?.pharmacy?.name,
    pharmacy?.pharmacy?.phone,
  ]);

  return (
    <Container>
      <Title>Subscription Plan</Title>
      <Wrapper>
        <Card>
          <PlanTitle>Monthly</PlanTitle>
          <Price>
            <FaIndianRupeeSign />
            999<span>/Month</span>
          </Price>
          <Desc>
            Your Pharmacy Billing with PK HealthTech: Choose Efficiency, Choose
            Excellence!
          </Desc>
          <Ul>
            <Li>
              <FaRegCheckSquare /> Streamlined Billing
            </Li>
            <Li>
              <FaRegCheckSquare /> User-Friendly Interface
            </Li>
            <Li>
              <FaRegCheckSquare /> Super Fast And Easier
            </Li>
            <Li>
              <FaRegCheckSquare /> Secure Data Handling
            </Li>
            <Li>
              <FaRegCheckSquare /> Inventory Management
            </Li>
            <Li>
              <FaRegCheckSquare /> Select Medicine In Storng Database
            </Li>
            <Li>
              <FaRegCheckSquare /> Support Multiple Store and Location
            </Li>
            <Li>
              <FaRegCheckSquare /> Safe and Secure Data Storage
            </Li>
            <Li>
              <FaRegCheckSquare /> Subsitute Avalability
            </Li>
            <Li>
              <FaRegCheckSquare /> GST Billing & Return Filling
            </Li>
            <Li>
              <FaRegCheckSquare /> Barcode Management
            </Li>
            <Li>
              <FaRegCheckSquare /> Purchase Management
            </Li>
            <Li>
              <FaRegCheckSquare /> Cash Collection Reports
            </Li>
            <Li>
              <FaRegCheckSquare /> Comprehensive Reporting
            </Li>
            <Li>
              <FaRegCheckSquare /> Expire Stock Management
            </Li>
            <Li>
              <FaRegCheckSquare /> 24/7 Support
            </Li>
          </Ul>
          <LoadingButton
            className="button-loading"
            loading={isLoading[0]}
            onClick={() => {
              setPlan("basic");
              subscriptionHandler("plan_O00JuKH0wFciMT", 0);
            }}
          >
            Subscribe Now
          </LoadingButton>
        </Card>
        <Card className="active">
          <PlanTitle style={{ color: "rgba(255, 255, 255, 1)" }}>
            Yearly
          </PlanTitle>
          <Price>
            <FaIndianRupeeSign />
            799<span>/Month</span>
          </Price>
          <Desc>
            Your Pharmacy Billing with PK HealthTech: Choose Efficiency, Choose
            Excellence!
          </Desc>
          <Ul>
            <Li>
              <FaRegCheckSquare /> Streamlined Billing
            </Li>
            <Li>
              <FaRegCheckSquare /> User-Friendly Interface
            </Li>
            <Li>
              <FaRegCheckSquare /> Super Fast And Easier
            </Li>
            <Li>
              <FaRegCheckSquare /> Secure Data Handling
            </Li>
            <Li>
              <FaRegCheckSquare /> Inventory Management
            </Li>
            <Li>
              <FaRegCheckSquare /> Select Medicine In Storng Database
            </Li>
            <Li>
              <FaRegCheckSquare /> Support Multiple Store and Location
            </Li>
            <Li>
              <FaRegCheckSquare /> Safe and Secure Data Storage
            </Li>
            <Li>
              <FaRegCheckSquare /> Subsitute Avalability
            </Li>
            <Li>
              <FaRegCheckSquare /> GST Billing & Return Filling
            </Li>
            <Li>
              <FaRegCheckSquare /> Barcode Management
            </Li>
            <Li>
              <FaRegCheckSquare /> Purchase Management
            </Li>
            <Li>
              <FaRegCheckSquare /> Cash Collection Reports
            </Li>
            <Li>
              <FaRegCheckSquare /> Comprehensive Reporting
            </Li>
            <Li>
              <FaRegCheckSquare /> Expire Stock Management
            </Li>
            <Li>
              <FaRegCheckSquare /> 24/7 Support
            </Li>
          </Ul>
          <LoadingButton
            className="button-loading"
            loading={isLoading[1]}
            onClick={() => {
              setPlan("basic");
              subscriptionHandler("plan_O00JuKH0wFciMT", 1);
            }}
          >
            Subscribe Now
          </LoadingButton>
        </Card>
        <Card>
          <PlanTitle>2 Yearly</PlanTitle>
          <Price>
            <FaIndianRupeeSign />
            599<span>/Month</span>
          </Price>
          <Desc>
            Your Pharmacy Billing with PK HealthTech: Choose Efficiency, Choose
            Excellence!
          </Desc>
          <Ul>
            <Li>
              <FaRegCheckSquare /> Streamlined Billing
            </Li>
            <Li>
              <FaRegCheckSquare /> User-Friendly Interface
            </Li>
            <Li>
              <FaRegCheckSquare /> Super Fast And Easier
            </Li>
            <Li>
              <FaRegCheckSquare /> Secure Data Handling
            </Li>
            <Li>
              <FaRegCheckSquare /> Inventory Management
            </Li>
            <Li>
              <FaRegCheckSquare /> Select Medicine In Storng Database
            </Li>
            <Li>
              <FaRegCheckSquare /> Support Multiple Store and Location
            </Li>
            <Li>
              <FaRegCheckSquare /> Safe and Secure Data Storage
            </Li>
            <Li>
              <FaRegCheckSquare /> Subsitute Avalability
            </Li>
            <Li>
              <FaRegCheckSquare /> GST Billing & Return Filling
            </Li>
            <Li>
              <FaRegCheckSquare /> Barcode Management
            </Li>
            <Li>
              <FaRegCheckSquare /> Purchase Management
            </Li>
            <Li>
              <FaRegCheckSquare /> Cash Collection Reports
            </Li>
            <Li>
              <FaRegCheckSquare /> Comprehensive Reporting
            </Li>
            <Li>
              <FaRegCheckSquare /> Expire Stock Management
            </Li>
            <Li>
              <FaRegCheckSquare /> 24/7 Support
            </Li>
          </Ul>
          <LoadingButton
            className="button-loading"
            loading={isLoading[2]}
            onClick={() => {
              setPlan("basic");
              subscriptionHandler("plan_O00JuKH0wFciMT", 2);
            }}
          >
            Subscribe Now
          </LoadingButton>
        </Card>
      </Wrapper>
    </Container>
  );
};

export default SubscriptionPlan;
