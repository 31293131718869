import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import axios from "axios";
import Sppiner from "../Sppiner";
import { usePharmacy } from "../../context/pharmacy";

export default function PrivateRoute() {
  const [ok, setOk] = useState(false);
  const [pharmacy] = usePharmacy();

  useEffect(() => {
    const authCheck = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/pharmacy/user`
      );
      if (res && res.data.ok) {
        setOk(true);
      } else {
        setOk(false);
      }
    };
    pharmacy?.token && authCheck();
  }, [pharmacy?.token]);

  return ok ? <Outlet /> : <Sppiner />;
}
