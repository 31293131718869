import React from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";

// ------------------- styled Components-------------------]

const Container = styled.div`
  width: 100%;
  height: 90vh;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Title = styled.h1``;

const Button = styled.button`
  padding: 10px;
  background-color: #3c4b64;
  color: white;
  border: none;
  outline: none;
  border-radius: 10px;
`;

const PageNotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Container>
      <Navbar />
      <Wrapper>
        <Title>PageNotFound</Title>
        <Button onClick={() => navigate("/", { state: location.pathname })}>
          Go Back
        </Button>
      </Wrapper>
      <Footer />
    </Container>
  );
};

export default PageNotFound;
