import React from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Heading = styled.h1`
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

const SubHeading = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
`;

const List = styled.ul`
  list-style-type: disc;
  margin-left: 20px;
`;

const ListItem = styled.li`
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;

  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const AboutUsPage = () => {
  return (
    <>
      <Navbar />
      <BreadcrumbsContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link className="link" style={{ color: "inherit" }} to="/">
            Home
          </Link>
          <Link className="link" style={{ color: "#212333" }} to="/about-us">
            ABOUT US
          </Link>
        </Breadcrumbs>
      </BreadcrumbsContainer>
      <Container>
        <Heading>About PK HealthTech Pharmacy Billing Software</Heading>
        <Image src="/img/about.jpg" />
        <Paragraph>
          Welcome to PK HealthTech, where innovation meets efficiency in
          pharmacy management. Our pharmacy billing software is designed to
          streamline and optimize the operations of your pharmacy, empowering
          you to deliver exceptional care to your patients while enhancing your
          bottom line.
        </Paragraph>

        <SubHeading>Our Mission</SubHeading>
        <Paragraph>
          Our mission at PK HealthTech is to revolutionize pharmacy management
          by providing cutting-edge software solutions that simplify workflows,
          enhance accuracy, and improve profitability for our clients. We strive
          to empower pharmacies of all sizes to thrive in an ever-changing
          industry, enabling them to focus on what matters most: delivering
          quality care to their patients.
        </Paragraph>

        <SubHeading>Why Choose PK HealthTech?</SubHeading>
        <List>
          <ListItem>
            Comprehensive Features: Our pharmacy billing software offers a
            comprehensive suite of features tailored to meet the unique needs of
            pharmacies.
          </ListItem>
          <ListItem>
            User-Friendly Interface: Our software is designed with a
            user-friendly interface that is intuitive and easy to navigate.
          </ListItem>
          <ListItem>
            Customizable Solutions: We offer customizable solutions that can be
            tailored to fit your specific requirements.
          </ListItem>
          <ListItem>
            Exceptional Support: Our dedicated team of experts is available to
            assist you every step of the way.
          </ListItem>
          <ListItem>
            Compliance and Security: Our software adheres to industry
            regulations and standards, ensuring that your data is always safe
            and secure.
          </ListItem>
        </List>

        <SubHeading>Get Started Today</SubHeading>
        <Paragraph>
          Ready to take your pharmacy to the next level? Contact us today to
          learn more about how PK HealthTech pharmacy billing software can
          transform your operations and drive success. Join the countless
          pharmacies that have already experienced the benefits of partnering
          with PK HealthTech. Let us help you unlock the full potential of your
          pharmacy.
        </Paragraph>

        <Image />
      </Container>
      <Footer />
    </>
  );
};

export default AboutUsPage;
