import React from "react";
import { styled } from "styled-components";
import Trail from "../components/Trail";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Container = styled.div``;

const BreadcrumbsContainer = styled.div`
  padding: 20px;

  @media screen and (min-width: 768px) {
    margin-bottom: 100px;
  }
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Demo = () => {
  return (
    <Container>
      <Navbar />
      <BreadcrumbsContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link className="link" style={{ color: "inherit" }} to="/">
            Home
          </Link>

          <Link className="link" style={{ color: "#3c4b64" }} to="/get-demo">
            GET DEMO
          </Link>
        </Breadcrumbs>
      </BreadcrumbsContainer>
      <Trail />
      <Footer />
    </Container>
  );
};

export default Demo;
