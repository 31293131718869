import styled from "styled-components";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { FcExpired } from "react-icons/fc";
import { GiSpeedometer, GiMedicines, GiCash } from "react-icons/gi";
import { MdOutlineInventory } from "react-icons/md";
import { BsDatabaseFillLock } from "react-icons/bs";
import { FaStore } from "react-icons/fa";
import { GrSecure } from "react-icons/gr";
import { RiBillFill } from "react-icons/ri";
import { CiBarcode } from "react-icons/ci";
import { BiPurchaseTag } from "react-icons/bi";

const Container = styled.div`
  width: 100%;
  padding: 50px;
`;

const Title = styled.h2`
  text-align: center;
  color: #3c4b64;
  font-weight: 600;
`;

const CardContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  @media screen and (min-width: 768px) {
    padding: 50px 100px;
  }
`;

const Card = styled.div`
  width: 250px;
  height: 320px;
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: 0.5s all ease;
  @media screen and (min-width: 768px) {
    &:hover {
      scale: 1.1;
    }
  }
`;

const Top = styled.div`
  flex: 1;
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 100px;
`;

const Bottom = styled.div`
  flex: 2;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CardTitle = styled.h4`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #25293a;
`;

const CardDesc = styled.p`
  text-align: center;
`;

const Features = () => {
  return (
    <Container>
      <Title>Features Of pk Helath Tech Web Software</Title>
      <CardContainer>
        <Card>
          <Top>
            <GiSpeedometer />
          </Top>
          <Bottom>
            <CardTitle>Super Fast Create Bill in 20 secound</CardTitle>
            <CardDesc>
              Revolutionize Billing: Effortlessly Generate Detailed Invoices in
              Only 20 Seconds!
            </CardDesc>
          </Bottom>
        </Card>
        <Card>
          <Top>
            <EmojiEmotionsIcon style={{ fontSize: "100px" }} />
          </Top>
          <Bottom>
            <CardTitle>Simplicity of use and Faster</CardTitle>
            <CardDesc>
              Effortless Efficiency: Simplified and Lightning-Fast Billing
            </CardDesc>
          </Bottom>
        </Card>
        <Card>
          <Top>
            <FcExpired />
          </Top>
          <Bottom>
            <CardTitle>Expire Stock Management</CardTitle>
            <CardDesc>
              Simplified Stock Expiry Management: Making Inventory Control
              Easier and Faster
            </CardDesc>
          </Bottom>
        </Card>
        <Card>
          <Top>
            <MdOutlineInventory />
          </Top>
          <Bottom>
            <CardTitle>Inventory Management</CardTitle>
            <CardDesc>
              Effortless Inventory Control: Simplified Management for Success
            </CardDesc>
          </Bottom>
        </Card>
        <Card>
          <Top>
            <BsDatabaseFillLock />
          </Top>
          <Bottom>
            <CardTitle>Select Medicine In Storng Database</CardTitle>
            <CardDesc>
              Effortless Medicine Selection: Reliable Database for Quick Access
            </CardDesc>
          </Bottom>
        </Card>
        <Card>
          <Top>
            <FaStore />
          </Top>
          <Bottom>
            <CardTitle>Support Multiple Store and Location</CardTitle>
            <CardDesc>
              Support Multiple Stores and Locations: Simplify Management
              Effortlessly
            </CardDesc>
          </Bottom>
        </Card>
        <Card>
          <Top>
            <GrSecure />
          </Top>
          <Bottom>
            <CardTitle>Safe and Secure Data Storage</CardTitle>
            <CardDesc>
              Secure Data Storage: Ensuring Safety and Privacy
            </CardDesc>
          </Bottom>
        </Card>
        <Card>
          <Top>
            <GiMedicines />
          </Top>
          <Bottom>
            <CardTitle>Subsitute Avalability</CardTitle>
            <CardDesc>
              Generic Names Availability: Ensuring Substitution Options
            </CardDesc>
          </Bottom>
        </Card>
        <Card>
          <Top>
            <RiBillFill />
          </Top>
          <Bottom>
            <CardTitle>GST Billing & Return Filling</CardTitle>
            <CardDesc>
              Streamlined GST Billing and Return Filing: Simplify Tax Compliance
            </CardDesc>
          </Bottom>
        </Card>
        <Card>
          <Top>
            <CiBarcode />
          </Top>
          <Bottom>
            <CardTitle>Barcode Management</CardTitle>
            <CardDesc>
              Efficient Barcode Management: Simplify Inventory Tracking
            </CardDesc>
          </Bottom>
        </Card>
        <Card>
          <Top>
            <BiPurchaseTag />
          </Top>
          <Bottom>
            <CardTitle>Purchase Management</CardTitle>
            <CardDesc>
              Efficient Purchase Management: Streamlining Procurement Processes
            </CardDesc>
          </Bottom>
        </Card>
        <Card>
          <Top>
            <GiCash />
          </Top>
          <Bottom>
            <CardTitle>Cash Collection Reports</CardTitle>
            <CardDesc>
              Insightful Daily Cash Collection Reports: Tracking Financial
              Transactions
            </CardDesc>
          </Bottom>
        </Card>
      </CardContainer>
    </Container>
  );
};

export default Features;
