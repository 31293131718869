import styled from "styled-components";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #3c4b64;
  background-image: linear-gradient(
    45deg,
    var(--cui-dark-start, #212333) 0%,
    var(--cui-dark-stop, #3c4b64 100%)
  );
  color: white;
  gap: 10px;
  @media screen and (min-width: 768px) {
    height: 80px;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 100px;
  }
`;

const Left = styled.div`
  flex: 1;
  text-align: center;

  @media screen and (min-width: 768px) {
    text-align: left;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    gap: 20px;
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
  }
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Footer = () => {
  return (
    <Container>
      <Left>
        copyright &copy; 2024{" "}
        <span style={{ color: "red" }}>pk HealthTech.</span> powered by{" "}
        <a
          href="https://pkwebcreation.site/"
          target="_blank"
          rel="noreferrer"
          style={{ color: "red" }}
        >
          pk Web Creation
        </a>
      </Left>

      <Right>
        <Details>
          <PhoneIcon /> +91 76 210 141 82
        </Details>
        <Details>
          <EmailIcon /> paresh@pkhealthtech.com
        </Details>
      </Right>
    </Container>
  );
};

export default Footer;
