import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//--------------------------------- Styled Components -------------------------------

const Container = styled.div`
  background-color: #3c4b64;
  background-image: linear-gradient(
    45deg,
    var(--cui-dark-start, #3c4b64) 0%,
    var(--cui-dark-stop, #212333 100%)
  );
  min-height: 100vh;
  height: 100%;
  color: white;
`;

const Logo = styled.h1`
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 25px;
  text-align: center;
  padding: 10px;
  color: white;
  background-color: #212333;
`;

const List = styled.div`
  padding: 10px;
`;

const ListBox = styled.div``;

const ListTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
`;

const ListItem = styled.li`
  color: #f2f2f2;
  padding-left: 30px;
  cursor: pointer;
`;

const Sidebar = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Container>
      <Logo>pk's technology</Logo>
      <List>
        <ListBox>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            sx={{
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                margin: "0",
                color: "white",
              }}
            >
              <ListTitle>
                <LocalHospitalIcon style={{ marginRight: "10px" }} />
                Pharmacy
              </ListTitle>{" "}
            </AccordionSummary>
            <AccordionDetails>
              <ListItem
                onClick={() => navigate("/dashboard/admin/add-pharmacy")}
              >
                Add New Pharmacy
              </ListItem>
              <ListItem onClick={() => navigate("/dashboard/admin/pharmacy")}>
                All Pharmacy
              </ListItem>{" "}
            </AccordionDetails>
          </Accordion>
          {/* //-------------------------------------------------------------------- */}
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            sx={{
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{
                margin: "0",
                color: "white",
              }}
            >
              <ListTitle>
                <VaccinesIcon style={{ marginRight: "10px" }} />
                Drug Master
              </ListTitle>
            </AccordionSummary>
            <AccordionDetails>
              <ListItem
                onClick={() => navigate("/dashboard/admin/add-new-drug")}
              >
                Add New Drug
              </ListItem>
              <ListItem onClick={() => navigate("/dashboard/admin/all-drugs")}>
                All Drugs
              </ListItem>
              <ListItem
                onClick={() => navigate("/dashboard/admin/add-generic-name")}
              >
                Add Generic Name
              </ListItem>
              <ListItem
                onClick={() => navigate("/dashboard/admin/generic-name")}
              >
                All Generic Name
              </ListItem>
            </AccordionDetails>
          </Accordion>
        </ListBox>
      </List>
    </Container>
  );
};

export default Sidebar;
