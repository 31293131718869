import { styled } from "styled-components";
import PulseLoader from "react-spinners/PulseLoader";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Sppiner = () => {
  const [count, setCount] = useState(2);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => prev - 1);
      count === 0 && navigate(`/`, { state: location.pathname });
    }, 1000);
    return () => clearInterval(interval);
  }, [count, location.pathname, navigate]);

  return (
    <Container>
      <Title>Redirecting in {count} secound</Title>
      <PulseLoader color="#212333" loading size={21} />
    </Container>
  );
};

export default Sppiner;
