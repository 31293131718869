import { styled } from "styled-components";
import Navbar from "../components/Navbar";
import { useEffect, useState } from "react";
import { usePharmacy } from "../context/pharmacy";
import toast, { LoaderIcon } from "react-hot-toast";
import axios from "axios";
import UnixTimestampConverter from "../components/UnixTimestampConverter ";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  min-height: 100vh;
`;

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1``;

const Filed = styled.p``;

const Button = styled.button`
  background: transparent;
  border: none;
  outline: none;
  color: #212333;
  font-weight: 900;
  text-decoration: underline;
`;

const Btn = styled.button``;

const MyProfile = () => {
  const [pharmacy, setPharmacy] = usePharmacy();
  const [subscription, setSubscription] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const upadatePharmacy = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/pharmacy/single-pharmacy/${pharmacy?.pharmacy?._id}`
      );
      if (data?.success) {
        setPharmacy({ ...pharmacy, pharmacy: data?.pharmacy });
      }
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
    }
  };

  const fatchSubscription = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/payment/fatch-subscription/${pharmacy?.pharmacy?.subscriptions?.id}`
      );
      setIsLoading(false);
      setSubscription(data?.subscription);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error("something went wrong");
    }
  };

  const cancelSubscriptionHandler = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(
        `${process.env.REACT_APP_URL}api/v1/payment/cancle-subscription`
      );

      if (data?.success) {
        toast.success(data?.message);
        setLoading(false);
        upadatePharmacy();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("something went wrong");
    }
  };

  useEffect(() => {
    pharmacy?.pharmacy?.subscriptions?.status === "active" &&
      fatchSubscription();
  }, [pharmacy, pharmacy?.pharmacy?.subscriptions?.status]);

  return (
    <Container>
      <Navbar />
      <Wrapper>
        <Title>My Profile</Title>
        <Filed>
          <b>Name : </b> {pharmacy?.pharmacy?.name}
        </Filed>
        <Filed>
          <b>Pharmacy Name : </b> {pharmacy?.pharmacy?.pharmacyName}
        </Filed>
        <Filed>
          <b>Email : </b>
          {pharmacy?.pharmacy?.email}
        </Filed>
        <Filed>
          <b>Username : </b>
          {pharmacy?.pharmacy?.username}
        </Filed>
        <Filed>
          <b>Address : </b>
          {pharmacy?.pharmacy?.address}
        </Filed>
        <Filed>
          <b>Phone : </b>
          {pharmacy?.pharmacy?.phone}
        </Filed>
        <Filed>
          <b>Drug Lic No. : </b>
          {pharmacy?.pharmacy?.druglience}
        </Filed>
        <Filed>
          <b>GST No. : </b>
          {pharmacy?.pharmacy?.gstin}
        </Filed>
        <Filed>
          <b>Subscriptions Status : </b>
          {pharmacy?.pharmacy?.subscriptions?.status
            ? pharmacy?.pharmacy?.subscriptions?.status
            : "None"}
        </Filed>
        {isloading ? (
          <div style={{ margin: "10px" }}>
            <LoaderIcon />
          </div>
        ) : (
          pharmacy?.pharmacy?.subscriptions?.id &&
          subscription && (
            <>
              <Filed>
                <b>Subscriptions Plan Id : </b>
                {subscription?.plan_id}
              </Filed>
              <Filed>
                <b>Start Date : </b>
                <UnixTimestampConverter
                  timestamp={subscription?.current_start}
                />
              </Filed>
              <Filed>
                <b>End Date : </b>
                <UnixTimestampConverter timestamp={subscription?.current_end} />
              </Filed>
              <Filed>
                <b>Next Payment : </b>
                <UnixTimestampConverter timestamp={subscription?.charge_at} />
              </Filed>
              <Filed>
                <b>Payment Method : </b>
                {subscription?.payment_method}
              </Filed>
            </>
          )
        )}
        <Filed>
          <b>Subscriptions : </b>
          {pharmacy?.pharmacy?.subscriptions?.status === "active" ? (
            <Button
              style={{ color: "red" }}
              onClick={cancelSubscriptionHandler}
            >
              {loading ? <Loader /> : "Cancle subscriptions"}
            </Button>
          ) : (
            <Button
              style={{ color: "green" }}
              onClick={() => navigate("/subscription-plan")}
            >
              Buy Subscription
            </Button>
          )}
        </Filed>
        {pharmacy?.pharmacy?.subscriptions?.status === "active" && (
          <Btn id="contBtn" onClick={() => navigate("/dashboard/pharmacy")}>
            Go To Dashboard
          </Btn>
        )}
      </Wrapper>
    </Container>
  );
};

export default MyProfile;
