import { Toaster } from "react-hot-toast";
import "./App.css";
import Home from "./pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/routes/private";
import AdminRoute from "./components/routes/Admin";
import Dashborad from "./pages/admin/Dashborad";
import Register from "./pages/Register";
import AddPharmacy from "./pages/admin/AddPharmacy";
import Pharmacies from "./pages/admin/Pharmacies";
import PharmacyProfile from "./pages/admin/PharmacyProfile";
import ChangePassword from "./pages/ChangePassword";
import UserDashborad from "./pages/user/UserDashboard";
import PageNotFound from "./pages/PageNotFound";
import AddNewDrug from "./pages/user/AddNewDrug";
import AddGenericName from "./pages/admin/AddGenericName";
import GenericName from "./pages/admin/GenericName";
import AllDurgs from "./pages/user/AllDurgs";
import PurchaseOrder from "./pages/user/PurchaseOrder";
import PurchaseList from "./pages/user/PuchaseList";
import Dispatch from "./pages/user/Dispatch";
import Invocie from "./pages/user/Invoice";
import ReturnInvoice from "./pages/user/ReturnInvoice";
import AllDispatch from "./pages/user/AllDispatch";
import ScrollToTop from "./components/ScrollToTop";
import StockSummary from "./pages/user/StockSummary";
import CashCollection from "./pages/user/CashCollection";
import Return from "./pages/user/Return";
import AllReturn from "./pages/user/AllReturn";
import AdminAddnewDrug from "./pages/admin/AdminAddnewDrug";
import AdminAllDrugs from "./pages/admin/AdminAllDrugs";
import Demo from "./pages/Demo";
import AboutUsPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";
import Loader from "./components/Loader";
import LoadingBar from "react-top-loading-bar";
import { useState } from "react";
import MyProfile from "./pages/MyProfile";
import PaymentSuccess from "./pages/PaymentSuccess";
import Subscription from "./pages/Subscription";
import DisptachList from "./pages/user/DisptachList";
import DispatchMedicineList from "./pages/user/DispatchMedicineList";

function App() {
  const [progress, setProgress] = useState(100);
  return (
    <Router>
      <ScrollToTop />
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/loader" element={<Loader />} />
        <Route exact path="/my-profile" element={<MyProfile />} />
        <Route exact path="/paymentSuccess" element={<PaymentSuccess />} />
        <Route exact path="/about-us" element={<AboutUsPage />} />
        <Route exact path="/contact-us" element={<ContactUsPage />} />
        <Route exact path="/get-demo" element={<Demo />} />
        <Route exact path="/subscription-plan" element={<Subscription />} />
        <Route path="*" element={<PageNotFound />} />

        {/* ---------------------- User Route ------------------------- */}
        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route path="pharmacy" element={<UserDashborad />} />
          <Route path="pharmacy/add-new-drug" element={<AddNewDrug />} />
          <Route path="pharmacy/all-drugs" element={<AllDurgs />} />
          <Route path="pharmacy/purchase-order" element={<PurchaseOrder />} />
          <Route path="pharmacy/dispatch" element={<Dispatch />} />
          <Route path="pharmacy/return" element={<Return />} />
          <Route
            path="pharmacy/reports/stock-summary"
            element={<StockSummary />}
          />
          <Route
            path="pharmacy/reports/dispatch-list"
            element={<DisptachList />}
          />
          <Route
            path="pharmacy/reports/dispatch-medicine-list"
            element={<DispatchMedicineList />}
          />
          <Route path="pharmacy/dispatch-list" element={<AllDispatch />} />
          <Route path="pharmacy/return-list" element={<AllReturn />} />
          <Route path="pharmacy/dispatch/:id" element={<Invocie />} />
          <Route path="pharmacy/return/:id" element={<ReturnInvoice />} />
          <Route
            path="pharmacy/reports/cash-collection"
            element={<CashCollection />}
          />
          <Route
            path="user/change-password/:pid"
            element={<ChangePassword />}
          />
          <Route
            path="pharmacy/purchase-order-list"
            element={<PurchaseList />}
          />
        </Route>

        {/* ---------------------- start Admin Route ------------------------- */}
        <Route path="/dashboard" element={<AdminRoute />}>
          <Route path="admin" element={<Dashborad />} />
          <Route
            path="admin/change-password/:pid"
            element={<ChangePassword />}
          />
          <Route path="admin/all-drugs" element={<AdminAllDrugs />} />
          <Route path="admin/add-new-drug" element={<AdminAddnewDrug />} />
          <Route path="admin/add-pharmacy" element={<AddPharmacy />} />
          <Route path="admin/add-generic-name" element={<AddGenericName />} />
          <Route path="admin/generic-name" element={<GenericName />} />
          <Route path="admin/pharmacy" element={<Pharmacies />} />
          <Route
            path="admin/pharmacy/edit-profile/:pid"
            element={<PharmacyProfile />}
          />
        </Route>
        {/* ---------------------- End Admin Route ------------------------- */}
      </Routes>
      <Toaster />
    </Router>
  );
}

export default App;
