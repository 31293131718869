import { Link, useNavigate } from "react-router-dom";
import * as React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import toast from "react-hot-toast";
import { usePharmacy } from "../context/pharmacy";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HomeIcon from "@mui/icons-material/Home";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const Container = styled.div`
  height: 50px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;
const Left = styled.div``;
const Right = styled.div``;

const DashNav = () => {
  const [pharmacy, setPharmacy] = usePharmacy();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("pharmacy");
    setPharmacy({ pharmacy: [], token: null });
    toast.success("logged out successfully");
  };

  return (
    <Container>
      <Left>
        <HomeIcon
          sx={{ cursor: "pointer" }}
          onClick={() =>
            navigate(
              pharmacy?.pharmacy?.role === 1
                ? "/dashboard/admin"
                : "/dashboard/pharmacy"
            )
          }
        />
      </Left>
      <Right>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <span
            style={{ color: "#212333", fontWeight: "800", marginRight: "5px" }}
          >
            Welcome, {pharmacy?.pharmacy?.name}
          </span>{" "}
          <ArrowDropDownIcon style={{ color: "#212333" }} />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Link
            style={{ color: "black", textDecoration: "none" }}
            to="/my-profile"
          >
            <MenuItem onClick={handleClose}>
              <AccountCircleIcon sx={{ marginRight: "5px" }} />
              My Profile
            </MenuItem>
          </Link>
          <Link
            style={{ color: "#212333", textDecoration: "none" }}
            to={
              pharmacy?.pharmacy?.role === 1
                ? `/dashboard/admin/change-password/${pharmacy?.pharmacy?._id}`
                : `/dashboard/user/change-password/${pharmacy?.pharmacy?._id}`
            }
          >
            <MenuItem onClick={handleClose}>
              <LockOpenIcon sx={{ marginRight: "5px" }} /> Change Password
            </MenuItem>
          </Link>
          <Link
            style={{ color: "#212333", textDecoration: "none" }}
            onClick={handleLogout}
            to="/"
          >
            <MenuItem onClick={handleClose}>
              <LogoutIcon sx={{ marginRight: "5px" }} />
              Logout
            </MenuItem>
          </Link>
        </Menu>
      </Right>
    </Container>
  );
};

export default DashNav;
