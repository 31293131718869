import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding: 50px;
`;

const Title = styled.h2`
  text-align: center;
  color: #3c4b64;
  font-weight: 600;
`;

const CardContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  @media screen and (min-width: 768px) {
    padding: 50px 100px;
  }
`;

const Card = styled.div`
  width: 250px;
  height: 320px;
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: 0.5s all ease;
  &:hover {
    scale: 1.1;
  }
`;

const Top = styled.div`
  flex: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bottom = styled.div`
  flex: 0.5;
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Image = styled.img`
  width: 100%;
`;

const CardTitle = styled.h4`
  margin: 0;
  padding: 0;
`;

const CardDesc = styled.p`
  font-size: 13px;
`;

const Team = () => {
  return (
    <Container>
      <Title>Team Of pk HealthTech</Title>
      <CardContainer>
        <Card>
          <Top>
            <Image src="/img/pareshKanzariya.jpg" />
          </Top>
          <Bottom>
            <span style={{ fontSize: "10px" }}>Founder And CEO</span>
            <CardTitle>Paresh Kanzariya</CardTitle>
            <CardDesc>MERN stack web developer</CardDesc>
          </Bottom>
        </Card>
      </CardContainer>
    </Container>
  );
};

export default Team;
