import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { usePharmacy } from "../context/pharmacy";
import Loader from "./Loader";

const Form = styled.form`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  background-color: #3c4b64;
  border: 2px solid white;
  border-radius: 10px;
  outline: none;
  color: white;
  margin: 5px 0;
  background: rgba(0, 0, 21, 0.15);

  &::placeholder {
    color: #d6d6d6;
  }
  @media screen and (min-width: 768px) {
    min-width: 200px;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  border: none;
  outline: none;
  color: #212333;
  border-radius: 10px;
  font-weight: 900;
`;

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [pharmacy, setPharmacy] = usePharmacy();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}api/v1/pharmacy/login`,
        {
          username: username,
          password: password,
        }
      );

      if (data?.success) {
        setIsLoading(false);
        setPharmacy({
          ...pharmacy,
          pharmacy: data?.pharmacy,
          token: data?.token,
        });
        localStorage.setItem("pharmacy", JSON.stringify(data));
        toast.success(data?.message);
        navigate(
          data?.pharmacy?.subscriptions?.status === "active"
            ? data?.pharmacy.role === 1
              ? "/dashboard/admin"
              : "/dashboard/pharmacy"
            : "/my-profile"
        );
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Form onSubmit={handleLogin}>
      <Input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <Input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button type="submit">{isLoading ? <Loader /> : "Login"}</Button>
    </Form>
  );
};

export default Login;
