import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    scroll.scrollToTop({
      duration: 100, // Adjust the duration as needed
      smooth: "easeInOutQuart", // Choose a timing function for the animation
    });
  }, [pathname]);

  return null;
}
