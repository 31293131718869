import styled from "styled-components";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import UserSidebar from "../../components/user/UserSidebar";
import DashNav from "../../components/DashNAv";
import { useEffect, useState } from "react";
import axios from "axios";
import toast, { LoaderIcon } from "react-hot-toast";
import Select from "react-select";
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import AddGeneric from "../../components/user/AddGeneric";

//-----------------------------------------------------------------------
//---------------------- Styled Components-----------------------
//-----------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Left = styled.div`
  flex: 2;
`;

const Right = styled.div`
  flex: 11;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Title = styled.h1`
  margin: 20px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #3c4b64;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  color: #3c4b64;
  width: 100%;
  margin: 0 auto;
`;

const Box1 = styled.div`
  margin: 20px 0;
  width: 47%;
  border: 0.5px solid lightgrey;
  border-radius: 10px;
`;

const BoxTitle = styled.h4`
  background-color: #3c4b64;
  color: white;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 10px 10px 0 0;
`;

const InputField = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  position: relative;
`;

const Lable = styled.label`
  width: 100%;
  font-size: 18px;
`;

const Input = styled.input`
  flex: 1;
  min-width: 60%;
  padding: 10px;
  border-radius: 10px;
  ::placeholder {
    color: gray !important;
  }
`;

const SelectHTML = styled.select`
  flex: 1;
  min-width: 60%;
  padding: 10px;
  border-radius: 10px;
`;
const OptionHTML = styled.option`
  flex: 1;
  min-width: 60%;
  padding: 10px;
  border-radius: 10px;
  :hover {
    background-color: #3c4b64;
    color: white;
  }
`;

const Span = styled.span`
  position: absolute;
  font-size: 14px;
  bottom: -25px;
  right: 10px;
  cursor: pointer;
  text-decoration: underline;
`;

const Button = styled.button`
  width: 30%;
  padding: 15px 30px;
  border: none;
  background-color: #3c4b64;
  color: white;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 100px;
`;

//-----------------------------------------------------------------------
//---------------------- Main Functioin Starts -----------------------
//-----------------------------------------------------------------------

const AddNewDrug = () => {
  //-----------------------------------------------------------------------
  //---------------------- Usestate And Useble Dependency-----------------------
  //-----------------------------------------------------------------------
  const [itemCode, setItemCode] = useState("");
  const [formulation, setFormulation] = useState("");
  const [brandName, setBrandName] = useState("");
  const [genericName, setGenericName] = useState(null);
  const [batchNo, setBatchNo] = useState("");
  const [stripSize, setStripSize] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [mfgDate, setMfgDate] = useState("");
  const [mrp, setMrp] = useState("");
  const [ptr, setPtr] = useState("");
  const [gst, setGst] = useState("");
  const [manufacture, setManufacture] = useState("");
  const [marketed, setMarketed] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [barcode, setBarcode] = useState("");
  const [genericNames, setGenericNames] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [search, setSearch] = useState("");
  const [drugList, setDrugList] = useState([]);
  const [selectedDrug, setSelectedDrug] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //-----------------------------------------------------------------------
  //---------------------- Model Open Close Fuction-----------------------
  //-----------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);
  const [openSearch, setOpenSearch] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpenSearch = () => {
    setOpenSearch(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseSearch = () => {
    setOpenSearch(false);
  };

  //-----------------------------------------------------------------------
  //---------------------- ExpiryDate Validation-----------------------
  //-----------------------------------------------------------------------

  const isValidExpiryDate = (expiryDate) => {
    const regex = /^\d{4}-(0[1-9]|1[0-2])$/;
    if (!regex.test(expiryDate)) {
      return false;
    }
    // Get current year and month
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 since getMonth returns zero-based index

    // Extract year and month from the expiry date
    const [year, month] = expiryDate.split("-").map(Number);

    // Verify if expiry year and month are greater than or equal to current year and month
    if (year < currentYear || (year === currentYear && month <= currentMonth)) {
      return false;
    }
    return true;
  };

  //-----------------------------------------------------------------------
  //---------------------- Get Generic Names -----------------------
  //-----------------------------------------------------------------------
  const getGenericNames = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/medicines/generic-name/${keyword}`
      );
      if (data && data.success) {
        setGenericNames(data?.GenericNames);
      }
    } catch (error) {
      console.log(error);
      toast.error("Somthing Went Wrong");
    }
  };

  useEffect(() => {
    keyword.length >= 3 && getGenericNames();
  }, [keyword]);

  //-----------------------------------------------------------------------
  //---------------------- get Drugs Form Data  -----------------------
  //-----------------------------------------------------------------------
  const getDrugsFormData = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/data/drugs/${search}`
      );
      if (data && data.success) {
        setDrugList(data?.drugs);
      }
    } catch (error) {
      console.log(error);
      toast.error("Somthing Went Wrong");
    }
  };

  useEffect(() => {
    search.length >= 3 && getDrugsFormData();
  }, [search]);

  useEffect(() => {
    handleCloseSearch();
    setFormulation(selectedDrug?.formulation);
    setBrandName(selectedDrug?.brandName);
    setGst(selectedDrug?.gst);
    setGenericName({
      name: selectedDrug?.genericName,
      label: selectedDrug?.genericName,
      value: selectedDrug?.genericName,
    });
    setManufacture(selectedDrug?.manufacture);
    setMarketed(selectedDrug?.marketed);
  }, [selectedDrug]);

  //-----------------------------------------------------------------------
  //------------- Final Api Call by axios - Drug Name save -----------------
  //-----------------------------------------------------------------------

  const handleAddDurgName = async (e) => {
    e.preventDefault();
    try {
      if (expiryDate)
        if (!isValidExpiryDate(expiryDate)) {
          toast.error(
            "Expiry date should be greater than the current year and month"
          );
          return;
        }
      setIsLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}api/v1/drug/add-new-drug`,
        {
          formulation,
          brandName,
          genericName: genericName.name,
          batch: [
            {
              batchNo: batchNo,
              stripSize: stripSize,
              expiryDate: expiryDate,
              mfgDate: mfgDate,
              mrp: mrp,
              ptr: ptr,
            },
          ],
          gst,
          manufacture,
          marketed,
          hsnCode,
        }
      );
      if (data?.success) {
        setIsLoading(false);
        toast.success(data?.message);
        window.location.reload();
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  //-----------------------------------------------------------------------
  //---------------------- Main Functioin Return Value-----------------------
  //-----------------------------------------------------------------------

  return (
    <Container>
      <Left>
        <UserSidebar />
      </Left>
      <Right>
        <DashNav />
        {/* ---------------------- Breadcrumbs Container----------------------- */}

        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Home
            </Link>
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Drug Master
            </Link>
            <Link
              className="link"
              style={{ color: "#3c4b64" }}
              to="/dashboard/pharmacy/add-new-drug"
            >
              ADD NEW DRUG
            </Link>
          </Breadcrumbs>
        </BreadcrumbsContainer>
        {/* ---------------------- Main Container----------------------- */}

        <Title>ADD NEW DRUG</Title>
        <Form onSubmit={handleAddDurgName}>
          <Box1>
            {/* ----------- Essential Details----------- */}
            <BoxTitle> Essential Details (Mandatory Fields)</BoxTitle>
            <div style={{ padding: "10px", paddingBottom: "none" }}>
              <InputField>
                <Lable>Item Code :</Lable>
                <Input
                  disabled
                  placeholder="Item Code"
                  value={itemCode}
                  onChange={(e) => setItemCode(e.target.value)}
                />
              </InputField>
              <InputField>
                <Lable> Drug Formulation :</Lable>
                <SelectHTML
                  value={formulation}
                  onChange={(e) => setFormulation(e.target.value)}
                  required
                >
                  <OptionHTML defaultChecked>Select A furmulation</OptionHTML>
                  <OptionHTML>Tablet</OptionHTML>
                  <OptionHTML>Capsules</OptionHTML>
                  <OptionHTML>Injection</OptionHTML>
                  <OptionHTML>Syrup</OptionHTML>
                  <OptionHTML>Sergicals</OptionHTML>
                  <OptionHTML>FMCG</OptionHTML>
                  <OptionHTML>Suppositories</OptionHTML>
                  <OptionHTML>Drops</OptionHTML>
                  <OptionHTML>Inhalers</OptionHTML>
                  <OptionHTML>Implants or patches</OptionHTML>
                  <OptionHTML>Other</OptionHTML>
                </SelectHTML>
              </InputField>
              <InputField>
                <Lable> Brand Name :</Lable>
                <Input
                  required
                  placeholder="Brand Name"
                  value={brandName}
                  onChange={(e) => setBrandName(e.target.value.toUpperCase())}
                />
              </InputField>
              <InputField>
                <Lable> Genaric Name :</Lable>
                {genericNames && (
                  <Select
                    required
                    styles={{
                      container: (baseStyles, state) => ({
                        ...baseStyles,
                        minWidth: "60%",
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: "7px",
                        borderRadius: "10px",
                        border: "2px solid black",
                        outline: "none",
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "black",
                        backgroundColor: state.isFocused ? "#3C4B64" : "white",
                        color: state.isFocused ? "white" : "#3C4B64",
                      }),
                    }}
                    placeholder="Search Generic Name"
                    value={genericName}
                    noOptionsMessage={() => "Not Found, Please Add New"}
                    onChange={setGenericName}
                    options={genericNames}
                    onInputChange={(e) => setKeyword(e)}
                  />
                )}

                <Span onClick={handleOpen}>Add New Generic Name ?</Span>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <AddGeneric
                      handleClose={handleClose}
                      getGenericNames={getGenericNames}
                    />
                  </Box>
                </Modal>
                <Modal
                  open={openSearch}
                  onClose={handleCloseSearch}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Select
                      required
                      styles={{
                        container: (baseStyles, state) => ({
                          ...baseStyles,
                          minWidth: "60%",
                        }),
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "7px",
                          borderRadius: "10px",
                          border: "2px solid black",
                          outline: "none",
                        }),
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          color: "black",
                          backgroundColor: state.isFocused
                            ? "#3C4B64"
                            : "white",
                          color: state.isFocused ? "white" : "#3C4B64",
                        }),
                      }}
                      placeholder="Search Drug Name"
                      noOptionsMessage={() => "Please Enter 3 Latter"}
                      defaultValue={selectedDrug}
                      onChange={setSelectedDrug}
                      options={drugList}
                      onInputChange={(e) => setSearch(e)}
                    />
                  </Box>
                </Modal>
              </InputField>
              <Button
                type="button"
                style={{
                  width: "30%",
                  marginTop: "0",
                  marginBottom: "10px",
                  marginLeft: "auto",
                  padding: "5px",
                  backgroundColor: "transparent",
                  color: "#3c4b64",
                  border: "3px solid #3c4b64",
                  borderRadius: "20px",
                  fontWeight: "600",
                }}
                onClick={handleOpenSearch}
              >
                Select
              </Button>
            </div>
          </Box1>
          <Box1>
            {/* ----------- Batch Details----------- */}
            <BoxTitle> Batch Details</BoxTitle>
            <div style={{ padding: "10px", paddingBottom: "none" }}>
              <InputField>
                <Lable>Batch Number :</Lable>
                <Input
                  placeholder="Batch Number"
                  value={batchNo}
                  onChange={(e) => setBatchNo(e.target.value.toUpperCase())}
                />
              </InputField>
              <InputField>
                <Lable> Strip Size :</Lable>
                <SelectHTML onChange={(e) => setStripSize(e.target.value)}>
                  <OptionHTML defaultChecked>Select A Strip Size</OptionHTML>
                  <OptionHTML value={1}>1 Number</OptionHTML>
                  <OptionHTML value={2}>2 Strip</OptionHTML>
                  <OptionHTML value={3}>3 Strip</OptionHTML>
                  <OptionHTML value={4}>4 Strip</OptionHTML>
                  <OptionHTML value={5}>5 Strip</OptionHTML>
                  <OptionHTML value={6}>6 Strip</OptionHTML>
                  <OptionHTML value={9}>9 Strip</OptionHTML>
                  <OptionHTML value={10}>10 Strip</OptionHTML>
                  <OptionHTML value={15}>15 Strip</OptionHTML>
                  <OptionHTML value={20}>20 Strip</OptionHTML>
                  <OptionHTML value={30}>30 Strip</OptionHTML>
                </SelectHTML>
              </InputField>
              <InputField>
                <Lable> Expiry Date :</Lable>
                <Input
                  type="month"
                  onChange={(e) => setExpiryDate(e.target.value)}
                />
              </InputField>
              <InputField>
                <Lable> Mfg. Date :</Lable>
                <Input
                  type="month"
                  onChange={(e) => setMfgDate(e.target.value)}
                />
              </InputField>
              <InputField>
                <Lable> MRP :</Lable>
                <Input
                  placeholder="MRP"
                  value={mrp}
                  onChange={(e) => setMrp(e.target.value)}
                />
              </InputField>
              <InputField>
                <Lable> PTR :</Lable>
                <Input
                  placeholder="PTR"
                  value={ptr}
                  onChange={(e) => setPtr(e.target.value)}
                />
              </InputField>
            </div>
          </Box1>
          <Box1>
            {/* ----------- Price Details----------- */}
            <BoxTitle>Price Details</BoxTitle>
            <div style={{ padding: "10px", paddingBottom: "none" }}>
              <InputField>
                <Lable> GST % :</Lable>
                <Input
                  required
                  placeholder=" GST % "
                  value={gst}
                  onChange={(e) => setGst(e.target.value)}
                />
              </InputField>
            </div>
          </Box1>
          <Box1>
            {/* ----------- General Details----------- */}
            <BoxTitle>General Details</BoxTitle>
            <div style={{ padding: "10px", paddingBottom: "none" }}>
              <InputField>
                <Lable> Manufacture :</Lable>
                <Input
                  placeholder="Manufacture"
                  value={manufacture}
                  onChange={(e) => setManufacture(e.target.value)}
                />
              </InputField>
              <InputField>
                <Lable> Marketed By :</Lable>
                <Input
                  placeholder="Marketed By"
                  value={marketed}
                  onChange={(e) => setMarketed(e.target.value)}
                />
              </InputField>
              <InputField>
                <Lable> Item HSN Code :</Lable>
                <Input
                  placeholder="HSN Code"
                  value={hsnCode}
                  onChange={(e) => setHsnCode(e.target.value)}
                />
              </InputField>
              <InputField>
                <Lable>Barcode :</Lable>
                <Input
                  placeholder="Barcode"
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                />
              </InputField>
            </div>
          </Box1>
          <Button type="submit" className="center">
            {isLoading ? <LoaderIcon /> : "SAVE"}
          </Button>
        </Form>
      </Right>
    </Container>
  );
};

export default AddNewDrug;
