import styled from "styled-components";
import { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import { Breadcrumbs } from "@mui/material";
import { usePharmacy } from "../context/pharmacy";
import Loader from "../components/Loader";

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  background: linear-gradient(#3c4b6452, #3c4b6452),
    url("/img/regester.jpg") center;
`;

const Wrapper = styled.div`
  width: 90%;
  padding: 10px;
  background-color: white;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    width: 40%;
    padding: 20px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #3c4b64;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  color: #3c4b64;
  @media screen and (min-width: 768px) {
    flex-wrap: wrap;
    flex-direction: row;
  }
  margin-bottom: 20px;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  width: 100%;
  padding: 10px;
  margin: 20px 10px 0 0;
  @media screen and (min-width: 768px) {
    width: 49%;
  }
`;

const Textarea = styled.textarea`
  padding: 10px;
  margin: 20px 10px 20px 0;
  width: 100%;
  &::placeholder {
    font-family: "Poppins", sans-serif;
  }
`;

const Button = styled.button`
  width: 99%;
  padding: 15px 30px;
  border: none;
  background-color: #3c4b64;
  color: white;
  cursor: pointer;
`;
const BreadcrumbsContainer = styled.div`
  padding: 20px;
  color: white;
  @media screen and (min-width: 768px) {
    margin-bottom: 100px;
  }
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Register = () => {
  const [name, setName] = useState("");
  const [pharmacyName, setPharmacyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [druglience, setDruglience] = useState("");
  const [gstin, setGstin] = useState("");
  const [address, setAddress] = useState("");
  const navigate = useNavigate();
  const [pharmacy, setPharmacy] = usePharmacy();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}api/v1/pharmacy/register`,
        {
          name: name,
          pharmacyName: pharmacyName,
          email: email,
          phone: phone,
          address: address,
          username: username,
          password: password,
          druglience: druglience,
          gstin: gstin,
        }
      );
      if (data?.success) {
        toast.success(data?.message);
        handleLogin();
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}api/v1/pharmacy/login`,
        {
          username: username,
          password: password,
        }
      );

      if (data?.success) {
        setIsLoading(false);
        setPharmacy({
          ...pharmacy,
          pharmacy: data?.pharmacy,
          token: data?.token,
        });
        localStorage.setItem("pharmacy", JSON.stringify(data));
        toast.success(data?.message);
        navigate(
          data?.pharmacy?.subscriptions?.status === "active"
            ? data?.pharmacy.role === 1
              ? "/dashboard/admin"
              : "/dashboard/pharmacy"
            : "/my-profile"
        );
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Container>
      <Navbar />
      <BreadcrumbsContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <Link className="link" style={{ color: "white" }} to="/">
            Home
          </Link>
          <Link className="link" style={{ color: "white" }} to="/register">
            REGISTER
          </Link>
        </Breadcrumbs>
      </BreadcrumbsContainer>
      <Wrapper>
        <Title>REGISTER YOU PHARMACY</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            required
            placeholder="Enter Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            required
            placeholder="Enter Your Pharmacy Name"
            value={pharmacyName}
            onChange={(e) => setPharmacyName(e.target.value)}
          />
          <Input
            required
            type="email"
            placeholder="Enter Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            required
            placeholder="Enter Your Phone Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Input
            required
            placeholder="Enter Your Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            required
            type="password"
            placeholder="Enter Your Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Input
            required
            placeholder="Enter Your Drug License Number"
            value={druglience}
            onChange={(e) => setDruglience(e.target.value.toUpperCase())}
          />
          <Input
            required
            placeholder="Enter Your GST License Number"
            value={gstin}
            onChange={(e) => setGstin(e.target.value.toUpperCase())}
          />
          <Textarea
            name=""
            id=""
            cols="95"
            rows="5"
            placeholder="Enter Your Pharmacy Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          ></Textarea>
          <Button type="submit">{isLoading ? <Loader /> : "REGISTER"}</Button>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default Register;
