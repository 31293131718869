import styled from "styled-components";
import Sidebar from "../../components/admin/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import DashNav from "../../components/DashNAv";
import axios from "axios";
import toast from "react-hot-toast";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";

const Container = styled.div`
  display: flex;
`;

const Left = styled.div`
  flex: 2;
`;

const Right = styled.div`
  flex: 11;
`;
const Title = styled.h1`
  margin: 20px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #3c4b64;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const AddNewButtonContainer = styled.div`
  width: 90%;
  display: flex;
  margin: 0 auto;
  .button {
    background-color: transparent;
    color: #212333;
    font-weight: 600;
    border: 2px solid #212333;
    &:hover {
      color: white;
      background-color: var(--secondary-color);
      border: 2px solid teal;
    }
  }
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: #3c4b64;
  width: 80%;
  margin: 0 auto;
  gap: 20px;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  padding: 10px;
`;

const AddButton = styled.button`
  width: 20%;
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: #3c4b64;
  color: white;
  cursor: pointer;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-radius: 10px;
  width: 100%;
  margin: 20px 0;
`;

const Row = styled.tr`
  width: 100%;
`;
const Heading = styled.th`
  height: 50px;
  text-align: center;
  color: var(--primary-color);
  border-bottom: 0.5px solid lightgrey;
`;

const Column = styled.td`
  border-bottom: 0.5px solid lightgrey;
  border-collapse: collapse;
  height: 50px;
  text-align: center;
  color: var(--secondary-color);
`;

const EditButton = styled.button`
  padding: 7px;
  font-size: 10px;
  background-color: #00aeff;
  border: none;
  outline: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 5px;
`;

const DeleteButton = styled.button`
  padding: 7px;
  font-size: 10px;
  background-color: red;
  border: none;
  outline: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
`;

const GenericName = () => {
  const navigate = useNavigate();
  const [genericNames, setGenericNames] = useState([]);
  const [name, setName] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}api/v1/medicines/add-generic-name`,
        {
          name: name,
        }
      );
      if (data?.success) {
        toast.success(data?.message);
        getGenericNames();
        setName("");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  //-------------------- Get Generic Name -----------------------

  const getGenericNames = async (req, res) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/medicines/generic-name`
      );
      if (data && data.success) {
        setGenericNames(data?.GenericNames);
      }
    } catch (error) {
      console.log(error);
      toast.error("Somthing Went Wrong");
    }
  };

  useEffect(() => {
    getGenericNames();
  }, []);

  const handleDelete = async (id) => {
    try {
      const answer = window.confirm("Are you sure?");

      if (!answer) return;

      const res = await axios.delete(
        `${process.env.REACT_APP_URL}api/v1/medicines/delete-generic-name/${id}`
      );
      if (res?.data.success) {
        toast.success("Deleted successfully");
        getGenericNames();
      } else {
        toast.error(res.data.massage);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Container>
      <Left>
        <Sidebar />
      </Left>
      <Right>
        <DashNav />
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/admin"
            >
              Home
            </Link>
            <Link
              className="link"
              style={{ color: "#212333", textTransform: "uppercase" }}
              to="/dashboard/admin/generic-name"
            >
              GENERIC NAMES
            </Link>
          </Breadcrumbs>
        </BreadcrumbsContainer>
        <Title>ALL GENERIC NAME</Title>
        <AddNewButtonContainer>
          <Form onSubmit={handleSubmit}>
            <Input
              required
              placeholder="Enter Generic Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <AddButton type="submit">Save</AddButton>
          </Form>
        </AddNewButtonContainer>
        <Table>
          <thead>
            <Row>
              <Heading>No.</Heading>
              <Heading> Name</Heading>
              <Heading> Action</Heading>
            </Row>
          </thead>
          <tbody>
            {genericNames &&
              genericNames.map((g, i) => (
                <Row key={i}>
                  <Column>{i + 1}</Column>
                  <Column>{g.name}</Column>

                  <Column>
                    <EditButton
                      onClick={() =>
                        navigate(
                          `/dashboard/admin/pharmacy/edit-profile/${g._id}`
                        )
                      }
                    >
                      <ModeEditIcon />
                    </EditButton>
                    <DeleteButton onClick={() => handleDelete(g._id)}>
                      <DeleteIcon />
                    </DeleteButton>
                  </Column>
                </Row>
              ))}
          </tbody>
        </Table>
      </Right>
    </Container>
  );
};

export default GenericName;
