import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 30px 0;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Box1 = styled.div`
  margin: 20px 0;
  width: 90%;
  border: 0.5px solid lightgrey;
  border-radius: 10px;
`;

const BoxTitle = styled.h4`
  background-color: #3c4b64;
  color: white;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 10px 10px 0 0;
`;

const InputField = styled.div`
  width: 49%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  position: relative;
`;

const Lable = styled.label`
  width: 100%;
  font-size: 18px;
`;

const Input = styled.input`
  flex: 1;
  min-width: 60%;
  padding: 10px;
  border-radius: 10px;
  ::placeholder {
    color: gray !important;
  }
`;
const Table = styled.table`
  border-collapse: collapse;
  border-radius: 10px;
  width: 100%;
`;

const Row = styled.tr`
  width: 100%;
  cursor: pointer;
`;
const Heading = styled.th`
  height: 50px;
  text-align: center;
  color: var(--primary-color);
  border-bottom: 0.5px solid lightgrey;
`;

const Column = styled.td`
  border-bottom: 0.5px solid lightgrey;
  border-collapse: collapse;
  height: 50px;
  text-align: center;
  color: var(--secondary-color);
`;

const TotalContainer = styled.div`
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const GrnDetails = ({ item }) => {
  return (
    <Container>
      <Box1>
        <BoxTitle> Essential Details (Mandatory Fields)</BoxTitle>
        <div
          style={{
            padding: "10px",
            paddingBottom: "none",
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <InputField>
            <Lable>Invoice Date : </Lable>
            <Input required type="date" value={item.invoiceDate} disabled />
          </InputField>
          <InputField>
            <Lable>Invoice Number : </Lable>
            <Input
              placeholder="Invoice Number"
              value={item.invoiceNumber}
              disabled
            />
          </InputField>
          <InputField style={{ width: "99%" }}>
            <Lable>Suppiler Name : </Lable>
            <Input
              placeholder="Suppiler Name"
              value={item.suppilerName}
              disabled
            />
          </InputField>
        </div>
      </Box1>
      <Box1>
        <BoxTitle>Item List</BoxTitle>
        <Table>
          <thead>
            <Row>
              <Heading>No.</Heading>
              <Heading>Item Name</Heading>
              <Heading>Batch No.</Heading>
              <Heading>Strip Size</Heading>
              <Heading>Quantiy</Heading>
              <Heading>Free Qty</Heading>
              <Heading>Accepted Qty</Heading>
              <Heading>Payable Qty</Heading>
              <Heading>PTR</Heading>
              <Heading>Dis %</Heading>
              <Heading>GST Amount</Heading>
              <Heading>Amount</Heading>
            </Row>
          </thead>
          <tbody>
            {item.itemList &&
              item.itemList.map((i, index) => (
                <Row key={index}>
                  <Column>{index + 1}</Column>
                  <Column>{i.itemName}</Column>
                  <Column>{i.batchNo}</Column>
                  <Column>{i.stripSize} X Strip</Column>
                  <Column>{i.quantity}</Column>
                  <Column>{i.freeQuantity}</Column>
                  <Column>{i.acceptableQuantity}</Column>
                  <Column>{i.quantity}</Column>
                  <Column>{i.ptr}</Column>
                  <Column>{i.itemDis}</Column>
                  <Column>{i.gstAmount}</Column>
                  <Column>{i.itemTotal}</Column>
                </Row>
              ))}
          </tbody>
        </Table>
        <TotalContainer>
          <InputField style={{ width: "20%" }}>
            <Lable>DIS % : </Lable>
            <Input value={item.dis} disabled />
          </InputField>
          <InputField style={{ width: "20%" }}>
            <Lable>Total : </Lable>
            <Input disabled value={item.amount} />
          </InputField>
        </TotalContainer>
      </Box1>
    </Container>
  );
};

export default GrnDetails;
