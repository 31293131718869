import styled from "styled-components";
import Sidebar from "../../components/admin/Sidebar";
import { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router";
import DashNav from "../../components/DashNAv";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
`;

const Left = styled.div`
  flex: 2;
`;

const Right = styled.div`
  flex: 11;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Title = styled.h1`
  margin: 20px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #3c4b64;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  color: #3c4b64;
  width: 60%;
  margin: 0 auto;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  padding: 10px;
  margin: 20px 10px 0 0;
`;

const Textarea = styled.textarea`
  padding: 10px;
  margin: 20px 10px 20px 0;

  &::placeholder {
    font-family: "Poppins", sans-serif;
  }
`;

const Button = styled.button`
  width: 99%;
  padding: 15px 30px;
  border: none;
  background-color: #3c4b64;
  color: white;
  cursor: pointer;
`;

const AddPhamrmacy = () => {
  const [name, setName] = useState("");
  const [pharmacyName, setPharmacyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [druglience, setDruglience] = useState("");
  const [gstin, setGstin] = useState("");
  const [address, setAddress] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}api/v1/pharmacy/register`,
        {
          name: name,
          pharmacyName: pharmacyName,
          email: email,
          phone: phone,
          address: address,
          username: username,
          password: password,
          druglience: druglience,
          gstin: gstin,
        }
      );
      if (data?.success) {
        toast.success(data?.message);
        navigate("/dashboard/admin/pharmacy");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something Want Wrong");
    }
  };

  return (
    <Container>
      <Left>
        <Sidebar />
      </Left>
      <Right>
        <DashNav />
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/admin"
            >
              Home
            </Link>
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/admin/pharmacy"
            >
              Pharmacies
            </Link>
            <Link
              className="link"
              style={{ color: "#212333" }}
              to="/dashboard/admin/add-pharmacy"
            >
              ADD PHARMACY
            </Link>
          </Breadcrumbs>
        </BreadcrumbsContainer>
        <Title>ADD NEW PHARMACY</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            required
            placeholder="Enter Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            required
            placeholder="Enter Your Pharmacy Name"
            value={pharmacyName}
            onChange={(e) => setPharmacyName(e.target.value)}
          />
          <Input
            required
            type="email"
            placeholder="Enter Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            required
            placeholder="Enter Your Phone Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Input
            required
            placeholder="Enter Your Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            required
            type="password"
            placeholder="Enter Your Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Input
            required
            placeholder="Enter Your Drug License Number"
            value={druglience}
            onChange={(e) => setDruglience(e.target.value.toUpperCase())}
          />
          <Input
            required
            placeholder="Enter Your GST License Number"
            value={gstin}
            onChange={(e) => setGstin(e.target.value.toUpperCase())}
          />
          <Textarea
            name=""
            id=""
            cols="95"
            rows="5"
            placeholder="Enter Your Pharmacy Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          ></Textarea>
          <Button type="submit">REGISTER</Button>
        </Form>
      </Right>
    </Container>
  );
};

export default AddPhamrmacy;
