export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear();

  // Add leading zeros if necessary
  const formattedDay = day < 10 ? "0" + day : day;
  const formattedMonth = month < 10 ? "0" + month : month;

  return `${formattedDay}-${formattedMonth}-${year}`;
};

export const numberToWords = (number) => {
  const units = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];
  const teens = [
    "",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tens = [
    "",
    "ten",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  const scales = ["", "thousand", "million", "billion", "trillion"];

  const numToWords = (num) => {
    if (num === 0) return "zero";

    let words = "";

    for (let i = 0; i < scales.length; i++) {
      const divisor = Math.pow(1000, i);
      const chunk = Math.floor(num / divisor) % 1000;

      if (chunk !== 0) {
        let chunkWords = "";

        const hundreds = Math.floor(chunk / 100);
        const tensAndUnits = chunk % 100;

        if (hundreds !== 0) {
          chunkWords += units[hundreds] + " hundred";
          if (tensAndUnits !== 0) chunkWords += " ";
        }

        if (tensAndUnits !== 0) {
          if (tensAndUnits < 10) {
            chunkWords += units[tensAndUnits];
          } else if (tensAndUnits < 20) {
            chunkWords += teens[tensAndUnits - 10];
          } else {
            const tensDigit = Math.floor(tensAndUnits / 10);
            const unitsDigit = tensAndUnits % 10;
            chunkWords += tens[tensDigit];
            if (unitsDigit !== 0) chunkWords += "-" + units[unitsDigit];
          }
        }

        chunkWords += " " + scales[i];

        if (words !== "") words = chunkWords + ", " + words;
        else words = chunkWords;
      }
    }

    return words.trim();
  };

  return numToWords(number);
};
