import styled from "styled-components";
import DashNav from "../../components/DashNAv";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import UserSidebar from "../../components/user/UserSidebar";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import toast, { LoaderIcon } from "react-hot-toast";
import Select from "react-select";
import { usePharmacy } from "../../context/pharmacy";
import { useRef } from "react";

//-----------------------------------------------------------------------
//---------------------- Styled Components-----------------------
//-----------------------------------------------------------------------

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Left = styled.div`
  flex: 2;
`;

const Right = styled.div`
  flex: 11;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Title = styled.h1`
  margin: 20px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #3c4b64;
`;

const GrnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  color: #3c4b64;
  width: 100%;
  margin: 0 auto;
`;

const Box1 = styled.div`
  margin: 20px 0;
  width: 90%;
  border: 0.5px solid lightgrey;
  border-radius: 10px;
`;

const BoxTitle = styled.h4`
  background-color: #3c4b64;
  color: white;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 10px 10px 0 0;
`;

const InputField = styled.div`
  width: 49%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  position: relative;
`;

const Lable = styled.label`
  width: 100%;
  font-size: 18px;
`;

const Input = styled.input`
  flex: 1;
  min-width: 60%;
  padding: 10px;
  border-radius: 10px;
  ::placeholder {
    color: gray !important;
  }
`;

const BoxV2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  gap: 20px;
  width: 100%;
`;

const InputFieldV2 = styled.div`
  flex: 1;
  width: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const LableV2 = styled.label`
  margin-left: 5px;
  font-size: 14px;
  letter-spacing: 2px;
  color: black;
`;

const InputV2 = styled.input`
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
`;

const SelectHTMLV2 = styled.select`
  padding: 10px;
  border-radius: 10px;
`;

const OptionHTMLV2 = styled.option``;

const SuplierNameList = styled.div`
  border: 1px solid black;
  background-color: white;
  width: auto;
  min-width: 60%;
  height: auto;
  border-radius: 10px;
  position: absolute;
  top: 47px;
  z-index: 999;
  right: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;
`;

const SuplierNameItem = styled.option`
  padding: 0 10px;
  width: 100%;
  z-index: 9999;
  list-style: none;
  height: 30px;
  cursor: pointer;

  &:hover {
    background-color: #3c4b64;
    color: white;
  }
`;

const BatchContainer = styled.div`
  margin: 0 auto;
  width: 90%;
  border: 0.5px solid #3c4b64;
  border-radius: 10px;
  padding: 20px;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-radius: 10px;
  width: 100%;
  margin: 20px 0;
  padding: 20px;
`;

const Row = styled.tr`
  width: 100%;
  cursor: pointer;
`;
const Heading = styled.th`
  height: 50px;
  text-align: center;
  color: var(--primary-color);
  border-bottom: 0.5px solid lightgrey;
`;

const Column = styled.td`
  border-bottom: 0.5px solid lightgrey;
  border-collapse: collapse;
  height: 50px;
  text-align: center;
  color: var(--secondary-color);
`;

const Controller = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Button = styled.button`
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 7px 10px;
  border: none;
  background-color: #3c4b64;
  color: white;
  cursor: pointer;
  margin-top: 30px;
  border: 2px solid #3c4b64;
`;

const DeleteButton = styled.button`
  padding: 7px;
  font-size: 10px;
  background-color: white;
  border: none;
  outline: none;
  color: red;
  border-radius: 10px;
  cursor: pointer;
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const PurchaseOrder = () => {
  //-----------------------------------------------------------------------
  //-----------------------------------------------------------------------
  //-----------------------------------------------------------------------
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [suppilerName, setSuppilerName] = useState("");
  const [suppilerNames, setSuppilerNames] = useState([]);
  const [filterSuppilerNames, setFilterSuppilerNames] = useState([]);
  const [batchNo, setbatchNo] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [mfgDate, setMfgDate] = useState("");
  const [mrp, setMrp] = useState("");
  const [ptr, setPtr] = useState("");
  const [stripSize, setStripSize] = useState("");
  const [quantity, setQuantity] = useState("");
  const [freeQuantity, setFreeQuantity] = useState(0);
  const [acceptableQuantity, setAcceptableQuantity] = useState(0);
  const [gst, setGst] = useState("");
  const [gstAmount, setGstAmount] = useState("");
  const [dis, setDis] = useState(0);
  const [itemDis, setItemDis] = useState(0);
  const [total, setTotal] = useState(0);
  const [itemTotal, setItemTotal] = useState(0);
  const [itemList, setItemList] = useState([]);
  const [drugs, setDrugs] = useState([]);
  const [itemNameId, setItemNameId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filteredBatch, setFilteredBatch] = useState([]);
  const [pharmacy] = usePharmacy();
  const [netTotal, setNetTotal] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);

  //-----------------------------------------------------------------------
  //------------------------All Use Effects--------------------------------
  //-----------------------------------------------------------------------

  useEffect(() => {
    getSupplierName();
  }, []);
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup function to clear the timeout when the component unmounts or when keyword changes
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [keyword]);

  useEffect(() => {
    // Function to call getUserDrugs after a delay of 0.5 seconds
    const debounceSearch = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        if (keyword.length > 3) {
          getUserDrugs();
        }
      }, 500); // Delay of 0.5 seconds (500 milliseconds)
    };

    // Call debounceSearch whenever keyword changes
    debounceSearch();
  }, [keyword]);

  useEffect(() => {
    generateTotal();
  }, [itemList, quantity, ptr, gst, dis]);

  useEffect(() => {
    calculateTotalAmount();
    setAcceptableQuantity(parseInt(quantity) + parseInt(freeQuantity));
  }, [quantity, ptr, gst, freeQuantity, itemDis]);

  //-----------------------------------------------------------------------
  //-----------Get Single Drug details Api Call By Axios-------------------
  //-----------------------------------------------------------------------
  const singleDrugDetail = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/drug/${itemNameId._id}`
      );
      if (data && data.success) {
        setSelectedItem(data?.drug);
        setGst(data?.drug.gst);
      }
    } catch (error) {
      console.log(error);
      toast.error("Plese Select item");
    }
  };

  //-----------------------------------------------------------------------
  //------------------- Filter supplier name Function -------------------------------
  //-----------------------------------------------------------------------

  const filterSuplier = (name) => {
    if (suppilerNames && suppilerNames.length > 0) {
      const filteredSupplierNames = suppilerNames.filter(
        (item) => item && item.toLowerCase().includes(name.toLowerCase())
      );
      setFilterSuppilerNames(filteredSupplierNames);
      console.log(filteredSupplierNames);
    }
  };

  //-----------------------------------------------------------------------
  //-------------------  Filter Batch Number Function-------------------------------
  //-----------------------------------------------------------------------

  const filterBatch = (batchNo) => {
    if (batchNo && selectedItem && selectedItem.batch) {
      const filteredBatch = selectedItem.batch.filter((item) =>
        item.batchNo.toLowerCase().includes(batchNo.toLowerCase())
      );
      setFilteredBatch(filteredBatch);
    }
  };
  //-----------------------------------------------------------------------
  //--------------------------set batch Number Function------------------------------
  //-----------------------------------------------------------------------

  const setBatchDeatail = (item) => {
    setbatchNo(item.batchNo);
    setExpiryDate(item.expiryDate);
    setMfgDate(item.mfgDate);
    setStripSize(item.stripSize);
    setMrp(item.mrp);
    setPtr(item.ptr);
    setFilteredBatch([]);
  };
  //-----------------------------------------------------------------------
  //------------ Function to calculate total amount for an item------------
  //-----------------------------------------------------------------------

  const calculateTotalAmount = () => {
    const netAmount = quantity * ptr;
    const subitemTotal = netAmount * (1 + gst / 100);
    const itemDiscount = subitemTotal * (itemDis / 100);
    const itemTotal = subitemTotal - itemDiscount;
    const getGstAmount = itemTotal - quantity * ptr;
    setNetTotal(netAmount.toFixed(2));
    setItemTotal(itemTotal.toFixed(2));
    setGstAmount(getGstAmount.toFixed(2));
  };

  //-----------------------------------------------------------------------
  //------Calculate total amount for each item and sum them up-----
  //-----------------------------------------------------------------------

  const generateTotal = () => {
    const subtotal = itemList.reduce((acc, item) => {
      return acc + parseFloat(item.itemTotal);
    }, 0);

    let totalAmount = subtotal;

    // Check if the decimal part of the total amount is greater than 0.50
    const decimalPart = totalAmount - Math.floor(totalAmount);
    if (decimalPart > 0.5) {
      // If decimal part is greater than 0.50, round up
      totalAmount = Math.ceil(totalAmount);
    } else {
      // If decimal part is less than or equal to 0.50, round down
      totalAmount = Math.floor(totalAmount);
    }

    // Adjust the total amount based on the discount
    totalAmount = totalAmount - (totalAmount * dis) / 100;

    setTotal(totalAmount.toFixed(2));
  };

  //-----------------------------------------------------------------------
  //------------------------Item Reset Function ---------------------------
  //-----------------------------------------------------------------------
  const itemReset = (e) => {
    e.preventDefault();
    setItemNameId(null);
    setbatchNo("");
    setExpiryDate("");
    setMfgDate("");
    setStripSize("");
    setMrp("");
    setPtr("");
    setQuantity("");
    setFreeQuantity(0);
    setAcceptableQuantity("");
    setGst("");
    setFilteredBatch([]);
  };

  //-----------------------------------------------------------------------
  //-----------------------------Remove Item Function----------------------------
  //-----------------------------------------------------------------------
  const handleRemoveItem = (index) => {
    const updatedItemList = itemList.filter((item, i) => i !== index);
    setItemList(updatedItemList);
  };

  //-----------------------------------------------------------------------
  //-------------------------- Get Pharmacy Drug--------------------------
  //-----------------------------------------------------------------------
  const getUserDrugs = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/drug/search/${keyword}`
      );
      if (data && data.success) {
        setDrugs(data?.drugs);
      }
    } catch (error) {
      console.log(error);
      toast.error("Somthing Went Wrong");
    }
  };
  //-----------------------------------------------------------------------
  //-------------------------- Get Suplier Name --------------------------
  //-----------------------------------------------------------------------
  const getSupplierName = async (req, res) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/grn/supilername/${pharmacy.pharmacy._id}`
      );
      if (data && data.success) {
        // setSuppilerNames(data?.suppilerNames);
        const uniqueSupplierNames = new Set(
          data?.suppilerNames.map((item) => item.suppilerName)
        );
        // Converting Set back to array
        const uniqueSupplierNamesArray = Array.from(uniqueSupplierNames);
        setSuppilerNames(uniqueSupplierNamesArray);
      }
    } catch (error) {
      console.log(error);
      toast.error("Somthing Went Wrong");
    }
  };
  //-----------------------------------------------------------------------
  //-------------- Add Item To the Array Function --------------------------
  //-----------------------------------------------------------------------
  const handleItem = async (e) => {
    e.preventDefault();

    if (parseInt(quantity) <= 0) {
      toast.error("Quantity should be greater than zero");
      return;
    }

    await batchNoUpdate();

    setItemList([
      ...itemList,
      {
        itemId: itemNameId._id,
        itemName: itemNameId.brandName,
        formulation: itemNameId.formulation,
        itemGenericName: itemNameId.genericName,
        itemCode: itemNameId.itemCode,
        batchNo,
        expiryDate,
        mfgDate,
        mrp,
        ptr,
        stripSize,
        quantity,
        freeQuantity,
        itemDis,
        gstAmount,
        acceptableQuantity,
        gst,
        itemTotal,
      },
    ]);
    inputRef.current.focus();
    setItemDis(0);
    setItemNameId(null);
    setbatchNo("");
    setExpiryDate("");
    setMfgDate("");
    setStripSize("");
    setMrp("");
    setPtr("");
    setQuantity("");
    setFreeQuantity(0);
    setAcceptableQuantity("");
    setGst("");
    setFilteredBatch([]);
  };

  //-----------------------------------------------------------------------
  //------------------Save The Final GRN Api Call by axios--------------------
  //-----------------------------------------------------------------------

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!invoiceDate || !invoiceNumber || !suppilerName) {
      toast.error("Please fill in all mandatory fields");
      return;
    }

    if (dis > 100) {
      return toast.error("Discount Not More than 100");
    }

    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}api/v1/grn/add-grn`,
        {
          pharmacy: pharmacy.pharmacy,
          invoiceDate,
          invoiceNumber,
          suppilerName,
          itemList,
          dis,
          amount: total,
        }
      );
      if (data?.success) {
        setIsLoading(false);
        toast.success(data?.message);
        window.location.reload();
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
  //-----------------------------------------------------------------------
  //---------------------- Batch Number Update Function-----------------------
  //-----------------------------------------------------------------------

  const batchNoUpdate = async () => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_URL}api/v1/drug/update-batch/${itemNameId._id}`,
        {
          batch: {
            batchNo: batchNo,
            stripSize: stripSize,
            expiryDate: expiryDate,
            mfgDate: mfgDate,
            mrp: mrp,
            ptr: ptr,
          },
        },
        { new: true }
      );
      if (data?.success) {
        toast.success("Item Add Successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error);
    }
  };

  //-----------------------------------------------------------------------
  //---------------------- Main Functioin Return Value-----------------------
  //-----------------------------------------------------------------------

  return (
    <Container>
      <Left>
        <UserSidebar />
      </Left>
      <Right>
        <DashNav />
        {/* ---------------------- Breadcrumbs Container----------------------- */}
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Home
            </Link>
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Inventory
            </Link>
            <Link
              className="link"
              style={{ color: "#212333" }}
              to="/dashboard/pharmacy/purchase-order"
            >
              PHARMACY PURCHASE
            </Link>
          </Breadcrumbs>
        </BreadcrumbsContainer>
        {/* ---------------------- Main Container----------------------- */}
        <Title> PHARMACY PURCHASE</Title>
        <GrnContainer>
          {/* ----------- Essential Details----------- */}
          <Box1>
            <BoxTitle> Essential Details (Mandatory Fields)</BoxTitle>
            <div
              style={{
                padding: "10px",
                paddingBottom: "none",
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <InputField>
                <Lable>Invoice Date : </Lable>
                <Input
                  required
                  type="date"
                  value={invoiceDate}
                  onChange={(e) => setInvoiceDate(e.target.value)}
                />
              </InputField>
              <InputField>
                <Lable>Invoice Number : </Lable>
                <Input
                  placeholder="Invoice Number"
                  value={invoiceNumber}
                  onChange={(e) =>
                    setInvoiceNumber(e.target.value.toUpperCase())
                  }
                />
              </InputField>
              <InputField style={{ width: "99%" }}>
                <Lable>Suppiler Name : </Lable>
                <Input
                  placeholder="Suppiler Name"
                  value={suppilerName}
                  onChange={(e) => {
                    setSuppilerName(e.target.value);
                    filterSuplier(e.target.value);
                  }}
                />
                {filterSuppilerNames.length > 0 && (
                  <SuplierNameList>
                    {filterSuppilerNames &&
                      filterSuppilerNames.map((item, i) => (
                        <SuplierNameItem
                          onClick={() => {
                            setSuppilerName(item);
                            setFilterSuppilerNames([]);
                          }}
                        >
                          {item}
                        </SuplierNameItem>
                      ))}
                  </SuplierNameList>
                )}
              </InputField>
            </div>
          </Box1>
          <Box1>
            {/* ----------- Add Item Container----------- */}
            <BoxTitle>Add Item</BoxTitle>
            <form onSubmit={handleItem}>
              <div
                style={{
                  padding: "10px",
                  paddingBottom: "none",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                <InputField>
                  <Lable>Item Name : </Lable>

                  <Select
                    ref={inputRef}
                    required
                    styles={{
                      container: (baseStyles, state) => ({
                        ...baseStyles,
                        minWidth: "60%",
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: "7px",
                        borderRadius: "10px",
                        border: "2px solid black",
                        outline: "none",
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "black",
                        backgroundColor: state.isFocused ? "#3C4B64" : "white",
                        color: state.isFocused ? "white" : "#3C4B64",
                      }),
                    }}
                    autoFocus
                    onInputChange={(e) => setKeyword(e)}
                    placeholder="Please Enter 3 latter for Search item..."
                    value={itemNameId}
                    noOptionsMessage={() => "Item Not Found"}
                    onChange={setItemNameId}
                    options={drugs}
                  />
                </InputField>
                {console.log(itemNameId)}
                <InputField>
                  <Lable>Batch Number : </Lable>
                  <Input
                    required
                    onFocus={singleDrugDetail}
                    placeholder="Batch Number"
                    value={batchNo}
                    onChange={(e) => {
                      setbatchNo(e.target.value.toUpperCase());
                      filterBatch(e.target.value.toUpperCase());
                    }}
                  />
                </InputField>
                {/* ----------- Batch Number Container----------- */}
                {filteredBatch.length > 0 && (
                  <BatchContainer>
                    <Table>
                      <thead>
                        <Row>
                          <Heading>Batch No.</Heading>
                          <Heading>Exp. Date</Heading>
                          <Heading>Mfg. Date</Heading>
                          <Heading>Strip Size</Heading>
                          <Heading>MRP</Heading>
                          <Heading>PTR</Heading>
                        </Row>
                      </thead>
                      <tbody>
                        {filteredBatch.map((item, i) => (
                          <Row key={i} onClick={() => setBatchDeatail(item)}>
                            <Column>
                              <button
                                style={{
                                  border: "none",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {item.batchNo}
                              </button>
                            </Column>
                            <Column>{item.expiryDate}</Column>
                            <Column>{item.mfgDate}</Column>
                            <Column>{item.stripSize} X strip</Column>
                            <Column>{item.mrp}</Column>
                            <Column>{item.ptr}</Column>
                          </Row>
                        ))}
                      </tbody>
                    </Table>
                  </BatchContainer>
                )}
                <BoxV2>
                  <InputFieldV2>
                    <LableV2>Exp. Date : </LableV2>
                    <InputV2
                      required
                      type="month"
                      value={expiryDate}
                      onChange={(e) => setExpiryDate(e.target.value)}
                    />
                  </InputFieldV2>
                  <InputFieldV2>
                    <LableV2>Mfg. Date : </LableV2>
                    <InputV2
                      type="month"
                      value={mfgDate}
                      onChange={(e) => setMfgDate(e.target.value)}
                    />
                  </InputFieldV2>
                  <InputFieldV2>
                    <LableV2>Strip Size : </LableV2>
                    <SelectHTMLV2
                      required
                      value={stripSize}
                      onChange={(e) => setStripSize(e.target.value)}
                    >
                      <OptionHTMLV2 defaultChecked>
                        Select A Strip Size
                      </OptionHTMLV2>
                      <OptionHTMLV2 value={1}>1 Number</OptionHTMLV2>
                      <OptionHTMLV2 value={2}>2 X Strip</OptionHTMLV2>
                      <OptionHTMLV2 value={3}>3 X Strip</OptionHTMLV2>
                      <OptionHTMLV2 value={4}>4 X Strip</OptionHTMLV2>
                      <OptionHTMLV2 value={5}>5 X Strip</OptionHTMLV2>
                      <OptionHTMLV2 value={6}>6 X Strip</OptionHTMLV2>
                      <OptionHTMLV2 value={9}>9 X Strip</OptionHTMLV2>
                      <OptionHTMLV2 value={10}>10 X Strip</OptionHTMLV2>
                      <OptionHTMLV2 value={15}>15 X Strip</OptionHTMLV2>
                      <OptionHTMLV2 value={20}>20 X Strip</OptionHTMLV2>
                      <OptionHTMLV2 value={30}>30 X Strip</OptionHTMLV2>
                    </SelectHTMLV2>
                  </InputFieldV2>

                  <InputFieldV2>
                    <LableV2>MRP : </LableV2>
                    <InputV2
                      required
                      value={mrp}
                      onChange={(e) => setMrp(e.target.value)}
                    />
                  </InputFieldV2>
                  <InputFieldV2>
                    <LableV2>PTR : </LableV2>
                    <InputV2
                      required
                      value={ptr}
                      onChange={(e) => setPtr(e.target.value)}
                    />
                  </InputFieldV2>
                  <InputFieldV2>
                    <LableV2>Quantity : </LableV2>
                    <InputV2
                      required
                      placeholder="Quantity"
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </InputFieldV2>
                </BoxV2>
                <BoxV2>
                  <InputFieldV2>
                    <LableV2>Free Quantity : </LableV2>
                    <InputV2
                      required
                      placeholder="Free Quantity"
                      value={freeQuantity}
                      onChange={(e) => setFreeQuantity(e.target.value)}
                    />
                  </InputFieldV2>
                  <InputFieldV2>
                    <LableV2>Acceptable Que : </LableV2>
                    <InputV2
                      required
                      disabled
                      placeholder="Total Quantity  "
                      value={acceptableQuantity}
                      onChange={(e) => setAcceptableQuantity(e.target.value)}
                    />
                  </InputFieldV2>

                  <InputFieldV2>
                    <LableV2>GST % : </LableV2>
                    <InputV2
                      disabled
                      required
                      placeholder="GST %"
                      value={gst}
                      onChange={(e) => setGst(e.target.value)}
                    />
                  </InputFieldV2>
                  <InputFieldV2>
                    <LableV2>Net Amount : </LableV2>
                    <InputV2
                      disabled
                      placeholder="Net Amount"
                      value={netTotal}
                    />
                  </InputFieldV2>
                  <InputFieldV2>
                    <LableV2>Item Dic % : </LableV2>
                    <InputV2
                      required
                      placeholder="Item Dic %"
                      value={itemDis}
                      onChange={(e) => setItemDis(e.target.value)}
                    />
                  </InputFieldV2>
                  <InputFieldV2>
                    <LableV2>Amount : </LableV2>
                    <InputV2
                      disabled
                      placeholder="Total Amount"
                      value={itemTotal}
                    />
                  </InputFieldV2>
                </BoxV2>
                <Controller>
                  <Button type="submit" className="darkButton">
                    Add Item
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      color: "#3c4b64",
                      border: "2px solid #3c4b64",
                    }}
                    onClick={(e) => itemReset(e)}
                  >
                    Reset
                  </Button>
                </Controller>
              </div>
            </form>
          </Box1>
          {/* ---------------------- item List  Container----------------------- */}
          {itemList.length > 0 && (
            <Box1>
              <BoxTitle>Item List</BoxTitle>
              <Table>
                <thead>
                  <Row>
                    <Heading>No.</Heading>
                    <Heading>Item Name</Heading>
                    <Heading>Batch No.</Heading>
                    <Heading>Strip Size</Heading>
                    <Heading>Quantiy</Heading>
                    <Heading>Free Qty</Heading>
                    <Heading>Accepted Qty</Heading>
                    <Heading>Payable Qty</Heading>
                    <Heading>PTR</Heading>
                    <Heading>Dis %</Heading>
                    <Heading>GST Amount</Heading>
                    <Heading>Amount</Heading>
                    <Heading>Action</Heading>
                  </Row>
                </thead>
                <tbody>
                  {itemList &&
                    itemList.map((i, index) => (
                      <Row key={index}>
                        <Column>{index + 1}</Column>
                        <Column>{i.itemName}</Column>
                        <Column>{i.batchNo}</Column>
                        <Column>{i.stripSize} X Strip</Column>
                        <Column>{i.quantity}</Column>
                        <Column>{i.freeQuantity}</Column>
                        <Column>{i.acceptableQuantity}</Column>
                        <Column>{i.quantity}</Column>
                        <Column>{i.ptr}</Column>
                        <Column>{i.itemDis}</Column>
                        <Column>{i.gstAmount}</Column>
                        <Column>{i.itemTotal}</Column>
                        <Column>
                          <DeleteButton onClick={() => handleRemoveItem(index)}>
                            <DeleteIcon />
                          </DeleteButton>
                        </Column>
                      </Row>
                    ))}
                </tbody>
              </Table>
              <TotalContainer>
                <InputField style={{ width: "20%" }}>
                  <Lable>DIS % : </Lable>
                  <Input value={dis} onChange={(e) => setDis(e.target.value)} />
                </InputField>

                <InputField style={{ width: "20%" }}>
                  <Lable>Total : </Lable>
                  <Input disabled value={total} />
                </InputField>
              </TotalContainer>
              <Controller>
                <Button onClick={handleSubmit}>
                  {isLoading ? <LoaderIcon /> : "Submit"}
                </Button>
                <Button
                  style={{
                    backgroundColor: "transparent",
                    color: "#3c4b64",
                    border: "2px solid #3c4b64",
                  }}
                  onClick={() => window.location.reload()}
                >
                  Reset
                </Button>
              </Controller>
            </Box1>
          )}
        </GrnContainer>
      </Right>
    </Container>
  );
};

export default PurchaseOrder;
