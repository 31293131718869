import styled from "styled-components";
import DashNav from "../../components/DashNAv";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import UserSidebar from "../../components/user/UserSidebar";
import axios from "axios";
import toast, { LoaderIcon } from "react-hot-toast";
import { useEffect, useState } from "react";
import { usePharmacy } from "../../context/pharmacy";
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ReturnHelper from "../../components/user/ReturnHelper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflowY: "scroll",
  height: "100%",
};

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Left = styled.div`
  flex: 2;
`;

const Right = styled.div`
  flex: 11;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Title = styled.h1`
  margin: 20px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #3c4b64;
`;

const SearchContainer = styled.form`
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const InputDiv = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  position: relative;
`;

const Label = styled.label`
  font-weight: 700;
  width: 100%;
  font-size: 18px;
`;

const Input = styled.input`
  flex: 1;
  min-width: 60%;
  padding: 10px;
  border-radius: 10px;
  ::placeholder {
    color: gray !important;
  }
`;

const PatientNameList = styled.div`
  border: 1px solid black;
  background-color: white;
  width: auto;
  min-width: 60%;
  height: auto;
  border-radius: 10px;
  position: absolute;
  top: 48px;
  z-index: 999;
  right: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;
`;

const PatientNameItem = styled.option`
  padding: 0 10px;
  width: 100%;
  z-index: 9999;
  list-style: none;
  height: 30px;
  cursor: pointer;

  &:hover {
    background-color: #3c4b64;
    color: white;
  }
`;

const Button = styled.button`
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  border-radius: 10px;
  border: none;
  background-color: #3c4b64;
  color: white;
  cursor: pointer;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-radius: 10px;
  width: 100%;
  margin: 20px 0;
  padding: 20px;
`;

const Row = styled.tr`
  width: 100%;
  color: black;
`;
const Heading = styled.th`
  height: 50px;
  text-align: center;
  color: var(--primary-color);
  border-bottom: 0.5px solid lightgrey;

  background-color: var(--secondary-color);
  color: white;
`;

const Column = styled.td`
  border-bottom: 0.5px solid lightgrey;
  border-collapse: collapse;
  height: 50px;
  text-align: center;
  color: var(--secondary-color);
`;

const Return = () => {
  const [pharmacy] = usePharmacy();
  const [patientList, setPatientList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [filterPatientList, setFilterPatientList] = useState([]);
  const [result, setResult] = useState([]);
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //-----------------------------------------------------------------------
  //---------------------- Model Open Close Fuction-----------------------
  //-----------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //-----------------------------------------------------------------------
  //----------------------get Result------------------------------
  //-----------------------------------------------------------------------

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!keyword) {
      return toast.error("Enter Patient Name or Number");
    }
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/dispatch/search/${keyword}`
      );
      if (data && data.success) {
        setIsLoading(false);
        setResult(data?.result);
        setFilterPatientList([]);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error("Somthing Went Wrong");
    }
  };

  //-----------------------------------------------------------------------
  //----------------------get Patien List  ------------------------------
  //-----------------------------------------------------------------------

  const getPatientList = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/dispatch/patient-list/${pharmacy.pharmacy._id}`
      );
      if (data && data.success) {
        const uniquePatients = removeDuplicates(data?.patientList, "lable");
        setPatientList(uniquePatients);
      }
    } catch (error) {
      console.log(error);
      toast.error("Somthing Went Wrong");
    }
  };

  useEffect(() => {
    getPatientList();
  }, []);

  //-----------------------------------------------------------------------
  //---------------------- Remove Duplicate ------------------------------
  //-----------------------------------------------------------------------
  function removeDuplicates(arr, prop) {
    const uniqueSet = new Set();
    return arr.filter((obj) => {
      const val = obj[prop];
      if (!uniqueSet.has(val)) {
        uniqueSet.add(val);
        return true;
      }
      return false;
    });
  }

  //-----------------------------------------------------------------------
  //---------------------- Filter Patient List ------------------------------
  //-----------------------------------------------------------------------

  const filterPatient = (name) => {
    if (patientList && patientList.length > 0) {
      const filterPatientNames = patientList.filter((item) =>
        item.lable.toLowerCase().includes(name.toLowerCase())
      );
      setFilterPatientList(filterPatientNames);
    }
  };

  return (
    <Container>
      <Left>
        <UserSidebar />
      </Left>
      <Right>
        <DashNav />
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Home
            </Link>
            <Link
              className="link"
              style={{ color: "#212333" }}
              to="/dashboard/pharmacy"
            >
              RETURN
            </Link>
          </Breadcrumbs>
        </BreadcrumbsContainer>

        <Title>RETURN</Title>
        <SearchContainer onSubmit={(e) => handleSearch(e)}>
          <InputDiv>
            <Label>Patient Name / Number : </Label>
            <Input
              placeholder="Enter Patient Name or Number"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                filterPatient(e.target.value);
              }}
            />
            {filterPatientList && filterPatientList.length > 0 && (
              <PatientNameList>
                {filterPatientList?.map((item, i) => (
                  <PatientNameItem
                    key={i}
                    onClick={() => {
                      setKeyword(item.lable);
                      setFilterPatientList([]);
                    }}
                  >
                    {item.lable}
                  </PatientNameItem>
                ))}
              </PatientNameList>
            )}
          </InputDiv>
          <Button type="submit">{isLoading ? <LoaderIcon /> : "Search"}</Button>
        </SearchContainer>
        {result && result.length > 0 && (
          <Table>
            <thead>
              <Row>
                <Heading>No.</Heading>
                <Heading>Invoice Date</Heading>
                <Heading>Patient Name</Heading>
                <Heading>Patient Number</Heading>
                <Heading>Dis %</Heading>
                <Heading> Dis Amount</Heading>
                <Heading>Amount</Heading>
                <Heading>Action</Heading>
              </Row>
            </thead>
            <tbody>
              {result &&
                result.length > 0 &&
                result.map((item, i) => (
                  <Row key={i}>
                    <Column>{i + 1}</Column>
                    <Column>{item.invoiceDate}</Column>
                    <Column>{item.patientName}</Column>
                    <Column>{item.patientNumber}</Column>
                    <Column>{item.dis}</Column>
                    <Column>{item.disAmount}</Column>
                    <Column>{item.amount}</Column>
                    <Column
                      onClick={() => {
                        setItem(item);
                        handleOpen();
                      }}
                      style={{ cursor: "pointer", textDecoration: "underLine" }}
                    >
                      Return
                    </Column>
                  </Row>
                ))}
            </tbody>
          </Table>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ReturnHelper item={item} />
          </Box>
        </Modal>
      </Right>
    </Container>
  );
};

export default Return;
