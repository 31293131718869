import { Link } from "react-router-dom";
import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import toast from "react-hot-toast";
import { usePharmacy } from "../context/pharmacy";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const ProfileMenu = () => {
  const [pharmacy, setPharmacy] = usePharmacy();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("pharmacy");
    setPharmacy({ pharmacy: [], token: null });
    toast.success("logged out successfully");
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <span style={{ color: "white", fontWeight: "800", marginRight: "5px" }}>
          Hello, {pharmacy?.pharmacy?.name}
        </span>
        💖👇🏻
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {pharmacy?.pharmacy?.subscriptions?.status === "active" ? (
          <>
            <Link
              style={{ color: "black", textDecoration: "none" }}
              to="/my-profile"
            >
              <MenuItem onClick={handleClose}>
                <AccountCircleIcon sx={{ marginRight: "5px" }} />
                My Profile
              </MenuItem>
            </Link>
            <Link
              style={{ color: "black", textDecoration: "none" }}
              to={
                pharmacy?.pharmacy?.role === 1
                  ? "/dashboard/admin"
                  : "/dashboard/pharmacy"
              }
            >
              <MenuItem onClick={handleClose}>
                <DashboardIcon sx={{ marginRight: "5px" }} />
                Dashborad
              </MenuItem>
            </Link>
          </>
        ) : (
          <Link
            style={{ color: "black", textDecoration: "none" }}
            to="/my-profile"
          >
            <MenuItem onClick={handleClose}>
              <AccountCircleIcon sx={{ marginRight: "5px" }} />
              My Profile
            </MenuItem>
          </Link>
        )}

        <Link
          style={{ color: "black", textDecoration: "none" }}
          onClick={handleLogout}
          to="/"
        >
          <MenuItem onClick={handleClose}>
            <LogoutIcon sx={{ marginRight: "5px" }} />
            Logout
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
};

export default ProfileMenu;
