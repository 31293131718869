import styled from "styled-components";
import DashNav from "../../components/DashNAv";
import { Breadcrumbs, colors } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import UserSidebar from "../../components/user/UserSidebar";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import toast, { LoaderIcon } from "react-hot-toast";
import Select from "react-select";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { usePharmacy } from "../../context/pharmacy";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 950,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Container = styled.div`
  display: flex;
  position: relative;
`;

const Left = styled.div`
  flex: 2;
`;

const Right = styled.div`
  flex: 11;
`;

const BreadcrumbsContainer = styled.div`
  padding: 20px;
  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Title = styled.h1`
  margin: 20px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #3c4b64;
`;

const DispatchContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  color: #3c4b64;
  width: 100%;
  margin: 0 auto;
`;

const Box1 = styled.div`
  margin: 20px 0;
  width: 90%;
  border: 0.5px solid lightgrey;
  border-radius: 10px;
  max-width: 100%;
`;

const BoxTitle = styled.h4`
  background-color: #3c4b64;
  color: white;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 10px 10px 0 0;
`;

const InputField = styled.div`
  width: 49%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  position: relative;
`;

const Lable = styled.label`
  width: 100%;
  font-size: 18px;
`;

const Input = styled.input`
  flex: 1;
  min-width: 60%;
  padding: 10px;
  border-radius: 10px;
  ::placeholder {
    color: gray !important;
  }
`;

const BoxV2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  gap: 20px;
  width: 100% !important;
`;

const InputFieldV2 = styled.div`
  flex: 1;
  width: 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const LableV2 = styled.label`
  margin-left: 5px;
  font-size: 14px;
  letter-spacing: 2px;
`;

const InputV2 = styled.input`
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
`;

const SelectHTMLV2 = styled.select`
  padding: 10px;
  border-radius: 10px;
`;

const OptionHTMLV2 = styled.option``;

const SelectHTML = styled.select`
  flex: 1;
  min-width: 60%;
  padding: 10px;
  border-radius: 10px;
`;

const OptionHTML = styled.option`
  flex: 1;
  min-width: 60%;
  padding: 10px;
  border-radius: 10px;
  &:hover {
    background-color: #3c4b64;
    color: white;
  }
`;

const PatientNameList = styled.div`
  border: 1px solid black;
  background-color: white;
  width: auto;
  min-width: 60%;
  height: auto;
  border-radius: 10px;
  position: absolute;
  top: 55px;
  z-index: 999;
  right: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;
`;

const PatientNameItem = styled.option`
  padding: 0 10px;
  width: 100%;
  z-index: 9999;
  list-style: none;
  height: 30px;
  cursor: pointer;

  &:hover {
    background-color: #3c4b64;
    color: white;
  }
`;

const Controller = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Button = styled.button`
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 10px;
  border: none;
  background-color: #3c4b64;
  color: white;
  cursor: pointer;
  margin-top: 30px;
  border: 2px solid #3c4b64;
  border-radius: 10px;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-radius: 10px;
  width: 100%;
  margin: 20px 0;
  padding: 20px;
  margin-bottom: 90px;
  position: relative;
`;

const Row = styled.tr`
  width: 100%;
  cursor: pointer;
`;
const Heading = styled.th`
  height: 50px;
  text-align: center;
  color: var(--primary-color);
  border-bottom: 0.5px solid lightgrey;
`;

const Column = styled.td`
  border-bottom: 0.5px solid lightgrey;
  border-collapse: collapse;
  height: 50px;
  text-align: center;
  color: var(--secondary-color);
`;

const DeleteButton = styled.button`
  padding: 7px;
  font-size: 10px;
  background-color: white;
  border: none;
  color: red;
  border-radius: 10px;
  cursor: pointer;

  &:focus {
    outline: 2px solid black;
  }
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Dispatch = () => {
  //-----------------------------------------------------------------------
  //-----------------------------------------------------------------------
  //-----------------------------------------------------------------------
  const [defaultDate, setDefaultDate] = useState(getTodayDate());
  const [stocks, setStocks] = useState([]);
  const [patientName, setPatientName] = useState("");
  const [patientNumber, setPatientNumber] = useState("");
  const [doctorName, setDoctorName] = useState("Dr. ");
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemstripSize, setItemStripSize] = useState(1);
  const [stripSizes, setStripSizes] = useState(
    Array(selectedItem?.batch?.length).fill(1)
  );
  const [batchNo, setBatchNo] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [mrp, setMrp] = useState("");
  const [ptr, setPtr] = useState("");
  const [gst, setGst] = useState("");
  const [itemQuantity, setItemQuantity] = useState("");
  const [quantity, setQuantity] = useState("");
  const [dis, setDis] = useState("0");
  const [disAmount, setDisAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [itemTotal, setItemTotal] = useState(0);
  const [itemList, setItemList] = useState([]);
  const [pharmacy] = usePharmacy();
  const [keyword, setKeyword] = useState("");
  const [patientList, setPatientList] = useState([]);
  const [filterPatientList, setFilterPatientList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  //-----------------------------------------------------------------------
  //---------------------- Model Open Close Fuction-----------------------
  //-----------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);
  const handleOpen = async () => {
    if (!selectedItem) {
      toast.error("Please Select item");
      return;
    }

    if (!open) {
      setOpen(true);
    }
    setStripSizes(Array(selectedItem?.batch?.length).fill(1));
  };
  const handleClose = () => {
    setOpen(false);
  };
  //-----------------------------------------------------------------------
  //----------------------All UseEffects ------------------------------
  //-----------------------------------------------------------------------

  useEffect(() => {
    getPatientList();
  }, []);

  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup function to clear the timeout when the component unmounts or when keyword changes
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [keyword]);

  useEffect(() => {
    // Function to call getuserStock after a delay of 0.5 seconds
    const debounceSearch = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        if (keyword.length >= 3) {
          getuserStock();
        }
      }, 500); // Delay of 0.5 seconds (500 milliseconds)
    };

    // Call debounceSearch whenever keyword changes
    debounceSearch();
  }, [keyword]);

  useEffect(() => {
    generateTotal();
  }, [itemList, dis]);

  //-----------------------------------------------------------------------
  //---------------------- Filter Patient List ------------------------------
  //-----------------------------------------------------------------------

  const filterPatient = (name) => {
    if (patientList && patientList.length > 0) {
      const filterPatientNames = patientList.filter((item) =>
        item.lable.toLowerCase().includes(name.toLowerCase())
      );
      setFilterPatientList(filterPatientNames);
    }
  };

  //-----------------------------------------------------------------------
  //---------------------- Remove Duplicate ------------------------------
  //-----------------------------------------------------------------------
  function removeDuplicates(arr, prop) {
    const uniqueSet = new Set();
    return arr.filter((obj) => {
      const val = obj[prop];
      if (!uniqueSet.has(val)) {
        uniqueSet.add(val);
        return true;
      }
      return false;
    });
  }

  //-----------------------------------------------------------------------
  //---------------------- Methemetic  Function ------------------------------
  //-----------------------------------------------------------------------
  const generateTotal = () => {
    const subtotal = itemList.reduce((acc, item) => {
      return acc + parseFloat(item.itemTotal);
    }, 0);

    let totalAmount = subtotal;

    const decimalPart = totalAmount - Math.floor(totalAmount);
    if (decimalPart >= 0.5) {
      // If decimal part is 0.5 or greater
      totalAmount = Math.ceil(totalAmount);
    } else {
      // If decimal part is less than 0.5
      totalAmount = Math.floor(totalAmount);
    }

    setDisAmount((totalAmount * dis) / 100);

    totalAmount = totalAmount - (totalAmount * dis) / 100;

    setTotal(totalAmount.toFixed(2));
  };
  //-----------------------------------------------------------------------
  //----------------------getTodayDate ------------------------------
  //-----------------------------------------------------------------------
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  //-----------------------------------------------------------------------
  //----------------------get User Stock ------------------------------
  //-----------------------------------------------------------------------

  const getuserStock = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/stock/${pharmacy?.pharmacy?._id}/search/${keyword}`
      );
      if (data && data.success) {
        setStocks(data?.stocks);
      }
    } catch (error) {
      console.log(error);
      toast.error("Somthing Went Wrong");
    }
  };
  //-----------------------------------------------------------------------
  //----------------------get Patien List  ------------------------------
  //-----------------------------------------------------------------------

  const getPatientList = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL}api/v1/dispatch/patient-list/${pharmacy.pharmacy._id}`
      );
      if (data && data.success) {
        const uniquePatients = removeDuplicates(data?.patientList, "lable");
        setPatientList(uniquePatients);
      }
    } catch (error) {
      console.log(error);
      toast.error("Somthing Went Wrong");
    }
  };

  //-----------------------------------------------------------------------
  //---------------------- Item Reset Function ------------------------------
  //-----------------------------------------------------------------------

  const itemReset = (e) => {
    e.preventDefault();
    setStocks([]);
    setSelectedItem([]);
    setBatchNo("");
    setExpiryDate("b.expiryDate");
    setMrp("");
    setItemStripSize("");
    setGst("");
    setItemQuantity("");
    setItemTotal("");
    setStripSizes([]);
    setQuantity("");
  };

  //-----------------------------------------------------------------------
  //---------------------- Item Add Function ------------------------------
  //-----------------------------------------------------------------------

  const handleItem = (e) => {
    e.preventDefault();

    if (!selectedItem) {
      toast.error("Please Select item");

      return;
    }
    if (!batchNo) {
      toast.error("Please Select Batch Number");
      return;
    }

    const today = new Date();
    const selectedExpiryDate = new Date(expiryDate);
    if (selectedExpiryDate < today) {
      const confirmExpiry = window.confirm(
        "The selected item has expired. Do you still want to proceed?"
      );
      if (!confirmExpiry) {
        return;
      }
    }
    inputRef.current.focus();
    const gstAmount = (itemTotal * selectedItem?.gst) / 100;

    const newItem = {
      itemId: selectedItem?._id,
      itemCode: selectedItem?.itemCode,
      itemName: selectedItem?.brandName,
      formulation: selectedItem?.formulation,
      itemGenericName: selectedItem?.genericName,
      batchNo: batchNo,
      expiryDate: expiryDate,
      quantity: itemQuantity,
      totalQuantity: itemQuantity * itemstripSize,
      unit: itemstripSize,
      mrp: mrp,
      ptr: ptr,
      gst: selectedItem?.gst,
      texable: gstAmount,
      itemTotal: itemTotal,
    };

    setItemList((prevItemList) => [...prevItemList, newItem]);

    // Find the index of the selected item in the stocks array
    const itemIndex = stocks.findIndex((item) => item._id === selectedItem._id);

    // Find the index of the batch in the selected item's batch array
    const batchIndex = stocks[itemIndex].batch.findIndex(
      (batch) => batch.batchNo === batchNo
    );

    // If both indexes are found, update the quantity of that batch in the stocks array
    if (itemIndex !== -1 && batchIndex !== -1) {
      const updatedStocks = [...stocks];
      updatedStocks[itemIndex].batch[batchIndex].quantity -=
        itemQuantity * itemstripSize;
      setStocks(updatedStocks);
    }

    setSelectedItem([]);
    setBatchNo("");
    setExpiryDate("b.expiryDate");
    setMrp("");
    setItemStripSize("");
    setGst("");
    setItemQuantity("");
    setItemTotal("");
    setStripSizes([]);
    setQuantity("");
    setStocks([]);
    handleClose();
  };

  //-----------------------------------------------------------------------
  //-----------------------------Remove Item Function----------------------------
  //-----------------------------------------------------------------------

  const handleRemoveItem = (index) => {
    const removedItem = itemList[index];

    const updatedItemList = itemList.filter((item, i) => i !== index);
    setItemList(updatedItemList);

    const itemIndex = stocks.findIndex(
      (item) => item._id === removedItem.itemId
    );

    // Find the index of the batch in the selected item's batch array
    const batchIndex = stocks[itemIndex]?.batch.findIndex(
      (batch) => batch.batchNo === removedItem.batchNo
    );

    // If both indexes are found, update the quantity of that batch in the stocks array
    if (itemIndex !== -1 && batchIndex !== -1) {
      const updatedStocks = [...stocks];
      updatedStocks[itemIndex].batch[batchIndex].quantity +=
        removedItem.totalQuantity;
      setStocks(updatedStocks);
    }
  };

  //-----------------------------------------------------------------------
  //---------------------- Batch Number save Function ------------------------------
  //-----------------------------------------------------------------------

  const calculateMrp = (b, stripSize) => {
    const result = (b.mrp / 1) * (stripSize || 1);
    return parseFloat(result.toFixed(2));
  };
  const calculatePtr = (b, stripSize) => {
    const result = (b.ptr / 1) * (stripSize || 1);
    return parseFloat(result.toFixed(2));
  };

  const calculateStock = (b, stripSize) => {
    return b.quantity / (stripSize || 1);
  };

  const handleQuantityBlur = (index, value) => {};

  const handleQuantityChange = (index, value) => {
    const newQuantities = [...quantity];
    newQuantities[index] = value;
    const availableStock = calculateStock(
      selectedItem.batch[index],
      stripSizes[index]
    );

    if (parseInt(value) > availableStock) {
      return toast.error("Quantity cannot be Greter than available stock");
    }
    setQuantity(newQuantities);
  };

  const handleStripSizeChange = (index, value) => {
    const newStripSizes = [...stripSizes]; // Make a copy of the stripSizes array
    newStripSizes[index] = value; // Update the strip size for the specific index
    setStripSizes(newStripSizes); // Update the stripSizes state with the modified array
  };

  const handleSave = (b, index) => {
    if (!quantity[index]) {
      return toast.error("Quantity Is Required");
    }
    setBatchNo(b.batchNo);
    setExpiryDate(b.expiryDate);
    setMrp(calculateMrp(b, stripSizes[index]));
    setPtr(calculatePtr(b, stripSizes[index]));
    setItemStripSize(stripSizes[index]);
    setGst(b.gst);
    setItemQuantity(quantity[index]);
    setItemTotal(quantity[index] * calculateMrp(b, stripSizes[index]));

    handleClose();
  };

  //-----------------------------------------------------------------------
  //---------------------- Final Submit  Function call Api by axios ------------------------------
  //-----------------------------------------------------------------------

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!patientName) {
      return toast.error("Patient Name is required");
    }
    if (!dis) {
      return toast.error("Discount Field Not Empty");
    }
    if (dis > 100) {
      return toast.error("Discount Not More than 100");
    }

    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL}api/v1/dispatch/add`,
        {
          pharmacy: pharmacy.pharmacy._id,
          invoiceDate: defaultDate,
          patientName,
          doctorName,
          patientNumber,
          itemList,
          dis,
          disAmount,
          amount: total,
        }
      );
      if (data?.success) {
        setIsLoading(false);
        toast.success(data?.message);
        navigate(`/dashboard/pharmacy/dispatch/${data?.dispatch._id}`);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Container>
      <Left>
        <UserSidebar />
      </Left>
      <Right>
        <DashNav />
        <BreadcrumbsContainer>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              className="link"
              style={{ color: "inherit" }}
              to="/dashboard/pharmacy"
            >
              Home
            </Link>
            <Link
              className="link"
              style={{ color: "#212333" }}
              to="/dashboard/pharmacy"
            >
              DISPATCH
            </Link>
          </Breadcrumbs>
        </BreadcrumbsContainer>
        <Title>Dispatch </Title>
        <DispatchContainer>
          <Box1>
            <BoxTitle> Essential Details (Mandatory Fields)</BoxTitle>
            <div
              style={{
                padding: "10px",
                paddingBottom: "none",
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <InputField style={{ width: "20%", flexDirection: "column" }}>
                <Lable
                  style={{
                    width: "100%",
                    fontSize: "12px",
                    fontWeight: "500",
                    textAlign: "center",
                    letterSpacing: "2px",
                  }}
                >
                  MM-DD-YYYY
                </Lable>
                <Input
                  disabled
                  type="date"
                  value={defaultDate}
                  onChange={(e) => setDefaultDate(e.target.value)}
                />
              </InputField>
              <InputField style={{ width: "75%" }}>
                <Lable>Patient Name : </Lable>
                <Input
                  placeholder="Patient Name"
                  value={patientName}
                  onChange={(e) => {
                    setPatientName(e.target.value);
                    filterPatient(e.target.value);
                  }}
                />
                {filterPatientList && filterPatientList.length > 0 && (
                  <PatientNameList>
                    {filterPatientList?.map((item, i) => (
                      <PatientNameItem
                        key={i}
                        onClick={() => {
                          setPatientName(item.lable.split(" | ")[0]);
                          setPatientNumber(item.lable.split(" | ")[1]);
                          setFilterPatientList([]);
                        }}
                      >
                        {item.lable}
                      </PatientNameItem>
                    ))}
                  </PatientNameList>
                )}
              </InputField>
              <InputField>
                <Lable>Mobile Number : </Lable>
                <Input
                  placeholder="Mobile Number"
                  value={patientNumber}
                  onChange={(e) => setPatientNumber(e.target.value)}
                />
              </InputField>
              <InputField>
                <Lable>Doctor Name: </Lable>
                <Input
                  placeholder="Doctor Name"
                  value={doctorName}
                  onChange={(e) => setDoctorName(e.target.value)}
                />
              </InputField>
            </div>
          </Box1>
          <Box1>
            <BoxTitle> Add Item</BoxTitle>
            <form onSubmit={(e) => handleItem(e)}>
              <BoxV2>
                <InputField style={{ width: "70%" }}>
                  <Lable>Item Name : </Lable>

                  <Select
                    ref={inputRef}
                    styles={{
                      container: (baseStyles, state) => ({
                        ...baseStyles,
                        minWidth: "70%",
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: " 4px 7px",
                        borderRadius: "10px",
                        border: "2px solid black",
                        outline: "none",
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "black",
                        backgroundColor: state.isFocused ? "#3C4B64" : "white",
                        color: state.isFocused ? "white" : "#3C4B64",
                      }),
                    }}
                    autoFocus
                    onInputChange={(e) => setKeyword(e)}
                    placeholder="Please Enter 3 latter for Search item..."
                    value={selectedItem}
                    noOptionsMessage={() => "Item Not Found"}
                    onChange={setSelectedItem}
                    options={stocks}
                  />
                </InputField>
                <InputField style={{ width: "20%" }}>
                  <Button
                    type="button"
                    style={{
                      width: "50%",
                      marginTop: "0",
                      marginLeft: "auto",
                      backgroundColor: "transparent",
                      color: "#3c4b64",
                      border: "3px solid #3c4b64",
                      borderRadius: "20px",
                      fontWeight: "600",
                    }}
                    onClick={handleOpen}
                  >
                    Select
                  </Button>
                </InputField>
              </BoxV2>
              <BoxV2>
                <InputFieldV2>
                  <LableV2>Strip Size : </LableV2>
                  <SelectHTMLV2 disabled required value={itemstripSize}>
                    <OptionHTMLV2 defaultChecked value={1}>
                      1 Number
                    </OptionHTMLV2>
                    <OptionHTMLV2 value={itemstripSize}>
                      {itemstripSize} X strip
                    </OptionHTMLV2>
                  </SelectHTMLV2>
                </InputFieldV2>
                <InputFieldV2>
                  <LableV2>Batch No. :</LableV2>
                  <InputV2
                    disabled
                    placeholder="Batch No."
                    value={batchNo}
                    onChange={(e) => setBatchNo(e.target.value)}
                  />
                </InputFieldV2>
                <InputFieldV2>
                  <LableV2>Exp. Date :</LableV2>
                  <InputV2
                    disabled
                    type="month"
                    value={expiryDate}
                    onChange={(e) => setExpiryDate(e.target.value)}
                  />
                </InputFieldV2>
                <InputFieldV2>
                  <LableV2>Quantity :</LableV2>
                  <InputV2
                    disabled
                    placeholder="Quantity"
                    value={itemQuantity}
                    onChange={(e) => setItemQuantity(e.target.value)}
                  />
                </InputFieldV2>
                <InputFieldV2>
                  <LableV2>MRP :</LableV2>
                  <InputV2
                    disabled
                    placeholder="MRP"
                    value={mrp}
                    onChange={(e) => setMrp(e.target.value)}
                  />
                </InputFieldV2>
                <InputFieldV2>
                  <LableV2>Amount :</LableV2>
                  <InputV2
                    disabled
                    placeholder="Amount"
                    value={itemTotal}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </InputFieldV2>
              </BoxV2>
              <Controller>
                <Button type="submit" className="darkButton">
                  Add Item
                </Button>
                <Button
                  type="button"
                  style={{
                    backgroundColor: "transparent",
                    color: "#3c4b64",
                    border: "2px solid #3c4b64",
                  }}
                  onClick={(e) => itemReset(e)}
                >
                  Reset
                </Button>
              </Controller>
            </form>
          </Box1>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Table>
                <thead>
                  <Row
                    style={{
                      backgroundColor: "#3C4B64",
                      color: "white",
                      marginBottom: "5px",
                    }}
                  >
                    <Heading
                      style={{ backgroundColor: "#3C4B64", color: "white" }}
                    >
                      No.
                    </Heading>
                    <Heading
                      style={{ backgroundColor: "#3C4B64", color: "white" }}
                    >
                      Batch No.
                    </Heading>
                    <Heading
                      style={{ backgroundColor: "#3C4B64", color: "white" }}
                    >
                      Exp. Date
                    </Heading>
                    <Heading
                      style={{ backgroundColor: "#3C4B64", color: "white" }}
                    >
                      Stock
                    </Heading>
                    <Heading
                      style={{ backgroundColor: "#3C4B64", color: "white" }}
                    >
                      Mrp
                    </Heading>
                    <Heading
                      style={{
                        width: "20%",
                        backgroundColor: "#3C4B64",
                        color: "white",
                      }}
                    >
                      Strip Size
                    </Heading>
                    <Heading
                      style={{
                        width: "15%",
                        backgroundColor: "#3C4B64",
                        color: "white",
                      }}
                    >
                      Quantity
                    </Heading>
                    <Heading
                      style={{
                        backgroundColor: "#3C4B64",
                        color: "white",
                      }}
                    >
                      Action
                    </Heading>
                  </Row>
                </thead>
                <tbody>
                  {selectedItem?.batch?.length === 0 ||
                  selectedItem?.length === 0 ||
                  selectedItem?.batch?.length === 0 ||
                  selectedItem === null
                    ? "Please Select A item"
                    : selectedItem?.batch?.map((b, index) => (
                        <>
                          <Row>
                            <Column>{index + 1}</Column>
                            <Column>{b.batchNo}</Column>
                            <Column>{b.expiryDate}</Column>
                            <Column>
                              {calculateStock(b, stripSizes[index])}
                            </Column>
                            <Column>
                              {calculateMrp(b, stripSizes[index])}
                            </Column>
                            <Column>
                              <SelectHTML
                                style={{ width: "80%" }}
                                required
                                value={stripSizes[index]} // Use stripSizes[index] for the value
                                onChange={(e) =>
                                  handleStripSizeChange(index, e.target.value)
                                } // Pass index to the handler
                              >
                                <OptionHTML defaultChecked value={1}>
                                  1 Number
                                </OptionHTML>
                                {b.stripSize === 1 ? (
                                  ""
                                ) : (
                                  <OptionHTML value={b.stripSize}>
                                    {b.stripSize} X strip
                                  </OptionHTML>
                                )}
                              </SelectHTML>
                            </Column>
                            <Column>
                              <Input
                                required
                                style={{ width: "20%", textAlign: "center" }}
                                placeholder="Que."
                                value={quantity[index] || ""}
                                onChange={(e) => {
                                  handleQuantityChange(index, e.target.value);
                                  handleQuantityBlur(index, e.target.value);
                                }}
                              />
                            </Column>
                            <Column>
                              <Button
                                style={{ width: "100%", marginTop: "0" }}
                                onClick={() => handleSave(b, index)}
                              >
                                save
                              </Button>
                            </Column>
                          </Row>
                        </>
                      ))}
                </tbody>
              </Table>
            </Box>
          </Modal>
          {itemList.length > 0 && (
            <Box1>
              <BoxTitle>Item List</BoxTitle>
              <Table>
                <thead>
                  <Row>
                    <Heading>No.</Heading>
                    <Heading>Item Name</Heading>
                    <Heading>Batch No.</Heading>
                    <Heading>Exp. Date</Heading>
                    <Heading>Quantiy</Heading>
                    <Heading>Unit</Heading>
                    <Heading>Mrp</Heading>
                    <Heading>GST %</Heading>
                    <Heading>Texable</Heading>
                    <Heading>Amount</Heading>
                    <Heading>Action</Heading>
                  </Row>
                </thead>
                <tbody>
                  {itemList?.map((i, index) => (
                    <Row>
                      <Column>{index + 1}</Column>
                      <Column>{i.itemName}</Column>
                      <Column>{i.batchNo}</Column>
                      <Column>{i.expiryDate}</Column>
                      <Column>{i.quantity}</Column>
                      <Column>
                        {i.unit} {i.unit === "1" ? "Number" : "X Strip"}
                      </Column>

                      <Column>{i.mrp?.toFixed(2)}</Column>
                      <Column>{i.gst}</Column>
                      <Column>{i.texable?.toFixed(2)}</Column>
                      <Column>{i.itemTotal?.toFixed(2)}</Column>
                      <Column>
                        <DeleteButton onClick={() => handleRemoveItem(index)}>
                          <DeleteIcon />
                        </DeleteButton>
                      </Column>
                    </Row>
                  ))}
                </tbody>
              </Table>
              <TotalContainer>
                <InputField style={{ width: "20%" }}>
                  <Lable>DIS % : </Lable>
                  <Input value={dis} onChange={(e) => setDis(e.target.value)} />
                </InputField>

                <InputField style={{ width: "20%" }}>
                  <Lable>Total : </Lable>
                  <Input disabled value={total} />
                </InputField>
              </TotalContainer>
              <Controller>
                <Button
                  onClick={handleSubmit}
                  className="darkButton"
                  disabled={isLoading}
                >
                  {isLoading ? <LoaderIcon /> : "Submit"}
                </Button>
                <Button
                  style={{
                    backgroundColor: "transparent",
                    color: "#3c4b64",
                    border: "2px solid #3c4b64",
                  }}
                  onClick={() => window.location.reload()}
                >
                  Reset
                </Button>
              </Controller>
            </Box1>
          )}
        </DispatchContainer>
      </Right>
    </Container>
  );
};

export default Dispatch;
